.form-group-default {
  .input-group-text {
    background: none;
    border: none;
  }
}

$primary: #007bff;
$strength-colors: (darkred, orangered, orange, yellowgreen, green);
$strength-gap: 6px;

.change-password-container {
  .eye-icon {
    float: right !important;
    margin-top: -25px;
    position: relative;
    z-index: 2;
  }

  .password-length {
    float: right !important;
    position: relative;
  }

  legend.form-label {
    font-size: 1.5rem;
    color: desaturate(darken($primary, 10%), 60%);
  }

  .control-label {
    font-size: 0.8rem;
    font-weight: bold;
    color: desaturate(darken($primary, 10%), 80%);
    text-transform: none !important;
  }

  .form-hint {
    font-size: 0.6rem;
    line-height: 1.4;
    margin: -5px auto 5px;
    color: #999;

    &.error {
      color: #c00;
      font-size: 0.8rem;
    }
  }

  .strength-meter {
    position: relative;
    height: 2px;
    background: #ddd;
    margin: 7px 0;
    border-radius: 2px;
    overflow: hidden;

    &:before,
    &:after {
      content: '';
      height: inherit;
      background: transparent;
      display: block;
      border-color: #fff;
      border-style: solid;
      border-width: 0 $strength-gap 0;
      position: absolute;
      width: calc(20% + #{$strength-gap});
      z-index: 2;
    }

    &:before {
      left: calc(20% - #{($strength-gap / 2)});
    }

    &:after {
      right: calc(20% - #{($strength-gap / 2)});
    }
  }

  .strength-meter-fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    // border-radius: inherit;
    transition: width 0.5s ease-in-out, background 0.25s;

    &[data-strength='1'] {
      background: darkred;
      width: 20%;
    }

    &[data-strength='2'] {
      background: darkred;
      width: 20%;
    }

    &[data-strength='3'] {
      background: orangered;
      width: 40%;
    }

    &[data-strength='4'] {
      background: orange;
      width: 60%;
    }

    &[data-strength='5'] {
      background: yellowgreen;
      width: 80%;
    }

    &[data-strength='6'] {
      background: green;
      width: 100%;
    }
  }

  .label-title:before {
    content: '*';
    color: #ff4949;
    margin-right: 4px;
  }

  .mt-18-minus {
    margin-top: -18px;
  }
}

.change-password-profile {
  .password-error {
    color: #ff4949 !important;
    font-size: 12px !important;
    margin-top: 0.2rem !important;
    font-weight: 400 !important;
  }

  .password-count-bottom {
    bottom: 27px !important;
  }
}
