.login-name {
    display: inline-grid;
}
.title-login {
    color: #8080809e;
    font-size: 12px !important;
    top: -3px !important;
}
.avatar-account {
    border-radius: 50%;
    background-color: #ccc; 
    margin-right: 15px;
    &.bg-color-lysithea {
        padding: 5px;
        background-color: #222222 !important;
    }
    &.bg-color-alias-login {
        padding: 10px;
        filter: invert(1) !important;
        background-color: #ff5722 !important;
    }
    .avatar-alias-login {
        width:  30px !important;
        height: 30px !important;
    }
}
.limit-length {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
