.card-table .card.card-default {
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 215px;
  width: 100%;
}

.card-table .card-col {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.card.selected {
  background-color: #bbdefb;
}

.card-table .card.card-default:hover {
  cursor: pointer;
  background-color: #dae2e9;
}

.card-content .description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding-top: 10px;
}

.card-buttons {
  position: absolute;
  top: 2px;
  right: 2px;
  display: flex;
}

.disable-hover {
  pointer-events: none; // Disable all pointer events, including hover
}

.disable-hover:hover {
  // Additional styles to ensure hover effects are disabled
  background-color: initial;
}

.disable-hover .card-buttons {
  pointer-events: auto; // Re-enable pointer events for the buttons
}