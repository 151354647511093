.react-autosuggest__suggestions-container {
	position: fixed;
	display: none;
	overflow: auto;
	z-index: 99999999;
	top: 0;
	left: 0;
}
.react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
	display: block;
}
.react-autosuggest__suggestions-list {
	width: 200px;
	height: auto;
	max-height: 400px;
	border: 1px solid #ddd;
	background-color: #fafafa;
	list-style: none;
	padding-left: 0;
	li {
		padding: 5px 10px;
		cursor: pointer;
	}
	li.react-autosuggest__suggestion--highlighted {
		background-color: $white;
	}
}

.input-group .react-autosuggest__container:not(:first-child) .form-control {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.input-group>.form-control+.react-autosuggest__container,
.input-group>.custom-select+.react-autosuggest__container,
.input-group>.react-autosuggest__container+.custom-select,
.input-group>.react-autosuggest__container+.form-control {
	margin-left: -1px;
}
.input-group > .react-autosuggest__container {
	position: relative;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	width: 1%;
	margin-bottom: 0;
}
