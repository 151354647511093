/* tagging mention
*/
.mentions-input {
	position: relative;
	.input {
		overflow: hidden;
		position: relative;
		max-width:100%;
		resize: none;
	}
	.highlighter {
		position: absolute;
		left: 0;
		right: 0;
		color: transparent;
		overflow: hidden;
		border-style: solid;
		border-color: transparent;
	}
	.highlighter-content {
		position: relative;
	}
	.highlighter-content strong {
		font-weight: inherit;
		background-color: #d3e4ff;
		display: inline;
		filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
	}
}

/* no border in mention */
.mention-n-border {
	div[class*='__control'] {
		border: none;
	}
	div[class*='__highlighter'], textarea[class*='__input'] {
		padding: 0 32px 0 0;
	}
}

.ui-autocomplete{
	border: 1px solid #ddd;
	list-style: none;
	padding:0;
	position: absolute!important;
	top: 100%;
	max-height: 200px;
	overflow: auto;
	z-index: 20;
	li{
		display: block;
		border-bottom:1px solid #ddd;
	}
	li a{
		color: #333;
		background-color:#f8f8f8;
		display: block;
		padding:10px;
	}
	li a.ui-state-focus,
	li a.ui-state-active{
		background-color:$primary;
		color: $white;
	}
	li:last-child{
		border-bottom:none;
	}
}

[data-mention] {
	background-color: #a3bcea;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}
