@import "../../core/variables";

$border-width-thick: 2px !default;

.dragged {
	@include opacity(0.7); 
	cursor: move;
	border:$border-width-thick dashed $primary-hover;
}

.placeholder {
	@include opacity(0.5);
  	background:$greylight;
  	border:$border-width-thick dashed $color-success-dark;
}