.sgbadge {
	position: relative;
	white-space: nowrap;
	margin-right: 24px;
	color: $text-color-inactive;
	&[data-badge]:after {
		content: attr(data-badge);
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		position: absolute;
		font-family: "Roboto","Helvetica","Arial",sans-serif;
		top: -11px;
		right: -24px;
		font-weight: 600;
		font-size: 12px;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		background: $primary;
		color: #fff;
	}
	&[data-badge].secondary:after {
		background: $darkgrey;
	}
	&[data-badge].info:after {
		background: $blue;
	}
	&[data-badge].danger:after {
		background: $danger;
	}
	&[data-badge].warning:after {
		background: $yellow;
	}
	&.sgbadge-overlap {
		margin-right: 10px;
	}
	&.sgbadge-overlap:after {
		right: -10px;
	}
}