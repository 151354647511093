._empty_ {
  background-color: #fff !important;
  color: #bbbbbbd1 !important;
  cursor: no-drop;
  text-align: center;
  pointer-events: none;

  .hover {
    background-color: #fff !important;
  }

  .selected {
    background-color: #fff !important;
    color: #bbbbbbd1 !important;
  }
}

._disabled_ {
  background-color: #fff !important;
  color: #bbbbbbd1 !important;
  cursor: no-drop;
  text-align: left;
  pointer-events: none;

  .hover {
    background-color: #fff !important;
  }

  .selected {
    background-color: #fff !important;
    color: #bbbbbbd1 !important;
  }
}

.react-select__placeholder p{
  padding-top: 10px;
  margin-left:2px !important; 
  margin-right:2px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}