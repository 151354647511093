/*! nouislider - 11.1.0 - 2018-04-02 11:18:13 */
/* Functional styling;
* These styles are required for noUiSlider to function.
* You don't need to change these rules to apply your design.
*/
.noUi-target,
.noUi-target * {
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-user-select: none;
	-ms-touch-action: none;
	touch-action: none;
	-ms-user-select: none;
	-moz-user-select: none;
	user-select: none;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-target {
	position: relative;
	direction: ltr;
}
.noUi-base,
.noUi-connects {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
}
/* Wrapper for all connect elements.
*/
.noUi-connects {
	overflow: hidden;
	z-index: 0;
}
.noUi-connect,
.noUi-origin {
	will-change: transform;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	-ms-transform-origin: 0 0;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
}
/* Offset direction
*/
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
	left: auto;
	right: 0;
}
/* Give origins 0 height/width so they don't interfere with clicking the
* connect elements.
*/
.noUi-vertical .noUi-origin {
	width: 0;
}
.noUi-horizontal .noUi-origin {
	height: 0;
}
.noUi-handle {
	position: absolute;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
	-webkit-transition: transform 0.3s;
	transition: transform 0.3s;
}
.noUi-state-drag * {
	cursor: inherit !important;
}
/* Slider size and handle placement;
*/
.noUi-horizontal {
	//height: 18px;
	height: 6px;
	margin: 10px 0;
}
.noUi-horizontal .noUi-handle {
	// width: 34px;
	// height: 28px;
	// left: -17px;
	// top: -6px;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	left: -17px;
	top: -9px;
}
.noUi-vertical {
	width: 18px;
}
.noUi-vertical .noUi-handle {
	width: 28px;
	height: 34px;
	left: -6px;
	top: -17px;
}
html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
	right: -17px;
	left: auto;
}
/* Styling;
* Giving the connect element a border radius causes issues with using transform: scale
*/
.noUi-target {
	background: #FAFAFA;
	border-radius: 4px;
}
.noUi-connects {
	border-radius: 3px;
	background-color: #B8B8B8;
}
.noUi-connect {
	background: $primary;
}
/* Handles and cursors;
*/
.noUi-draggable {
	cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
	cursor: ns-resize;
}
.noUi-handle {
	//border: 1px solid #D9D9D9;
	border-radius: 3px;
	background: $primary;
	cursor: default;
	&:active, &:hover, &:focus {
		outline: none;
	}
	//box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
}
[disabled].noUi-target .noUi-handle {
	background: #B8B8B8;
}

.noUi-horizontal:not(.slider-multi-range) .noUi-handle[aria-valuenow="0.0"],
[disabled].noUi-target:not(.slider-multi-range) .noUi-handle[aria-valuenow="0.0"] {
	background: $white;
	border: 3px solid #999;
}

.noUi-active {
	background: $primary-click;
}
/* Handle stripes;
*/
.noUi-handle:before,
.noUi-handle:after {
	content: "";
	display: none;
	position: absolute;
	height: 14px;
	width: 1px;
	background: #E8E7E6;
	left: 14px;
	top: 6px;
}
.noUi-handle:after {
	left: 17px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
	width: 14px;
	height: 1px;
	left: 6px;
	top: 14px;
}
.noUi-vertical .noUi-handle:after {
	top: 17px;
}
/* Disabled state;
*/
[disabled] .noUi-connect {
	background: #B8B8B8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
	cursor: not-allowed;
}
/* Base;
*
*/
.noUi-pips,
.noUi-pips * {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-pips {
	position: absolute;
	color: #999;
}
/* Values;
*
*/
.noUi-value {
	position: absolute;
	white-space: nowrap;
	text-align: center;
}
.noUi-value-sub {
	color: #ccc;
	font-size: 10px;
}
/* Markings;
*
*/
.noUi-marker {
	position: absolute;
	background: #CCC;
}
.noUi-marker-sub {
	background: #AAA;
}
.noUi-marker-large {
	background: #AAA;
}
/* Horizontal layout;
*
*/
.noUi-pips-horizontal {
	padding: 10px 0;
	height: 80px;
	top: 100%;
	left: 0;
	width: 100%;
}
.noUi-value-horizontal {
	-webkit-transform: translate(-50%, 50%);
	transform: translate(-50%, 50%);
}
.noUi-rtl .noUi-value-horizontal {
	-webkit-transform: translate(50%, 50%);
	transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
	margin-left: -1px;
	width: 2px;
	height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
	height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
	height: 15px;
}
/* Vertical layout;
*
*/
.noUi-pips-vertical {
	padding: 0 10px;
	height: 100%;
	top: 0;
	left: 100%;
}
.noUi-value-vertical {
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%, 0);
	padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
	-webkit-transform: translate(0, 50%);
	transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
	width: 5px;
	height: 2px;
	margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
	width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
	width: 15px;
}
.noUi-tooltip {
	display: block;
	position: absolute;
	border: 1px solid #D9D9D9;
	border-radius: 3px;
	background: #fff;
	color: #000;
	padding: 5px;
	text-align: center;
	white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	left: 50%;
	bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	top: 50%;
	right: 120%;
}
