.switch {
	border:1px solid $greydefault;
	width:50px;
	height:26px;
	border-radius:13px;
	cursor:pointer;
	display:inline-block;
	vertical-align: middle;
}
.switch-toggle {
	border:1px solid $greylight;
	box-shadow:1px 1px 1px #ccc;
	width:25px;
	height:24px;
	left:0;
	border-radius:12px;
	background:$white;
	position:relative;
	transition:left .2s ease-in-out;
}
.switch.on {
	background:$primary;
}
.switch.on .switch-toggle {
	left:23px
}
.switch.disabled {
	cursor:not-allowed;
	background: $greylight;
	.switch-toggle {
		background: $greylight;
	}
}

.switch.switch-md {
	width: 40px;
	height: 22px;
	border-radius: 11px;
	.switch-toggle {
		width: 20px;
		height: 19px;
		border-radius: 9px;
	}
	&.on .switch-toggle {
		left: 18px;
	}
}
