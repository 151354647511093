.badge {
	font-weight: 500;
	padding: 5px 8px;
}
.badge-primary {
	background-color: $primary;
	color: $white;
}
h3 > .badge {
	font-size: 15px;
}
