.wiki-chat-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #ffffff;
    color: #2C2C2C;
    border: none;
    border-radius: 5px;
    padding: 8px;
    font-size: 18px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    z-index: 999;
  }
  
  .page-chat {
    position: fixed;
    bottom: 0;
    right: 10px;
    height: calc(100% - 10px);
	  z-index: 1050;
  
    &.keep-left {
      right: auto;
      left: 10px;
    }
  
    .page-chat-logo {
      position: absolute;
      bottom: 10px;
      right: 0;
      width: 60px;
      height: 60px;
  
      &.keep-right-middle {
        top: 50%;
        bottom: auto;
        height: auto;
        transform: translate(0, -50%);
      }
    }
  
    &.keep-left-bottom .page-chat-logo {
      right: auto;
      left: 10px;
    }
  }
  
  .page-chat-container {
    width: 500px;
    height: 100%;
    margin: 0 auto;
    max-height: calc(100% - 10px);
    overflow: hidden;
    border: none;
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    bottom: 0;
    box-shadow: 0 0 10px 0 #ccc;
    animation-duration: 0.3s;
    transition-timing-function: ease-in;
    animation-name: chat_bounce_in;
  
    label {
      font-size: 12pt;
    }
  
    .chat-header {
      align-items: center;
      border-bottom: 1pt solid rgba(0, 0, 0, 0.1);
      display: flex;
      flex-basis: 10%;
      height: 55px;
      max-height: 55px;
      text-align: center;
      width: 100%;
      background-color: white;
      position: relative;
  
      .chat-action {
        margin-right: 11px;
        margin-left: auto;
        order: 3;
        display: flex;
        align-items: center;
      }
  
      img {
        margin-left: 11px;
      }
  
      > h1 {
        padding-left: 11px;
      }
  
      .chat-company-logo {
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 11px;
        order: 1;
  
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-left: 11px;
        }
      }
  
      &.no-logo .chat-info {
        text-align: center;
      }
  
      .btn-chat-reset {
        background-color: transparent;
        border: none;
        color: #9999FF;
        margin-right: 5px;
        cursor: pointer;
  
        > i {
          font-size: 30px;
        }
      }
  
      .btn-chat-close {
        border-radius: 50%;
        background-color: white;
        align-items: center;
        display: flex;
        justify-content: center;
        width: 25px;
        height: 25px;
        border: 0;
        cursor: pointer;
      }
    }
  
    .chat-content {
      flex: 1 1 auto;
      height: 100%;
      overflow-y: auto;
      position: relative;
      word-wrap: break-word;
  
      > div > div {
        background-color: transparent;
      }
  
      .chat-scroll {
        overflow: auto;
        height: 100%;
        position: relative;
        z-index: 200;
      }
    }
  
    .chat-body-background {
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 45px);
      background-size: cover;
      background-position: 50%;
      background-repeat: no-repeat;
      z-index: 0;
    }
  }
  
  h1 {
    color: black;
    font-family: Segoe UI;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 0 20px;
  }
  
  #webchat {
    height: calc(100% - 75px);
    overflow: auto;
    position: relative;
    width: 99%;
  }
  
  .chat-title {
    font-size: 18px !important;
    justify-content: center;
    padding: 0 11px 0 10px;
    text-align: center;
    order: 2;
    font-weight: 700;
    box-sizing: border-box;
    line-height: 35px;
  }

  .enable-send-box {
    .webchat__send-box__main {
      pointer-events: auto;
    }
    .webchat__send-box-text-box {
      visibility: visible;
    }
    .webchat__icon-button {
      visibility: visible;
    }
  }
  
  .webchat__send-box__main {
    padding-top: 5px;
    pointer-events: none;
  }
  
  .webchat__send-box-text-box {
    visibility: hidden;
  }
  
  .webchat__icon-button {
    visibility: hidden;
  }