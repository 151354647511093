.terms_of_use-wrapper {
  background-color: #fff;
  min-height: 100%;
  .title-page {
    text-align: center;
    width: 100%;
    margin: 50px 0 30px;
    font-size: 20px;
    color: #000;
  }
  .content {
    p {
      font-size: 16px;
      line-height: 40px;
      color: #000;
      white-space: pre-wrap;
      padding-bottom: 20px;
    }
  }
  .content_en_US {
    p {
      font-size: 18px;
    }
  }
}
@media (min-width: 768px) {
  .terms_of_use-wrapper {
    .content {
      margin: 0 50px;
    }
  }
}
