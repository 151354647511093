.table-scenario {
	.type-icon {
		border-color: #eee!important;
	}
	td {
		position: relative;
	}
	.action-bar {
		position: absolute;
		right: 10px;
		top: 10px;
		display: none;
	}
	td:hover > .action-bar {
		display: block;
	}
	.selected-edit {
		background-color: #BBDEFB;
	}
}

$scenarioBar: 700px;
$headerFooter: 55px;
.scenario-sidebar {
	//width: $scenarioBar;
	//height: calc(100% - 54px);
	//position: fixed;
	bottom: 0;
	right: 0;
	background-color: #f8f8f8;
	box-shadow: 0px 0px 4px #ccc;
	border: 1px solid #ddd;
	border-right: 0;
	border-bottom: 0;
	z-index: 999;
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	.header,
	.middle,
	.footer {
		//width: $scenarioBar;
	}
	.header {
		height: $headerFooter;
		background-color: #eee;
		position: absolute;
		top:0;
		padding: 14px;
	}
	.header .action {
		position: absolute;
		right: 10px;
		top: 8px;
	}
	.header .action .btn-link {
		color: $text-color-primary;
	}
	.header h3 {
		font-size: 18px;
	}
	.middle {
		height: calc(100% - 110px);
		margin-top: $headerFooter;
		padding: 15px;
		overflow: auto;
	}
	.footer {
		height: $headerFooter;
		background-color: #eee;
		position: absolute;
		bottom:0;
		padding: 10px;
		border-top:1px solid $border-color;
	}
	.card-scenario-footer {
		background-color: #eee;
		border-top:1px solid #ddd;
		padding: 10px;
		position: fixed;
		bottom: 0;
		right: -$scenarioBar;
		z-index: 100;
		width: $scenarioBar;
		-webkit-transition-duration: 0.3s;
		-moz-transition-duration: 0.3s;
		-o-transition-duration: 0.3s;
		transition-duration: 0.3s;
	}
	.card + .card {
		margin-top:15px;
	}
	&.out {
		//right: -$scenarioBar;
	}
	&.in {
		right: 0;
	}
	.card-scenario-editor {
		border: none;
		height: 100%;
		overflow: auto;
		display: block;
		position: relative;
		background-color: #f8f8f8;
	}
	.card-scenario-editor > .card-content {
		background-color: #f8f8f8;
		padding-bottom: 60px;
	}
}

.scenario-editor-flow, .on-drag-progress {
	position: relative;
	.action-flow {
		text-align: center;
	}
	.action-flow .btn-add-scenario-flow {
		margin: 10px auto;
		text-align: center;
	}
	.timeline-bar {
		position: absolute;
		left: 30px;
		top: 0;
		height: 100%;
		width: 5px;
		background-color: $white;
		z-index: 0;
	}
}
/* accordion
*/
.accordion .card .card-header {
	padding: 10px!important;
}
.btn.remove {
	padding: 0px 15px;
}
.btn.remove span {
	font-size: 1.5rem;
	line-height: 27px;
	font-weight: 700;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: .5;
}
.btn.remove:disabled span {
	opacity: .2;
}
.scenario-editor-flow .scenario, .on-drag-progress {
	background-color: $greybg;
	padding:0 15px;
	.drag-handle {
		padding: 5px;
		background-color: $white;
		position: absolute;
    top: -33px;
		border: 1px solid $greydefault;
	}
	.flow {
		position: relative;
	}
	.flow + .flow {
		margin-top: 20px;
	}
	.header-action {
		position: absolute;
		right: 10px;
		top: 10px;
	}
	.header-action .btn-link {
		color: $text-color-sub;
		padding: 5px;
	}
	.card {
		//width: auto;
		margin-bottom: 0;
	}
	.card-flow {
		margin-left: 50px;
		width: auto;
	}
	.type-flow {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 1;
		border: 1px solid #ddd;
		border-radius: $radius-default;
		padding: 5px;
		background-color: $white;
	}
}
$modal-header: 60px;
.uploader {
	position: relative;
	height: 100%;
	display: inline-block;
	overflow: hidden;
	input[type="file"]{
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}
}
.media-uploader {
	background-color: $white;
	border: 1px solid $border-color;
	.media-left {
		padding: 10px;
	}
	.media-body {
		background-color: $greylight;
		border-left: 1px solid $border-color;
		padding: 10px;
		min-height: 86px;
		overflow-y: auto;
	}
	.images .image{
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
		width: 150px;
		height: 100px;
		position: relative;
		display: inline-block;
		margin:0 5px 5px 0;
	}
	.images .image .tools {
		width: 100%;
		height: 100%;
		visibility: hidden;
	}
	.images .image .drag {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		background-color: $white;
	}
	.images .image:hover .drag {
		display: inline-block;
	}
	.images .image:hover .tools {
		visibility: visible;
	}
	.images .image .remove {
		position: absolute;
		right: 0;
		top: 0;
		padding: 2px 5px;
		border-radius: 0;
	}
	.videos .video {
		max-width: 400px;
    position: relative;
	}
	.videos .video button.remove {
		position: absolute;
		right: 0;
		top: 0;
		z-index: $zIndex-10;
	}
}
$carousel-width: 350px;
$carousel-height: 200px;
$carousel-square-height: 348px;
.media-carousel {
	flex-direction: row;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	flex-wrap: nowrap;
	position: relative;;
	& > .card {
		width: $carousel-width;
		min-width: $carousel-width;
		margin-right: 20px;
		overflow: hidden;
	}
	.badge-order {
		z-index: $zIndex-1;
		background-color: #444;
	  //box-shadow: 0 0 3px 2px rgba(0,0,0,0.8);
	  height: 50px;
	  left: -25px;
	  position: absolute;
	  top: -25px;
	  width: 50px;
		border-radius: 0;
	  -webkit-transform: rotate(-45deg);
		span {
			color: $white;
		  left: -15px;
		  top: 34px;
		  position: absolute;
		  width: 80px;
		}
	}
	.card .card-content {
		padding: 0;
	}
	.card-content .image {
		position: relative;
		height: $carousel-height;
		border-bottom: 1px solid $border-color;
		background-color: $greylight;
		-webkit-transition-duration: 0.3s;
		-moz-transition-duration: 0.3s;
		-o-transition-duration: 0.3s;
		transition-duration: 0.3s;
	}
	.card-content .image .tools {
		width: 100%;
		height: 100%;
		visibility: hidden;
		position: absolute;
		z-index: $zIndex-1;
	}
	.card-content .image:hover .tools {
		visibility: visible;
	}
	.image-uploaded {
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
		width: 100%;
		height: $carousel-height;
		position: relative;
		-webkit-transition-duration: 0.3s;
		-moz-transition-duration: 0.3s;
		-o-transition-duration: 0.3s;
		transition-duration: 0.3s;
	}
	&.square-carousel {
		.card-content .image {
			height: $carousel-square-height;
		}
		.image-uploaded {
			height: $carousel-square-height;
		}
	}
	.card-content .image.active .uploader {
		display: none;
	}
	.card-content .image:hover .uploader {
		display: block;
	}
	.card-content .settings {
		padding: 10px;
	}
	.card-content .image .remove {
		position: absolute;
		right: 0;
		top: 0;
		padding: 2px 5px;
		border-radius: 0;
		z-index: $zIndex-2;
	}
	.uploader {
		// position: absolute;
		// left: 0;
		// top: 0;
		z-index: 9;
		width: 100%;
		height: $carousel-height;
	}
	.uploader span.btn {
		background: transparent;
		border:none;
		width: 100%;
		height: 100%;
	}
	.image.active .uploader span.btn i {
		color: $white;
	}

}
.modal-task {
	z-index: 1051;
	.card {
		cursor: pointer;
		margin-bottom: 20px;
	}
	.card.selected {
		background-color: $blue;
	}
	.card.selected .card-header,
	.card.selected .card-content,
	.card.selected .card-content i {
		color: $white !important;
	}
	.disabled {
		background-color: $greylight;
		cursor: not-allowed;
		.card-title, .card-content {
			color: $grey;
		}
	}
}
.modal-flow {
	.card .card-header {
		padding: 16px 16px 7px;
	}
	.tab-content {
		padding: 15px 0px;
	}
	.cover-preview {
		position: relative;
	}
	.remove-cover {
		position: absolute;
		top: 0;
		right: 0;
	}
	.modal-dialog {
		overflow: hidden;
	}
	.modal-header > button.close {
		display: none;
	}
	.modal-header {
		transition: all .2s !important;
		position: fixed;
		top: 0;
		//left: 0;
		width: inherit;
		height: $modal-header;
		z-index: 10;
		background-color: #eee;
		padding: 10px;
		h3 {
			margin: 0;
		}
		.action {
			position: absolute;
			right: 10px;
			top: 10px;
		}
	}
	.modal-body {
		overflow: auto;
		padding-top: $modal-header + 20px;
		// padding-bottom: $modal-header + 10px;
		padding-bottom: 20px;
	}
	.modal-footer {
		height: $modal-header - 10px;
		z-index: 10;
		background-color: #eee;
		padding: 5px 10px 5px 5px;
		// position: fixed;
		// bottom: 0;
		//left: 0;
		width: inherit;
		border-top: 1px solid #ddd;
	}
	.sc-name {
		font-family: $base-font-family;
		@include font-size(22px);
		padding: 0;
		&:focus {
			background-color: transparent;
			border-bottom:1px solid $primary;
		}
	}
	.social-brand-logo {
		width: 20px;
	}
	.nav-tabs .nav-item .nav-link {
		opacity: .5;
	}
	.nav-tabs .nav-item .nav-link.active {
		opacity: 1;
		border-top: 2px solid $primary;
		border-bottom: none;
	}
	.nav-tabs .nav-item .nav-link.active:hover {
		background-color: $white;
	}
}
.flow-header {

}
.flow-body {
	overflow: auto;
}
.modal-flow-editor {
	position: relative;
	background-color: #f8f8f8;
	.flow.header, .flow.footer {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
	}
	.footer {
		bottom: 0;
		top: auto;
	}
}

/* drag and drop
*/
.on-drag-progress {
	.scenario {
		padding: 0;
	}
	.card .card-header {
		padding:16px 16px 7px;
	}
}
.on-drag-progress,
.scenario-in-drag .on-drag-stay {
	margin-bottom:30px;
	.btn-add-scenario-flow {
		display: none;
	}
	z-index: 1;
	position: relative;
}
.scenario-in-drag .on-drag-stay {
	overflow: hidden!important;
}
.on-drag-progress {
	background-color: transparent!important;
}
.on-drag-progress, .on-drag-stay {
	user-select: none;
	padding: 0px;
	margin: 0px;
	& > div {
		position: relative;
		z-index: 1;
	}
}
[data-react-beautiful-dnd-drag-handle][draggable="true"] {
	background-color: red!important;
}

.on-drag-template-image {
	.image {
		width: 150px;
		height: 100px;
		@extend %background-cover;
	}
	.drag {
		background-color: $white;
		display: inline-block;
	}
	.tools,
	.remove,
	.btn-edit-img {
		display: none;
	}
}

/* mentions input on editor
*/
.mention-wrapper {
	position: relative;
	&.is-invalid .mention-on-editor__control {
		border-color: #DC3546;
	}
	&.is-invalid~.invalid-feedback {
		display: block;
	}
	.tooltips {
		position: absolute;
		right: 6px;
		top: 5px;
		z-index: 1;
	}
	i.tag {
		color: $border-color;
	}
}
.mention-on-editor__control {
	border: 1px solid $border-color;
}
.mention-on-editor__highlighter {
	padding: 9px 32px 9px 15px;
	line-height: 20px;
	min-height: 80px;
	letter-spacing:normal;
	font-family: Arial, sans-serif!important;
	strong {
		background-color: $blue-500!important;
	}
}
.mention-on-editor__input {
	padding: 9px 32px 9px 15px;
	font-family: Arial, sans-serif!important;
	min-height: 80px;
	outline: 0;
	border: 0;
}
.mention-on-editor__suggestions {
	z-index: 99999999!important;
	min-width: 160px;
}
.mention-on-editor__suggestions__list {
	min-width: 160px;
	background-color: #fafafa;
	box-shadow: 0px 0px 5px rgba(98, 98, 98, 0.2);
}
.mention-on-editor__suggestions__item {
	color: #626262 !important;
	border-bottom: 1px solid #dddddd;
	background-color:#fafafa;
	opacity: .6;
	&:last-child {
		border-bottom: none;
	}
}
.mention-on-editor__suggestions__item--focused {
	background-color: $blue-500;
	color: $color-black;
	opacity: 1;
}

/* message tools
*/
.message-tools {
	background-color: $greylight;
	border:1px solid $border-color;
	border-top:none;
	position: relative;;
	li {
		border-right:1px solid $border-color;
		padding: 3px 5px;
		cursor: pointer;
	}
	.tool-emoji {
		position: absolute;
		bottom: 100%;
		left: 0;
	}
}
.operation-btns {
	width:205px;
}

/* scenario list/tree toolbar
*/
.scenario-toolbar-select {
	border-radius: $radius-default;
	.react-select__value-container {
		line-height: 22px;
		label {
			font-family: 'Montserrat';
    	text-transform: uppercase;
			font-size: 11px;
			font-weight: 500;
		}
	}
	.react-select__value-container--is-multi {
		line-height: 20px;
		.react-select__multi-value {
			line-height: 18px;
		}
	}
	.react-select__placeholder {
		font-size: 14px;
		margin-left:5px;
	}
	.react-select__control {
		align-items: flex-start;
		min-height: 35px;
		height: 35px;
	}
	.react-select__dropdown-indicator {
		padding: 7px;
	}
}
.border-wrapper {
	background: url('#{$base-img-url}/vertical-line-9.png');
	height: 61px;
	position: relative;
	left:-9px;
	top:-30px;
	background-repeat: no-repeat;
	background-position: right;
}
.task-grouper, .parent-task-connector{
	background: url('#{$base-img-url}/vertical-line-9.png') repeat-y right top;
	height: 60px;
	position: absolute;
	left: 28px;
	top: 29px;
	z-index:1;
}
.border-wrapper-el {
	background: url('#{$base-img-url}/el-line-6.png') no-repeat right top ;
	height: 22px;
	position: absolute;
	&.long-el {
		left:41px;
		top:10px;
	}
	&.short-el {
		left:12px;
		top:10px;
	}
}
.border-wrapper-hor {
	background: url('#{$base-img-url}/horizontal-line-6.png') no-repeat right top ;
	height: 22px;
	position: absolute;
	left:53px;
	top:10px;
}
.grp-icon {
	position:absolute;
	left:-15px;
  top: 35px;
  background: $white;
}
.expanded-material {
	//margin-left: 23px;
	color:#666 !important;
}
.collapsed-material {
	//margin-left: 23px;
	border: 1px solid #666;
	border-radius:1px;
    font-size: 11px !important;
    background: $white;
}
.BtnGrp {
	color: #FFF;
	font-size: 11px;
	font-family:'Montserrat';
	position: relative;
	left: 22px;
	top: 0px;
	display:inline-block;
	width:10px;
}

.cp_tree-table_row , .cp_tree-table_column {
	overflow:visible !important;
}

.cp_tree-table_cell .main-title > i.material-icons {
	padding: 0px 5px;
	background-color: $white;
}

/* Bot settings */
.settingOptions {
	background: $greynormal;
}

/* scenario conditional & declarations
*/
.declare-conditional {
	position: relative;
	.wrapper {
		position: relative;
	}
	.wrapper.off {
		max-height: 100px;
		overflow: hidden;
	}
	.wrapper .arrow {
		position: absolute;
		bottom: 15px;
		width: 100%;
		height: 20px;
		text-align: center;
		display: none;
		z-index: $zIndex-1;
	}
	.wrapper.off .arrow {
		display: block;
	}
	.toggle-tool {
		position: absolute;
		right: 0;
		top: 0;
		z-index: $zIndex-1;
	}
	h5 {
		font-family: 'Montserrat';
		text-transform: uppercase;
		font-size: 13px;
		font-weight: 500;
		margin-top: 0;
		padding-top: 10px;
	}
	h5 span {
		text-transform: none;
	}
	.section {
		background-color: $white;
		border: 1px solid $border-color;
		border-radius: $radius-default;
		padding: 10px;
		color: $darkergrey;
	}
	span.varcode {
		background-color: $greylight;
		color: $blue;
		padding: 3px 10px;
    border-radius: 10px;
		margin: 0 5px;
		//text-decoration: underline;
		border: 1px solid $blue;
		// &.value {
		// 	background-color: $orange;
		// }
		// &.operator {
		// 	background-color: $blue;
		// }
	}
	.declare, .rule {
		margin-bottom: 10px;
		border-bottom: 1px solid $border-color;
		padding-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
		}
	}
}
