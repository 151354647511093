.dropdown-submenu {
	position: relative;
}
.dropdown-submenu > .dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: 0;
	margin-left: 0;
	border-radius: $radius-default;
}
.dropdown-submenu:hover > ul.dropdown-menu,
.dropdown-submenu:hover > .dropdown-menu {
	display: block;
}
.dropdown-menu>.dropdown-item {
	border-radius: 0;
	cursor: pointer;
}
a.dropdown-item.active {
	color: $white;
}
.dropdown-submenu > a:after {
	display: block;
	content: " ";
	float: right;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	border-width: 5px 0 5px 5px;
	border-left-color: $darkergrey;
	margin-top: 7px;
	margin-right: -5px;
}

.dropdown-submenu.pull-left {
	float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
	left: -100%;
	margin-left: 10px;
	border-radius: $radius-default;
}
.dropdown-item {
	padding: 5px 15px;
	border-bottom: 1px solid $border-color;
	&:last-child {
		border-bottom: none;
	}
	&:hover {
		background-color: $white!important;
	}
}
.dropdown-item a {
	color: $text-color-primary;
	display: block;
}
.dropdown-menu {
	padding:0;
	font-size: $text-body;
	border-radius: $radius-default;
	min-width: 220px;
}
.dropdown-menu.md {
	min-width: 180px;
}
