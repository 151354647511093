.el-date-editor--daterange {
	input.el-input__inner {
		border-color: rgba(0, 0, 0, 0.15)!important;
		border-radius: $radius-default;
		height: 35px;
	}
}
.react-datepicker-popper {
	z-index: 99999;
}
@media (max-width: 575px) {
	.el-date-editor,
	.el-date-editor--daterange.el-input {
		width: 100%;
	}
}
