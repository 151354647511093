@charset "UTF-8";
/* border radius
*/
/* help use by arrow mixins
*/
/* with or without border
*	e.g. @include arrow('right','bottom', 10px, 0px, #4fade3) - right bottom without border
*	e.g. @include arrow('right','bottom', 10px, 0px, #eee, #4fade3, 6px) - right bottom with border
*/
/* media queries
*	e.g
	.foo {
		@include screen(sm) { //max-width
			width: 22%;
		}
		@include screen(xs, min) { //min-width
			width: 10%;
		}
	}
*/
/* REM and pixel convertion : USAGE
h1 {
	@include font-size(12px);
}
*/
/* animation with keyframes :: USAGE
@include keyframes(circle1) {
	0% { top: 8px; }
	15% { top: 4px; }
	25% { top: 8px; }
}
.element {
	@include animation('circle1');
}
*/
/* transition
*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600");
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.on-drag-template-image .image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.ReactTable .rt-th.-cursor-pointer > div:after, #toast-container > .toast:before, .slick-prev:before,
.slick-next:before, .page-sidebar .sidebar-menu .menu-items > li > a > .arrow:before,
.page-sidebar .sidebar-menu .menu-items > li > span.sub-label > .arrow:before, [data-toggle-pin='sidebar'] > i:before {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons';
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

::placeholder {
  color: #000 !important;
  opacity: 0.3 !important; }

::-webkit-input-placeholder {
  color: #000 !important;
  opacity: 0.3 !important; }

:-ms-input-placeholder {
  color: #000 !important;
  opacity: 0.3 !important; }

::-moz-placeholder {
  color: #000 !important;
  opacity: 0.3 !important; }

/* text color
*/
/* padding
*/
/* z-index
*/
/* hover
*/
/* gap
*/
/* shadow
*/
/* text size
*/
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 99999; }

.pace-inactive {
  display: none; }

.pace .pace-progress {
  background: #FF6666;
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px; }

/* Page Loader
------------------------------------
*/
.pace .pace-progress {
  height: 3px; }

.pace .pace-progress-inner {
  box-shadow: none; }

.pace .pace-activity {
  -webkit-animation: none;
  animation: none;
  top: 73px;
  background: url("~assets/img/progress/progress-circle-success.svg") no-repeat top left;
  background-size: 100% auto;
  margin: 0 auto;
  border-width: 0;
  border-radius: 0;
  width: 28px;
  height: 40px;
  right: 19px;
  display: none; }

/* Alerts */
.alert {
  background-image: none;
  box-shadow: none;
  text-shadow: none;
  padding: 9px 19px 9px 15px;
  border-radius: 3px;
  font-size: 13px;
  border-width: 0;
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s; }
  .alert.bordered {
    border-width: 1px; }
  .alert .link {
    color: #ce8f22;
    font-weight: bold; }
  .alert .alert-heading {
    color: #ce8f22 !important;
    margin-bottom: 5px;
    font-weight: 600; }
  .alert .btn-small {
    position: relative;
    top: -3.5px; }
  .alert .button-set .btn {
    position: relative;
    top: 8px; }
  .alert .close {
    background: url("~assets/img/icons/noti-cross.png") no-repeat scroll 0 0 transparent;
    background-position: -9px -10px;
    width: 10px;
    height: 9px;
    position: relative;
    opacity: 0.8;
    top: 4.5px;
    margin-left: 20px;
    font-size: 0;
    right: auto;
    padding: 0; }
    .alert .close:hover {
      opacity: 1; }

/* Alert : Color Options
------------------------------------
*/
.alert-danger,
.alert-error {
  background-color: #fddddd;
  color: #933432;
  border-color: #933432; }
  .alert-danger .close,
  .alert-error .close {
    background-position: -95px -10px !important; }

.alert-warning {
  background-color: #fef6dd;
  color: #957d32;
  border-color: #957d32; }

.alert-info {
  background-color: #daeffd;
  color: #2b6a94;
  border-color: #2b6a94; }
  .alert-info .close {
    background-position: -67px -10px !important; }

.alert-success {
  background-color: #cff5f2;
  color: #0a7c71;
  border-color: #0a7c71; }
  .alert-success .close {
    background-position: -38px -10px !important; }

.alert-default {
  background-color: #fff;
  color: #626262;
  border-color: #e6e6e6; }
  .alert-default .close {
    background-position: -67px -10px !important; }

/* Form Elements */
label,
input,
button,
select,
textarea {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px; }

input[type="radio"],
input[type="checkbox"] {
  margin-top: 1px 0 0;
  line-height: normal;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

select[multiple],
select[size] {
  height: auto !important; }

input:focus,
select:focus,
textarea:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: none;
  box-shadow: none; }

form legend {
  margin: 15px 0px 10px 0px; }

.form-control {
  background-color: #fff;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-family: Arial, sans-serif;
  color: #2c2c2c;
  outline: 0;
  padding: 8px 12px;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  min-height: 35px;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
  box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s; }
  .form-control:focus {
    border-color: rgba(0, 0, 0, 0.1);
    background-color: #f0f0f0;
    outline: 0 !important;
    box-shadow: none; }
    .form-control:focus::-moz-placeholder {
      color: inherit;
      opacity: 0.7; }
    .form-control:focus:-ms-input-placeholder {
      color: inherit;
      opacity: 0.7; }
    .form-control:focus::-webkit-input-placeholder {
      color: inherit;
      opacity: 0.7; }
  .form-control::-moz-placeholder {
    color: inherit;
    opacity: 0.33; }
  .form-control:-ms-input-placeholder {
    color: inherit;
    opacity: 0.33; }
  .form-control::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.33; }
  .form-control[disabled], .form-control[readonly],
  fieldset[disabled] .form-control {
    background: #f8f8f8;
    color: rgba(98, 98, 98, 0.23); }
  .form-control.transparent {
    background-color: transparent;
    border-color: transparent; }
    .form-control.transparent:focus {
      background-color: transparent;
      border-color: transparent; }

/* Input Sizes
--------------------------------------------------
*/
.input-sm,
.form-horizontal .form-group-sm .form-control {
  font-size: 13px;
  min-height: 32px;
  min-height: 32px;
  padding: 7px 9px; }

.input-lg,
.form-horizontal .form-group-lg .form-control {
  border-radius: 3px;
  font-size: 18px;
  min-height: 45px;
  padding: 11px 13px; }

.input-xlg {
  min-height: 51px;
  font-size: 18px;
  line-height: 22px; }

.input-group-lg > .form-control {
  padding: 6px 1rem; }

.input-group-sm > .form-control {
  padding: 0 .5rem;
  min-height: 32px; }

.select2-lg .select2-container .select2-selection.select2-selection--single {
  height: 45px;
  padding: 11px 13px; }
  .select2-lg .select2-container .select2-selection.select2-selection--single .select2-selection__rendered {
    padding-top: 0px;
    line-height: 22px;
    font-size: 18px; }
  .select2-lg .select2-container .select2-selection.select2-selection--single .select2-selection__arrow {
    bottom: 9px; }

.select2-sm .select2-container .select2-selection.select2-selection--single {
  height: 32px;
  padding: 8px 9px; }
  .select2-sm .select2-container .select2-selection.select2-selection--single .select2-selection__rendered {
    padding-top: 0px;
    line-height: 16px;
    font-size: 13px; }
  .select2-sm .select2-container .select2-selection.select2-selection--single .select2-selection__arrow {
    bottom: 4px; }

/* Checkboxes and Radio buttons
--------------------------------------------------
*/
.radio,
.checkbox {
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 0px; }
  .radio label,
  .checkbox label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 15px;
    min-width: 17px;
    min-height: 17px;
    margin-right: 15px;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0; }
    .radio label:before,
    .checkbox label:before {
      -webkit-box-sizing: inherit;
      box-sizing: border-box;
      content: "";
      display: inline-block;
      width: 17px;
      height: 17px;
      margin-right: 10px;
      position: absolute;
      left: 0px;
      background-color: #fff;
      border: 1px solid #d0d0d0; }

.radio label {
  margin-bottom: 6px; }
  .radio label:before {
    bottom: 2.5px;
    border-radius: 99px;
    -webkit-transition: border 0.3s 0s cubic-bezier(0.455, 0.03, 0.215, 1.33);
    transition: border 0.3s 0s cubic-bezier(0.455, 0.03, 0.215, 1.33); }

.radio input[type=radio]:checked + label:before {
  border-width: 5px; }

.radio input[type="radio"]:focus + label {
  color: #2c2c2c; }
  .radio input[type="radio"]:focus + label:before {
    background-color: #e6e6e6; }

.radio input[type=radio] {
  opacity: 0;
  width: 0;
  height: 0; }

.radio input[type=radio][disabled] + label {
  opacity: 0.65; }

.radio.radio-success input[type=radio]:checked + label:before {
  border-color: #10cfbd; }

.radio.radio-primary input[type=radio]:checked + label:before {
  border-color: #FF6666; }

.radio.radio-info input[type=radio]:checked + label:before {
  border-color: #2979FF; }

.radio.radio-warning input[type=radio]:checked + label:before {
  border-color: #f8d053; }

.radio.radio-danger input[type=radio]:checked + label:before {
  border-color: #f55753; }

.radio.radio-complete input[type=radio]:checked + label:before {
  border-color: #48b0f7; }

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px; }

.checkbox input[type=radio][disabled] + label:after {
  background-color: #e6e6e6; }

.checkbox label {
  transition: border 0.2s linear 0s, color 0.2s linear 0s; }
  .checkbox label:before {
    top: 1.4px;
    border-radius: 3px;
    transition: border 0.2s linear 0s, color 0.2s linear 0s; }
  .checkbox label::after {
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 3.5px;
    top: 0px;
    font-size: 11px;
    transition: border 0.2s linear 0s, color 0.2s linear 0s; }
  .checkbox label:after {
    border-radius: 3px; }

.checkbox input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0; }

.checkbox.checkbox-circle label:after {
  border-radius: 99px; }

.checkbox.checkbox-circle label:before {
  border-radius: 99px; }

.checkbox input[type=checkbox]:checked + label:before {
  border-width: 8.5px; }

.checkbox input[type=checkbox]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\F00C";
  color: #fff; }

.checkbox input[type="checkbox"]:focus + label {
  color: #2c2c2c; }
  .checkbox input[type="checkbox"]:focus + label:before {
    background-color: #e6e6e6; }

.checkbox input[type=checkbox][disabled] + label {
  opacity: 0.65; }
  .checkbox input[type=checkbox][disabled] + label:before {
    background-color: #eceff3; }

.checkbox.right label {
  margin-right: 35px;
  padding-left: 0 !important; }
  .checkbox.right label:before {
    right: -35px;
    left: auto; }

.checkbox.right input[type=checkbox]:checked + label {
  position: relative; }
  .checkbox.right input[type=checkbox]:checked + label::after {
    font-family: 'FontAwesome';
    content: "\F00C";
    position: absolute;
    right: -27px;
    left: auto; }

.checkbox.check-success input[type=checkbox]:checked + label:before {
  border-color: #10cfbd; }

.checkbox.check-primary input[type=checkbox]:checked + label:before {
  border-color: #FF6666; }

.checkbox.check-complete input[type=checkbox]:checked + label:before {
  border-color: #48b0f7; }

.checkbox.check-warning input[type=checkbox]:checked + label:before {
  border-color: #f8d053; }

.checkbox.check-danger input[type=checkbox]:checked + label:before {
  border-color: #f55753; }

.checkbox.check-info input[type=checkbox]:checked + label:before {
  border-color: #2979FF; }

.checkbox.check-success input[type=checkbox]:checked + label::after,
.checkbox.check-primary input[type=checkbox]:checked + label::after,
.checkbox.check-complete input[type=checkbox]:checked + label::after,
.checkbox.check-warning input[type=checkbox]:checked + label::after,
.checkbox.check-danger input[type=checkbox]:checked + label::after,
.checkbox.check-info input[type=checkbox]:checked + label::after {
  color: #fff; }

.input-group > .select-group {
  position: relative;
  flex: 1 1 auto;
  width: 1%; }

.input-group-text {
  background-color: #f0f0f0;
  color: rgba(98, 98, 98, 0.47);
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 2px; }
  .input-group-text.primary {
    background-color: #FF6666;
    border: 1px solid #FF6666;
    color: #fff;
    background-color: #FF6666; }
  .input-group-text.success {
    background-color: #0090d9;
    color: #fff; }
  .input-group-text.info {
    background-color: #1f3853;
    color: #fff; }
  .input-group-text.warning {
    background-color: #fbb05e;
    color: #fff; }
  .input-group-text.danger {
    background-color: #f35958;
    color: #fff; }
  .input-group-text.transparent {
    background-color: transparent; }

.input-group-append .input-group-text {
  border-left: 0; }

.input-group-prepend .input-group-text {
  border-right: 0; }

/* Form layouts
--------------------------------------------------
*/
@media only screen and (min-width: 768px) {
  form .row {
    margin-left: 0;
    margin-right: 0; }
  form .row [class*='col-']:not(:first-child), form .row [class*='col-']:not(:last-child) {
    padding-right: 7px;
    padding-left: 7px; }
  form .row [class*='col-']:first-child {
    padding-left: 0; }
  form .row [class*='col-']:last-child {
    padding-right: 0; } }

/* Form layouts  : Horizontal
--------------------------------------------------
*/
.form-horizontal .form-group {
  border-bottom: 1px solid #e6e6e6;
  padding-top: 19px;
  padding-bottom: 19px;
  margin-bottom: 0; }
  .form-horizontal .form-group:not(.form-group-default):last-child {
    border-bottom: none; }
  .form-horizontal .form-group:hover .control-label {
    opacity: .6; }
  .form-horizontal .form-group.focused .control-label {
    opacity: 1; }
  .form-horizontal .form-group .control-label {
    text-align: left;
    opacity: .6;
    -webkit-transition: opacity ease 0.3s;
    transition: opacity ease 0.3s; }

.form-horizontal .radio {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0; }

.form-horizontal .control-label {
  padding-top: 7px;
  margin-bottom: 0; }

/* Form layouts  : Attached
--------------------------------------------------
*/
.form-group-attached .form-group.form-group-default {
  border-radius: 0;
  margin-bottom: 0; }

.form-group-attached > div {
  margin: 0; }
  .form-group-attached > div:first-child.row > [class*='col-']:first-child .form-group-default {
    border-top-left-radius: 2px; }
  .form-group-attached > div:first-child.row > [class*='col-']:last-child .form-group-default {
    border-top-right-radius: 2px; }
  .form-group-attached > div:first-child.form-group-default {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
  .form-group-attached > div:last-child.row > [class*='col-']:first-child .form-group-default {
    border-bottom-left-radius: 2px; }
  .form-group-attached > div:last-child.row > [class*='col-']:last-child .form-group-default {
    border-bottom-right-radius: 2px; }
  .form-group-attached > div:last-child.form-group-default {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px; }
  .form-group-attached > div.row > [class*='col-'] {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .form-group-attached > div.row > [class*='col-']:not(:only-child):not(:last-child) > .form-group-default {
    border-right-color: transparent; }
  .form-group-attached > div:not(:last-child) .form-group-default, .form-group-attached > div:not(:last-child).form-group-default {
    border-bottom-color: transparent; }

/* Form layouts  : Responsive Handlers
--------------------------------------------------
*/
@media (max-width: 767px) {
  .form-group-attached .form-group-default {
    border-right-color: rgba(0, 0, 0, 0.15) !important; } }

@media only screen and (min-width: 768px) {
  .form-group-attached > div.row > [class*='col-'] .form-group {
    height: 100%;
    width: 100%; } }

/* Form Groups
--------------------------------------------------
*/
.form-group {
  margin-bottom: 10px; }
  .form-group label:not(.error) {
    font-family: 'Montserrat';
    font-size: 10.5px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-weight: 500; }
  .form-group label .help {
    margin-left: 8px; }
  .form-group .help {
    font-size: 11px;
    text-transform: none;
    color: rgba(98, 98, 98, 0.75); }
  .form-group .help-ast:before {
    color: #f55753;
    content: "*";
    font-family: arial;
    font-size: 20px;
    margin-right: 5px;
    vertical-align: middle; }

.required-group, .required {
  position: relative; }
  .required-group.is-invalid > .invalid-feedback, .required.is-invalid > .invalid-feedback {
    display: block; }

.media-uploader.is-invalid ~ .invalid-feedback {
  display: block; }

.required-group:after, .form-group-default.required:after {
  color: #f55753;
  content: "*";
  font-family: arial;
  font-size: 20px;
  position: absolute;
  right: 3px;
  top: 0; }

.required-field:after {
  color: #f55753;
  content: "*";
  font-family: arial;
  font-size: 20px;
  position: absolute;
  top: 0; }

.form-group-default {
  background-color: #fff;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding-top: 7px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;
  overflow: hidden;
  width: 100%;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease; }
  .form-group-default.required:after {
    right: 15px;
    top: 9px; }
  .form-group-default.disabled {
    background: #f8f8f8;
    color: rgba(98, 98, 98, 0.23); }
    .form-group-default.disabled input {
      opacity: .6; }
    .form-group-default.disabled.focused {
      background: #f8f8f8; }
      .form-group-default.disabled.focused label {
        opacity: 1; }
  .form-group-default.focused {
    border-color: rgba(0, 0, 0, 0.1) !important;
    background-color: #f0f0f0; }
    .form-group-default.focused label {
      opacity: .4;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden; }
  .form-group-default.has-error {
    background-color: rgba(245, 87, 83, 0.1); }
  .form-group-default.has-success .form-control-feedback, .form-group-default.has-error .form-control-feedback {
    display: none !important; }
  .form-group-default.has-success .form-control,
  .form-group-default.has-success .form-control:focus,
  .form-group-default.has-error .form-control,
  .form-group-default.has-error .form-control:focus {
    border: none;
    box-shadow: none; }
  .form-group-default.input-group {
    padding: 0; }
    .form-group-default.input-group > label {
      margin-top: 6px;
      padding-left: 12px; }
      .form-group-default.input-group > label.inline {
        margin-top: 6px;
        float: left; }
    .form-group-default.input-group > .form-control {
      margin-top: -2px;
      margin-bottom: 3px;
      padding-left: 12px; }
    .form-group-default.input-group label {
      margin-top: 6px;
      padding-left: 12px; }
    .form-group-default.input-group .form-input-group {
      position: relative;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      width: 1%;
      margin-bottom: 0; }
    .form-group-default.input-group .form-control {
      margin-top: -2px;
      margin-bottom: 3px;
      padding-left: 12px;
      width: 100%; }
    .form-group-default.input-group .input-group-text {
      border: 0;
      border-radius: 0;
      min-width: 50px; }
      .form-group-default.input-group .input-group-text i {
        width: 100%; }
    .form-group-default.input-group .btn {
      border-top: 0;
      border-bottom: 0; }
  .form-group-default .form-control {
    border: none;
    min-height: 25px;
    padding: 0;
    margin-top: -2px;
    background: none; }
    .form-group-default .form-control.error {
      color: #2c2c2c; }
    .form-group-default .form-control:focus {
      background: none; }
  .form-group-default textarea.form-control {
    padding-top: 5px; }
  .form-group-default select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px;
    position: relative;
    background-position: right 0 top 50% !important;
    background-repeat: no-repeat !important;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wLDBoMjR2MjRIMFYweiIvPjxwb2x5Z29uIGZpbGw9IiNDQ0NDQ0MiIHBvaW50cz0iMTIsNS44MyAxNS4xNyw5IDE2LjU4LDcuNTkgMTIsMyA3LjQxLDcuNTkgOC44Myw5ICIvPjxwb2x5Z29uIGZpbGw9IiNDQ0NDQ0MiIHBvaW50cz0iMTIsMTQuMTcgOC44MywxMSA3LjQyLDEyLjQxIDEyLDE3IDE2LjU5LDEyLjQxIDE1LjE3LDExICIvPjwvc3ZnPg==) !important; }
  .form-group-default label {
    margin: 0;
    display: block;
    opacity: 0.7;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease; }
    .form-group-default label.label-lg {
      font-size: 13px;
      left: 13px;
      top: 9px; }
    .form-group-default label.label-sm {
      font-size: 11px;
      left: 11px;
      top: 6px; }
    .form-group-default label.highlight {
      opacity: 1; }
    .form-group-default label.fade {
      opacity: .5;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden; }
  .form-group-default > .input-lg {
    height: 29px;
    min-height: 29px;
    padding-left: 1px; }
  .form-group-default > .input-sm {
    min-height: 18px;
    height: 18px; }
  .form-group-default.form-group-default-select {
    overflow: visible; }
    .form-group-default.form-group-default-select .ui-select-container.ui-select-bootstrap.dropdown .ui-select-match {
      padding-top: 6px;
      height: 23px; }
    .form-group-default.form-group-default-select .ui-select-container .select2-choices {
      border: 0px; }
  .form-group-default.form-group-default-select2 {
    padding: 0; }
    .form-group-default.form-group-default-select2 > label {
      position: absolute;
      z-index: 10;
      padding: 7px 12px 0 12px; }
      .form-group-default.form-group-default-select2 > label.label-lg {
        left: 0;
        top: 0; }
      .form-group-default.form-group-default-select2 > label.label-sm {
        left: 0;
        top: 0; }
    .form-group-default.form-group-default-select2 .select2-container .select2-selection--single {
      padding-top: 20px;
      height: 52px;
      border: 0 !important; }
      .form-group-default.form-group-default-select2 .select2-container .select2-selection--single .select2-arrow b:before {
        top: 20px; }
      .form-group-default.form-group-default-select2 .select2-container .select2-selection--single .select2-chosen {
        padding-left: 3px;
        padding-top: 1px; }
    .form-group-default.form-group-default-select2 .select2-container .select2-selection--multiple {
      padding-top: 20px;
      height: 52px;
      border: 0px; }
      .form-group-default.form-group-default-select2 .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
        margin-top: 4px; }
    .form-group-default.form-group-default-select2.input-lg {
      height: auto;
      padding: 0; }
      .form-group-default.form-group-default-select2.input-lg .select2-container .select2-selection--single {
        padding-top: 26px;
        height: 58px; }
        .form-group-default.form-group-default-select2.input-lg .select2-container .select2-selection--single .select2-selection__rendered {
          font-size: 18px; }
    .form-group-default.form-group-default-select2.input-sm {
      height: auto;
      padding: 0; }
      .form-group-default.form-group-default-select2.input-sm .select2-container .select2-selection--single {
        padding-top: 21px;
        height: 49px; }
        .form-group-default.form-group-default-select2.input-sm .select2-container .select2-selection--single .select2-selection__rendered {
          font-size: 13px; }
  .form-group-default.form-group-default-selectFx {
    padding: 0; }
    .form-group-default.form-group-default-selectFx > label {
      position: absolute;
      z-index: 10;
      padding: 7px 12px 0 12px; }
      .form-group-default.form-group-default-selectFx > label.label-lg {
        left: 0;
        top: 0; }
      .form-group-default.form-group-default-selectFx > label.label-sm {
        left: 0;
        top: 0; }
    .form-group-default.form-group-default-selectFx .cs-wrapper .cs-placeholder {
      padding-top: 28px;
      height: 52px;
      padding-left: 12px; }
    .form-group-default.form-group-default-selectFx .cs-wrapper .cs-select {
      height: auto; }
      .form-group-default.form-group-default-selectFx .cs-wrapper .cs-select > span:after,
      .form-group-default.form-group-default-selectFx .cs-wrapper .cs-select .cs-selected span:after {
        top: 39px; }
      .form-group-default.form-group-default-selectFx .cs-wrapper .cs-select.input-lg .cs-placeholder {
        height: 60px; }
      .form-group-default.form-group-default-selectFx .cs-wrapper .cs-select.input-sm .cs-placeholder {
        height: 50px; }
    .form-group-default.form-group-default-selectFx .cs-wrapper .dropdown-placeholder {
      vertical-align: top; }
  .form-group-default.search-user {
    min-width: 420px; }

/* Form validation
--------------------------------------------------
*/
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
  color: #0da899; }

.has-success .form-control {
  border-color: #0da899;
  box-shadow: none; }
  .has-success .form-control:focus {
    border-color: #09796e;
    box-shadow: none; }

.has-success .input-group-addon {
  background: #f0f0f0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba(98, 98, 98, 0.47); }

.has-success .form-control-feedback {
  color: #0da899; }

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
  color: #c9a843; }

.has-warning .form-control {
  border-color: #c9a843;
  box-shadow: none; }
  .has-warning .form-control:focus {
    border-color: #a98b30;
    box-shadow: none; }

.has-warning .input-group-addon {
  background: #f0f0f0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba(98, 98, 98, 0.47); }

.has-warning .form-control-feedback {
  color: #c9a843; }

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #f55753; }

.has-error .form-control {
  border-color: #f55753;
  box-shadow: none; }
  .has-error .form-control:focus {
    border-color: #f22823;
    box-shadow: none; }

.has-error .input-group-addon {
  background: #f0f0f0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba(98, 98, 98, 0.47); }

.has-error .form-control-feedback {
  color: #f55753; }

.error {
  font-size: 12px;
  color: #f55753;
  display: block; }

/* Plugins
--------------------------------------------------
Datepicker
https://github.com/eternicode/bootstrap-datepicker
*/
.datepicker {
  padding: 16px 25px;
  border-radius: 2px;
  font-size: 12px; }
  .datepicker:after {
    border-bottom-color: #fafafa; }
  .datepicker thead tr .datepicker-switch {
    color: #6f7b8a;
    font-size: 13px; }
  .datepicker thead tr .next,
  .datepicker thead tr .prev {
    color: #FF6666;
    content: '';
    font-size: 0px; }
    .datepicker thead tr .next:before,
    .datepicker thead tr .prev:before {
      color: #FF6666;
      font-family: 'FontAwesome';
      font-size: 10px; }
  .datepicker thead tr .prev:before {
    content: "\f053"; }
  .datepicker thead tr .next:before {
    content: "\f054"; }
  .datepicker thead tr .dow {
    font-family: 'Montserrat';
    color: #FF6666;
    text-transform: uppercase;
    font-size: 11px; }
  .datepicker thead tr th {
    width: 31px;
    height: 29px; }
  .datepicker tbody tr .odd {
    color: #d0d3d8; }
  .datepicker table tr td {
    width: 31px;
    height: 29px; }
    .datepicker table tr td.old, .datepicker table tr td.new {
      color: #e6e6e6; }
    .datepicker table tr td.day:hover {
      background: #f0f0f0; }
    .datepicker table tr td.active {
      background-color: #FF6666 !important; }
      .datepicker table tr td.active, .datepicker table tr td.active:hover, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover {
        background-image: none;
        text-shadow: none;
        font-weight: 600; }
    .datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover {
      background-color: #f0f0f0;
      background-image: none;
      color: #fff; }
    .datepicker table tr td span {
      border-radius: 4px;
      width: 42px;
      height: 42px;
      line-height: 42px; }
      .datepicker table tr td span.active {
        background-color: #FF6666 !important; }
      .datepicker table tr td span.active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover {
        background-image: none;
        border: none;
        text-shadow: none; }
  .datepicker.dropdown-menu {
    border-color: #e6e6e6;
    color: #626262; }
  .datepicker.datepicker-dropdown.datepicker-orient-bottom:before {
    border-color: #e6e6e6; }

.datepicker-inline {
  width: auto; }

.input-daterange .input-group-addon {
  text-shadow: none;
  border: 0;
  margin: 0; }

/* Timepicker
https://github.com/m3wolf/bootstrap3-timepicker
*/
.bootstrap-timepicker-widget table td a i {
  font-size: 12px; }

.bootstrap-timepicker-widget a.btn, .bootstrap-timepicker-widget .bootstrap-timepicker-widget input {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px; }

.bootstrap-timepicker-widget.dropdown-menu {
  background: #fff; }

/* Daterangepicker
http://www.dangrossman.info/
*/
.daterangepicker {
  background: #fff; }
  .daterangepicker .calendar .calendar-date {
    border: 1px solid rgba(0, 0, 0, 0.15); }
  .daterangepicker .calendar .prev, .daterangepicker .calendar .next, .daterangepicker .calendar th {
    color: #FF6666;
    text-transform: uppercase;
    font-size: 11px; }
  .daterangepicker .calendar .month {
    color: #6f7b8a;
    font-size: 13px; }
  .daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #FF6666;
    border-color: #FF6666; }

/* Select2
http://ivaynberg.github.io/select2/
*/
.form-group-default .select2-container .select2-selection,
.select2-container-multi .select2-choices {
  border-color: transparent !important; }

select.full-width + .select2-container {
  width: 100% !important; }

.select2-container *:focus {
  outline: none; }

.select2-container .select2-selection {
  background-image: none;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  padding: 2px 9px;
  transition: border 0.2s linear 0s; }
  .select2-container .select2-selection .select2-selection__rendered {
    padding: 0;
    padding-left: 3px;
    padding-top: 1px; }
    .select2-container .select2-selection .select2-selection__rendered .select2-selection__clear {
      font-weight: normal;
      right: 16px;
      width: 20px;
      text-align: center;
      color: #888; }
  .select2-container .select2-selection .select2-selection__arrow {
    top: auto;
    bottom: 5px;
    right: 10px !important;
    left: auto;
    background: transparent;
    border-left: 0px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .select2-container .select2-selection.select2-selection--single {
    height: 35px; }
  .select2-container .select2-selection > .select2-chosen {
    margin-right: 26px;
    margin-left: 0; }

.select2-container.select2-drop-above .select2-choice {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px; }

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: transparent; }

.select2-container--default .select2-results__group {
  color: #2c2c2c; }

.select2-search input,
.select2-search-choice-close,
.select2-container .select2-choice abbr,
.select2-container .select2-choice .select2-arrow b {
  background-image: none !important; }

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  background-image: none !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #f0f0f0; }

.select2-dropdown-open .select2-choice .select2-arrow {
  -webkit-transform: scale(scale(1, -1));
  -ms-transform: scale(scale(1, -1));
  transform: scale(scale(1, -1)); }

.select2-dropdown {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: none;
  border-radius: 2px;
  padding-top: 0px; }
  .select2-dropdown.select2-drop-above {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    box-shadow: none; }

.select2-container--open .select2-selection {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #f0f0f0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }

.select2-search--dropdown {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px; }
  .select2-search--dropdown input {
    background: #fff !important;
    vertical-align: baseline;
    line-height: 28px;
    border-radius: 2px;
    border: none;
    font-size: 12px;
    border: 1px solid rgba(0, 0, 0, 0.15) !important; }

.select2-results {
  margin: 3px 8px 10px 8px; }
  .select2-results li {
    color: #626262; }
    .select2-results li.select2-result-with-children > .select2-result-label {
      color: #2c2c2c; }
      .select2-results li.select2-result-with-children > .select2-result-label:first-child {
        padding-top: 8px; }
  .select2-results .select2-results__option--highlighted {
    background-color: #f0f0f0 !important;
    border-radius: 3px;
    color: #626262 !important; }
  .select2-results .select2-no-results,
  .select2-results .select2-searching,
  .select2-results .select2-selection-limit {
    background: none;
    color: #626262;
    font-size: 12px;
    padding-left: 8px;
    padding-top: 0px;
    position: relative;
    top: -5px; }

.select2-dropdown {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  padding-top: 5px;
  z-index: 790;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15); }

.select2-container--open .select2-selection--multiple .select2-selection__choice {
  background-color: #fff !important; }

.select2-selection--multiple .select2-selection__rendered {
  background-image: none; }
  .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
    background-color: #e6e6e6;
    background-image: none;
    border: none;
    box-shadow: none;
    color: inherit;
    border-radius: 8px;
    margin: 6px -5px 0px 10px;
    padding: 0 8px 0 7px;
    line-height: 21px; }
    .select2-selection--multiple .select2-selection__rendered .select2-selection__choice:first-child {
      margin-left: 0; }
    .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
      color: #626262;
      font-weight: 900;
      margin-right: 4px; }
  .select2-selection--multiple .select2-selection__rendered .select2-search--inline {
    margin-left: 8px; }

.select2-selection--multiple.select2-container-active .select2-choices {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none; }

.select2-selection--multiple .select2-search-choice-close {
  left: 6px; }

.select2-search-choice-close {
  background: none;
  top: 4px;
  right: 0; }
  .select2-search-choice-close:hover {
    text-decoration: none; }

.select2-drop-multi .select2-results .select2-no-results,
.select2-drop-multi .select2-results .select2-searching,
.select2-drop-multi .select2-results .select2-selection-limit {
  top: 0px; }

.select2.form-control {
  padding: 0;
  box-shadow: none;
  border: 0; }

.select2-drop-mask {
  z-index: 700; }

.ui-select-bootstrap .ui-select-choices-row.active > a {
  background: #f0f0f0;
  border-radius: 3px;
  color: #626262; }

.ui-select-bootstrap > .ui-select-choices {
  background-color: #fff; }

.ui-select-choices-group-label {
  color: #2c2c2c;
  font-weight: bold; }

.modal-open .select2-drop-active {
  z-index: 1051; }

.modal-open .modal .select2-container {
  z-index: 1051; }

.modal-open .datepicker.dropdown-menu {
  z-index: 1051 !important; }

.modal-open .select2-drop-mask {
  z-index: 1050; }

.modal-open .cs-skin-slide.cs-active {
  z-index: 1050; }

.dropdown-placeholder {
  display: inline-block;
  vertical-align: middle; }

.dropdown-mask {
  bottom: 0;
  display: none;
  left: 0;
  outline: 0 none;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 600; }

.ui-select-bootstrap *:focus {
  outline: none; }

.ui-select-bootstrap .ui-select-choices-row > span {
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 24px;
  font-size: 14px; }

.ui-select-bootstrap .ui-select-match {
  box-shadow: none; }

.ui-select-bootstrap .ui-select-choices-group-label {
  padding-left: 15px;
  line-height: 24px;
  font-size: 14px;
  font-weight: 700;
  color: #626262; }

.ui-select-bootstrap .ui-select-match.btn-default-focus {
  background-color: transparent; }

.form-group-default-select {
  padding-bottom: 2px; }
  .form-group-default-select .ui-select-bootstrap .ui-select-dropdown {
    width: calc(100% + 24px) !important;
    margin-left: -12px;
    padding: 10px; }
  .form-group-default-select .ui-select-bootstrap .ui-select-match-text {
    width: calc(100% + 24px);
    margin-top: -17px;
    margin-left: -16px;
    padding: 16px 16px 0; }
  .form-group-default-select .ui-select-bootstrap .ui-select-match {
    padding-top: 2px !important; }
    .form-group-default-select .ui-select-bootstrap .ui-select-match i.caret {
      margin-top: 10px; }
    .form-group-default-select .ui-select-bootstrap .ui-select-match.btn-default-focus {
      outline: none; }
    .form-group-default-select .ui-select-bootstrap .ui-select-match > .ui-select-toggle {
      width: calc(100% + 27px);
      height: 50px;
      margin-top: -34px;
      margin-left: -16px;
      padding: 32px 16px 16px; }
  .form-group-default-select .ui-select-bootstrap .dropdown-menu .divider {
    display: none; }
  .form-group-default-select .ui-select-bootstrap.ui-select-container {
    margin-top: -3px;
    padding-bottom: 2px; }
  .form-group-default-select .ui-select-multiple {
    margin-top: -7px; }
    .form-group-default-select .ui-select-multiple .select2-search-field input {
      height: 23px !important; }
    .form-group-default-select .ui-select-multiple .select2-search-choice {
      margin-top: 2px;
      margin-bottom: 0; }
      .form-group-default-select .ui-select-multiple .select2-search-choice:first-child {
        margin-left: 0; }
  .form-group-default-select .select2-container-multi .select2-choices {
    background-color: transparent; }

/* Bootstrap Tags input
https://github.com/timschlechter/bootstrap-tagsinput
*/
.form-group-default .bootstrap-tagsinput {
  border: 0px;
  padding-left: 0;
  margin-bottom: 0;
  padding-top: 0;
  vertical-align: top;
  padding-bottom: 0px;
  padding-left: 0;
  margin-top: -3px; }
  .form-group-default .bootstrap-tagsinput .tag {
    padding: 2px 9px;
    line-height: 18px;
    margin-bottom: 2px;
    margin-left: 0;
    margin-right: 2px; }
    .form-group-default .bootstrap-tagsinput .tag [data-role="remove"] {
      display: inline-block;
      vertical-align: top; }
  .form-group-default .bootstrap-tagsinput input {
    min-height: 20px; }
    .form-group-default .bootstrap-tagsinput input:first-child {
      margin-left: 0; }

.bootstrap-tagsinput {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 0;
  padding-top: 5px;
  padding-right: 5px;
  box-shadow: none;
  width: 100%;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s; }
  .bootstrap-tagsinput.active-element {
    background-color: #e6e6e6; }
  .bootstrap-tagsinput input {
    border: none;
    margin: 5px;
    margin-top: 0;
    margin-right: 0;
    min-height: 25px;
    min-width: 10em !important;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -webkit-flex-shrink: 0;
    -webkit-flex-grow: 1;
    -webkit-flex-basis: auto; }
  .bootstrap-tagsinput .tag {
    padding: 3px 9px;
    padding-right: 6px;
    border-radius: 3px;
    line-height: 18px;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -webkit-flex-shrink: 0;
    -webkit-flex-grow: 1;
    -webkit-flex-basis: auto;
    margin: 5px;
    margin-top: 0;
    margin-right: 0; }
  .bootstrap-tagsinput .tag [data-role="remove"] {
    margin-left: 4px;
    float: right;
    vertical-align: middle; }
  .bootstrap-tagsinput .tag[data-role="remove"]:hover:active,
  .bootstrap-tagsinput .tag [data-role="remove"]:hover {
    box-shadow: none; }
  .bootstrap-tagsinput .tag [data-role="remove"]:after {
    font-family: 'pages-icon';
    content: "\e60a";
    padding: 0; }

/* Bootstrap3 wysihtml5
https://github.com/Waxolunist/bootstrap3-wysihtml5-bower
*/
.wysiwyg5-wrapper {
  position: relative; }
  .wysiwyg5-wrapper .wysihtml5-toolbar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    /*height: 50px;*/
    background: #f0f0f0;
    border-top: 1px solid #e6e6e6; }
    .wysiwyg5-wrapper .wysihtml5-toolbar .btn {
      background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
      border-color: transparent;
      border-right-color: #e6e6e6;
      color: #A5A5A5;
      font-size: 16px;
      font-weight: 600;
      height: 50px;
      line-height: 50px;
      padding: 0 5px;
      border-width: 1px;
      border-radius: 0 !important;
      box-shadow: none !important; }
      .wysiwyg5-wrapper .wysihtml5-toolbar .btn:hover .editor-icon {
        opacity: .8; }
      .wysiwyg5-wrapper .wysihtml5-toolbar .btn.active .editor-icon {
        opacity: 1; }
      .wysiwyg5-wrapper .wysihtml5-toolbar .btn.dropdown-toggle {
        padding-left: 10px;
        padding-right: 20px; }
        .wysiwyg5-wrapper .wysihtml5-toolbar .btn.dropdown-toggle .current-font {
          opacity: .5;
          font-size: 14px; }
    .wysiwyg5-wrapper .wysihtml5-toolbar > li {
      margin: 0;
      padding: 0; }
  .wysiwyg5-wrapper .wysiwyg {
    width: 100%;
    min-height: 200px;
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 50px !important;
    border: 0; }
    .wysiwyg5-wrapper .wysiwyg:focus {
      background-color: #fafafa;
      outline: 0 !important;
      box-shadow: none; }
  .wysiwyg5-wrapper .expand-wysiwyg {
    bottom: 0;
    color: #A5A5A5;
    font-size: 20px;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    padding: 0 15px;
    position: absolute;
    right: 0; }
  .wysiwyg5-wrapper .wysihtml5-sandbox {
    border: none !important;
    padding: 16px 16px 50px !important;
    width: 100% !important; }
    .wysiwyg5-wrapper .wysihtml5-sandbox.expanded {
      height: 100% !important; }

.toggle-wysiwyg {
  position: absolute;
  right: 15px;
  top: 0; }
  .toggle-wysiwyg li {
    display: inline-block;
    font-weight: 600; }

.editor-icon {
  background-image: url("~assets/img/editor/editor_tray.png");
  display: inline-block;
  height: 40px;
  margin-top: 5px;
  opacity: 0.4;
  vertical-align: top;
  width: 40px; }
  .editor-icon-headline {
    background-position: 0 0;
    width: 31px; }
  .editor-icon-bold {
    background-position: -40px 0; }
  .editor-icon-italic {
    background-position: -80px 0; }
  .editor-icon-underline {
    background-position: -120px 0; }
  .editor-icon-link {
    background-position: -160px 0; }
  .editor-icon-quote {
    background-position: -200px 0; }
  .editor-icon-ul {
    background-position: -240px 0; }
  .editor-icon-ol {
    background-position: -280px 0; }
  .editor-icon-outdent {
    background-position: -320px 0; }
  .editor-icon-indent {
    background-position: -360px 0; }
  .editor-icon-image {
    background-position: -400px 0; }
  .editor-icon-html {
    background-position: -440px 0; }

/* Summernote
https://github.com/HackerWins/summernote
*/
.summernote-wrapper .note-editor {
  border-color: #e6e6e6; }
  .summernote-wrapper .note-editor .note-toolbar {
    padding: 0;
    background-color: #f0f0f0;
    border-bottom: none; }
    .summernote-wrapper .note-editor .note-toolbar .btn-group {
      margin: 0 -1px 0 0; }
      .summernote-wrapper .note-editor .note-toolbar .btn-group .btn {
        font-size: 12px;
        font-weight: 600;
        height: 50px;
        min-width: 47px;
        line-height: 50px;
        padding: 0 5px;
        border-radius: 0;
        background-color: #f0f0f0;
        border-color: transparent;
        border-right-color: #e6e6e6;
        border-bottom-color: #e6e6e6;
        color: #626262; }
        .summernote-wrapper .note-editor .note-toolbar .btn-group .btn.active, .summernote-wrapper .note-editor .note-toolbar .btn-group .btn:active {
          background-color: #e6e6e6; }
        .summernote-wrapper .note-editor .note-toolbar .btn-group .btn.dropdown-toggle {
          min-width: 61px; }
        .summernote-wrapper .note-editor .note-toolbar .btn-group .btn:not(:last-child), .summernote-wrapper .note-editor .note-toolbar .btn-group .btn:not(:only-child) {
          margin-right: 1px; }
  .summernote-wrapper .note-editor .note-statusbar {
    background-color: transparent; }
    .summernote-wrapper .note-editor .note-statusbar .note-resizebar {
      border-top-color: transparent; }
      .summernote-wrapper .note-editor .note-statusbar .note-resizebar .note-icon-bar {
        border-top: 1px solid #e6e6e6; }

.summernote-wrapper .note-popover .popover .popover-content .dropdown-menu li a i, .summernote-wrapper .note-toolbar .dropdown-menu li a i {
  color: #FF6666; }

.in-formgroup__control {
  border: none !important;
  background: transparent !important; }
  .in-formgroup__control.in-formgroup__control--is-focused {
    border: none !important;
    box-shadow: none !important; }
  .in-formgroup__control .in-formgroup__value-container {
    padding: 2px 0; }
  .in-formgroup__control .in-formgroup__indicator-separator {
    background: none; }
  .in-formgroup__control .in-formgroup__multi-value {
    border: 1px solid #dddddd !important;
    background-color: #f8f8f8; }
  .in-formgroup__control .react-select__multi-value__label {
    color: #333333; }

.react-select__control {
  border-width: 1px !important;
  border-radius: 2px !important;
  background-color: #ffffff !important;
  border-color: rgba(0, 0, 0, 0.15) !important; }
  .react-select__control.react-select__control--is-focused, .react-select__control.react-select__control--is-focused:hover {
    border-color: #dddddd !important;
    box-shadow: none !important;
    background-color: #f0f0f0 !important; }
  .react-select__control .react-select__placeholder {
    margin-left: 10px; }
  .react-select__control .react-select__value-container--is-multi {
    padding: 2px; }
    .react-select__control .react-select__value-container--is-multi .react-select__multi-value {
      border: 1px solid #dddddd !important;
      background-color: #f8f8f8; }
    .react-select__control .react-select__value-container--is-multi .react-select__multi-value__label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 300px;
      display: inline-block;
      vertical-align: middle; }

.react-select--is-disabled .react-select__multi-value__remove {
  display: none; }

.react-select--is-disabled .react-select__control {
  background-color: #f8f8f8 !important;
  opacity: .8; }

.react-select__menu .react-select__option {
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  cursor: pointer; }

.react-select__menu .react-select__option--is-selected {
  background-color: #f8f8f8; }

.react-select__menu .react-select__option--is-focused {
  background-color: #f8f8f8; }

input, input:focus {
  -webkit-transition: none !important; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important; }

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #f0f0f0 inset !important; }

input.error:-webkit-autofill,
input.error:-webkit-autofill:focus,
.has-error input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #F9E9E9 inset !important; }

/* Pages SelectFx */
/* Default custom select styles */
div.cs-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
  width: 100%;
  max-width: 500px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

div.cs-select:focus {
  outline: none;
  /* For better accessibility add a style for this in your skin */ }

.cs-select select {
  display: none; }

.cs-select span {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/* Placeholder and selected option */
.cs-select > span {
  padding-right: 3em; }

.cs-select > span::after,
.cs-select .cs-selected span::after {
  speak: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.cs-select > span::after {
  content: '\25BE';
  right: 1em; }

.cs-select .cs-selected span::after {
  content: '\2713';
  margin-left: 1em; }

.cs-select.cs-active > span::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg); }

/* Options */
.cs-select .cs-options {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: #fff;
  visibility: hidden; }

.cs-select.cs-active .cs-options {
  visibility: visible; }

.cs-select ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%; }

.cs-select ul span {
  padding: 1em; }

.cs-select ul li.cs-focus span {
  background-color: #ddd; }

/* Optgroup and optgroup label */
.cs-select li.cs-optgroup ul {
  padding-left: 1em; }

.cs-select li.cs-optgroup > span {
  cursor: default; }

div.cs-skin-slide {
  color: #fff;
  /*font-size: 1.5em;*/
  width: 300px; }

@media screen and (max-width: 30em) {
  div.cs-skin-slide {
    font-size: 1em;
    width: 250px; } }

div.cs-skin-slide::before {
  content: '';
  background: #282b30;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s; }

.cs-skin-slide.cs-active::before {
  -webkit-transform: scale3d(1.1, 3.5, 1);
  transform: scale3d(1.1, 3.5, 1); }

.cs-skin-slide > span {
  height: 80px;
  line-height: 32px;
  -webkit-transition: text-indent 0.3s, opacity 0.3s;
  transition: text-indent 0.3s, opacity 0.3s; }

@media screen and (max-width: 30em) {
  .cs-skin-slide > span {
    height: 60px;
    line-height: 28px; } }

.cs-skin-slide.cs-active > span {
  text-indent: -290px;
  opacity: 0; }

.cs-skin-slide.cs-active > span::after {
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0); }

.cs-skin-slide .cs-options {
  background: transparent;
  width: 70%;
  height: 400%;
  padding: 1.9em 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

@media screen and (max-width: 30em) {
  .cs-skin-slide .cs-options {
    padding-top: 3em; } }

.cs-skin-slide .cs-options li {
  opacity: 0;
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s; }

.cs-skin-slide.cs-active .cs-options li {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.cs-skin-slide.cs-active .cs-options li:first-child {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(5) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s; }

/* more options need more delay declaration */
.cs-skin-slide .cs-options li span {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 65%;
  padding: 0.8em 1em 0.8em 2.5em; }

.cs-skin-slide .cs-options li span:hover,
.cs-skin-slide .cs-options li.cs-focus span,
.cs-skin-slide .cs-options li.cs-selected span {
  color: #eb7e7f;
  background: transparent; }

.cs-skin-slide .cs-selected span::after {
  content: ''; }

/* Pages Select  overriding */
.form-group-default .cs-skin-slide > span {
  padding: 0 30px 0 0;
  height: 22px;
  line-height: 21px; }

.form-group-default .cs-wrapper {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.cs-wrapper {
  display: inline-block; }

.form-control.cs-select:not(.cs-active) {
  width: 100% !important; }

.cs-select {
  background-color: transparent; }
  .cs-select span {
    text-overflow: initial; }
  .cs-select .cs-placeholder {
    width: 100%; }

div.cs-skin-slide {
  width: auto;
  font-family: Arial,sans-serif;
  color: #5e5e5e; }
  div.cs-skin-slide:before {
    background-color: transparent; }
  div.cs-skin-slide.cs-transparent {
    background: none; }
    div.cs-skin-slide.cs-transparent .cs-backdrop {
      border-color: transparent;
      background: none; }
    div.cs-skin-slide.cs-transparent.cs-active .cs-backdrop {
      background: #fafafa; }
  div.cs-skin-slide > span {
    height: 35px;
    padding: 6px 33px 6px 17px;
    line-height: 23px;
    z-index: 1; }
  div.cs-skin-slide.cs-active {
    z-index: 790; }
    div.cs-skin-slide.cs-active:before {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1); }
    div.cs-skin-slide.cs-active .cs-backdrop {
      border: transparent;
      background: #fafafa;
      box-shadow: -1px 0 1px #ccc, 1px 0 1px #ccc; }
  div.cs-skin-slide > span:after,
  div.cs-skin-slide.cs-active > span:after {
    content: "\f0d7";
    font-family: FontAwesome;
    color: #5e5e5e; }
  div.cs-skin-slide .cs-options {
    height: auto;
    padding: 9px 0;
    width: auto;
    padding: 10px;
    max-height: 350px;
    transform: translate3d(1, 1, 1);
    overflow: hidden;
    z-index: 1; }
    div.cs-skin-slide .cs-options ul {
      width: 100%;
      display: table; }
      div.cs-skin-slide .cs-options ul li {
        display: table-row; }
        div.cs-skin-slide .cs-options ul li span {
          display: table-cell;
          font-size: 14px;
          font-weight: normal;
          letter-spacing: normal;
          padding: 5px 0;
          text-transform: none;
          max-height: 350px;
          overflow-y: auto; }
        div.cs-skin-slide .cs-options ul li span:hover,
        div.cs-skin-slide .cs-options ul li.cs-focus span,
        div.cs-skin-slide .cs-options ul li.cs-selected span {
          color: #2c2c2c; }

.cs-backdrop {
  background: none repeat scroll 0 0 #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.cs-skin-slide.cs-active .cs-options li:nth-child(6) {
  transition-delay: 0.3s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(7) {
  transition-delay: 0.35s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(8) {
  transition-delay: 0.4s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(9) {
  transition-delay: 0.45s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(10) {
  transition-delay: 0.5s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(11) {
  transition-delay: 0.55s; }

.cs-input-group-addon {
  background: #f0f0f0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 6px 10px; }

/* end overrides */
.twitter-typeahead {
  width: 100%; }

.tt-menu {
  width: 100%;
  padding: 3px 10px 3px 3px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-top: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15); }

.tt-suggestion {
  color: #626262;
  padding: 3px 7px 4px;
  padding-left: 26px;
  line-height: 24px; }
  .tt-suggestion:hover {
    cursor: pointer;
    background: #f0f0f0; }
  .tt-suggestion.tt-cursor {
    background: #f0f0f0; }
  .tt-suggestion p {
    margin: 0; }

.form-group-default.typehead {
  overflow: inherit; }

.form-group-default .tt-menu {
  width: calc(100% + 24px);
  margin: 4px -12px; }

.dropzone {
  overflow: hidden; }
  .dropzone .dz-default.dz-message {
    width: 100%; }

/* Dropzone for Angular */
.dropzone[dropzone="dropzone"] input[type="file"] {
  visibility: hidden; }

/* end overrides */
/* Demo specifc classes */
.demo-form-wysiwyg {
  height: 250px; }

.error-form .form-group-default {
  padding: 0;
  justify-content: space-between; }
  .error-form .form-group-default label {
    margin-top: 6px;
    padding-left: 12px; }
  .error-form .form-group-default .form-control {
    margin-top: -2px;
    margin-bottom: 3px;
    padding-left: 12px; }

.error-container.bottom-container {
  margin-top: 0; }

.h-c-50 {
  height: calc(50px); }

.card-toolbar .note-editor .btn-toolbar {
  display: block; }

/* Breadcrumbs
------------------------------------
*/
.breadcrumb {
  font-family: 'Montserrat';
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  box-shadow: none;
  background-color: transparent;
  padding: 15px 0;
  margin: 0;
  border: none;
  text-transform: uppercase; }
  .breadcrumb.breadcrumb-alt {
    padding: 8px 0;
    text-transform: none; }
    .breadcrumb.breadcrumb-alt a {
      letter-spacing: normal;
      color: #000;
      font-size: 12px !important; }
    .breadcrumb.breadcrumb-alt > .breadcrumb-item,
    .breadcrumb.breadcrumb-alt > .breadcrumb-item.active {
      letter-spacing: normal;
      opacity: .46;
      color: #000;
      font-size: 12px !important; }
  .breadcrumb a {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 10.5px !important;
    letter-spacing: 0.06em;
    font-weight: 400;
    color: #7b7d82; }
  .breadcrumb .breadcrumb-item {
    float: left;
    padding-left: 0px; }
  .breadcrumb > .breadcrumb-item + .breadcrumb-item:before {
    padding: 0 5px;
    color: #515050;
    font-family: FontAwesome;
    content: "\f105";
    font-size: 12px;
    font-weight: bold; }
  .breadcrumb > .breadcrumb-item.active {
    font-weight: 500;
    color: #0090d9;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 10.5px !important;
    letter-spacing: 0.06em; }
    .breadcrumb > .breadcrumb-item.active:before {
      padding-right: 10px; }

.row {
  margin-right: -8px;
  margin-left: -8px; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  padding-right: 8px;
  padding-left: 8px; }

.row + .row {
  margin-top: 24px; }

.form-group.row + .form-group.row {
  margin-top: 0; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.btn {
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500; }
  .btn:focus, .btn:active, .btn.focus {
    outline: none !important;
    box-shadow: none !important; }
  .btn:disabled {
    opacity: 0.3;
    cursor: not-allowed; }

.btn-close span {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  vertical-align: middle; }

.btn-link {
  color: rgba(0, 0, 0, 0.87); }

.navbar-toggler {
  padding: 5px; }

.btn.btn-fab {
  border-radius: 50%;
  height: 56px;
  margin: auto;
  min-width: 56px;
  width: 56px;
  padding: 0;
  overflow: hidden; }
  .btn.btn-fab.btn-sm {
    height: 30px;
    min-width: 30px;
    width: 30px; }

.btn-primary {
  background-color: #FF6666;
  color: #ffffff;
  border-color: #FF6666; }
  .btn-primary:hover {
    background: #FF6666;
    /* The Fallback */
    background: rgba(255, 102, 102, 0.7);
    border-color: #FF6666;
    color: #ffffff; }
  .btn-primary:focus, .btn-primary:active {
    background-color: #BF360C !important;
    border-color: #BF360C !important; }
  .btn-primary:disabled {
    background-color: #FF6666;
    border-color: #FF6666;
    color: #ffffff; }

.btn-default {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-color: #ddd; }
  .btn-default:hover {
    border-color: #000000;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.87) !important;
    box-shadow: none !important; }
  .btn-default:focus, .btn-default:active {
    color: rgba(0, 0, 0, 0.87) !important;
    background-color: #eeeeee !important; }
  .btn-default:disabled {
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.87) !important; }

.btn-secondary {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-color: #ddd; }
  .btn-secondary:hover {
    border-color: #000000;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.87) !important;
    box-shadow: none !important; }
  .btn-secondary:focus, .btn-secondary:active {
    color: rgba(0, 0, 0, 0.87) !important;
    background-color: #eeeeee !important; }
  .btn-secondary:disabled {
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.87) !important; }

/* btn group and input group material icons */
.btn-group .btn > i.material-icons,
.input-group .input-group-append .btn > i.material-icons,
.input-group .input-group-prepend .btn > i.material-icons {
  font-size: 22px; }

/* group button toggle */
.btn-group.btn-group-toggle i.material-icons {
  color: #cccccc !important; }

.btn-group.btn-group-toggle .btn-default.active {
  background-color: #dddddd !important; }

.btn-group.btn-group-toggle .btn-default.active i.material-icons {
  color: #666666 !important; }

/* dropdown */
.dropdown.show .dropdown-toggle::after {
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent; }

.dropdown > .dropdown-menu > a.dropdown-item:active, .dropdown > .dropdown-menu > a.dropdown-item.active, .dropdown > .dropdown-menu > a.dropdown-item.selected {
  background-color: #1976D2; }

.dropdown-item > .dropdown-menu {
  top: auto;
  bottom: 0; }

.dropdown-item:hover > .dropdown-menu {
  display: block; }

/* Buttons
*/
/*
[Buttons Base Styles]
*/
.btn {
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  margin-bottom: 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none !important;
  color: #626262;
  background-color: #fff;
  text-shadow: none;
  box-shadow: none;
  line-height: 21px;
  position: relative;
  transition: color 0.1s linear 0s,background-color 0.1s linear 0s,opacity 0.2s linear 0s !important;
  padding: 6px 17px; }
  .btn:hover {
    background-color: #fafafa;
    border: 1px solid rgba(98, 98, 98, 0.27);
    color: #333; }
  .btn.active {
    border-color: #e6e6e6;
    background: #fff;
    color: #333; }
  .btn:focus, .btn:active:focus, .btn.active:focus {
    outline: none !important;
    outline-style: none;
    color: #333; }
  .btn .caret {
    margin-left: 3px; }
    .btn .caret.single {
      margin-left: 0px; }
  .btn:hover, .btn:focus, .btn:active, .btn.active, .btn.disabled, .btn[disabled] {
    box-shadow: none; }

button:focus {
  outline: none !important; }

/*
Alternate buttons
--------------------------------------------------
*/
.btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #FF6666;
  border-color: #FF6666; }

.btn-primary.active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary.active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled):active:hover,
.btn-primary .show .dropdown-toggle.btn-primary {
  background-color: #cf5353;
  border-color: #cf5353;
  color: #fff;
  box-shadow: none; }

.btn-primary.hover, .btn-primary:hover,
.btn-primary .show .dropdown-toggle.btn-primary {
  background-color: #ff8585;
  border-color: #ff8585;
  color: #fff; }

.btn-primary.active:hover {
  background: #db5858;
  border-color: #db5858; }

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: #FF6666;
  border-color: #FF6666; }

.btn-primary .badge {
  color: #FF6666;
  background-color: #fff; }

.btn-success, .btn-success:focus {
  color: #fff;
  background-color: #10cfbd;
  border-color: #10cfbd; }

.btn-success.active, .btn-success:not(:disabled):not(.disabled):active, .btn-success.active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled):active:hover,
.btn-success .show .dropdown-toggle.btn-success {
  background-color: #0da899;
  border-color: #0da899;
  color: #fff;
  box-shadow: none; }

.btn-success.hover, .btn-success:hover,
.btn-success .show .dropdown-toggle.btn-success {
  background-color: #40d9ca;
  border-color: #40d9ca;
  color: #fff; }

.btn-success.active:hover {
  background: #0eb2a3;
  border-color: #0eb2a3; }

.btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled]:active, .btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
  background-color: #10cfbd;
  border-color: #10cfbd; }

.btn-success .badge {
  color: #10cfbd;
  background-color: #fff; }

.btn-complete, .btn-complete:focus {
  color: #fff;
  background-color: #48b0f7;
  border-color: #48b0f7; }

.btn-complete.active, .btn-complete:not(:disabled):not(.disabled):active, .btn-complete.active:focus, .btn-complete:not(:disabled):not(.disabled):active:focus, .btn-complete:not(:disabled):not(.disabled):active:hover,
.btn-complete .show .dropdown-toggle.btn-complete {
  background-color: #3a8fc8;
  border-color: #3a8fc8;
  color: #fff;
  box-shadow: none; }

.btn-complete.hover, .btn-complete:hover,
.btn-complete .show .dropdown-toggle.btn-complete {
  background-color: #6dc0f9;
  border-color: #6dc0f9;
  color: #fff; }

.btn-complete.active:hover {
  background: #3e97d4;
  border-color: #3e97d4; }

.btn-complete.disabled, .btn-complete.disabled:hover, .btn-complete.disabled:focus, .btn-complete.disabled:active, .btn-complete.disabled.active, .btn-complete[disabled], .btn-complete[disabled]:hover, .btn-complete[disabled]:focus, .btn-complete[disabled]:active, .btn-complete[disabled].active,
fieldset[disabled] .btn-complete,
fieldset[disabled] .btn-complete:hover,
fieldset[disabled] .btn-complete:focus,
fieldset[disabled] .btn-complete:active,
fieldset[disabled] .btn-complete.active {
  background-color: #48b0f7;
  border-color: #48b0f7; }

.btn-complete .badge {
  color: #48b0f7;
  background-color: #fff; }

.btn-info, .btn-info:focus {
  color: #fff;
  background-color: #2979FF;
  border-color: #2979FF; }

.btn-info.active, .btn-info:not(:disabled):not(.disabled):active, .btn-info.active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled):active:hover,
.btn-info .show .dropdown-toggle.btn-info {
  background-color: #2162cf;
  border-color: #2162cf;
  color: #fff;
  box-shadow: none; }

.btn-info.hover, .btn-info:hover,
.btn-info .show .dropdown-toggle.btn-info {
  background-color: #5494ff;
  border-color: #5494ff;
  color: #fff; }

.btn-info.active:hover {
  background: #2368db;
  border-color: #2368db; }

.btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled]:active, .btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
  background-color: #2979FF;
  border-color: #2979FF; }

.btn-info .badge {
  color: #2979FF;
  background-color: #fff; }

.show > .btn-info.dropdown-toggle:focus {
  background-color: #2979FF;
  border-color: #2979FF; }

.btn-warning, .btn-warning:focus {
  color: #fff;
  background-color: #f8d053;
  border-color: #f8d053; }

.btn-warning.active, .btn-warning:not(:disabled):not(.disabled):active, .btn-warning.active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled):active:hover,
.btn-warning .show .dropdown-toggle.btn-warning {
  background-color: #c9a843;
  border-color: #c9a843;
  color: #fff;
  box-shadow: none; }

.btn-warning.hover, .btn-warning:hover,
.btn-warning .show .dropdown-toggle.btn-warning {
  background-color: #f9d975;
  border-color: #f9d975;
  color: #fff; }

.btn-warning.active:hover {
  background: #d5b347;
  border-color: #d5b347; }

.btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled]:active, .btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
  background-color: #f8d053;
  border-color: #f8d053; }

.btn-warning .badge {
  color: #f8d053;
  background-color: #fff; }

.btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #f55753;
  border-color: #f55753; }

.btn-danger.active, .btn-danger:not(:disabled):not(.disabled):active, .btn-danger.active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled):active:hover,
.btn-danger .show .dropdown-toggle.btn-danger {
  background-color: #c64643;
  border-color: #c64643;
  color: #fff;
  box-shadow: none; }

.btn-danger.hover, .btn-danger:hover,
.btn-danger .show .dropdown-toggle.btn-danger {
  background-color: #f77975;
  border-color: #f77975;
  color: #fff; }

.btn-danger.active:hover {
  background: #d34b47;
  border-color: #d34b47; }

.btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled]:active, .btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  background-color: #f55753;
  border-color: #f55753; }

.btn-danger .badge {
  color: #f55753;
  background-color: #fff; }

.btn-default, .btn-default:focus {
  color: #5e5e5e;
  background-color: #fff;
  border-color: #dddddd; }

.btn-default.active, .btn-default:active, .btn-default.active:focus, .btn-default:active:focus, .btn-default:active:hover {
  background-color: #f0f0f0;
  border-color: #e6e6e6;
  color: #2c2c2c; }

.btn-default.hover, .btn-default:hover {
  background-color: #fafafa;
  border-color: rgba(98, 98, 98, 0.27);
  color: #1a1a1a; }

.btn-default.active:hover {
  background: #f0f0f0; }

.btn-link {
  color: #5e5e5e;
  background-color: transparent;
  border: none; }
  .btn-link:hover, .btn-link:focus, .btn-link:active, .btn-link.active, .btn-link.disabled, .btn-link[disabled] {
    background-color: transparent;
    border: none;
    text-decoration: none;
    outline: none; }

.btn-file {
  position: relative;
  overflow: hidden; }

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  background: red;
  cursor: inherit;
  display: block; }

/*
Button Sizes
--------------------------------------------------
*/
.btn-lg, .btn-group-lg > .btn {
  padding-left: 28px;
  padding-right: 28px;
  line-height: 22px;
  padding-top: 10px;
  padding-bottom: 10px; }

.btn-sm, .btn-group-sm > .btn {
  padding-left: 16px;
  padding-right: 16px;
  font-size: 11.9px;
  line-height: 20px; }

.btn-xs, .btn-group-xs > .btn {
  padding: 2px 9px;
  font-size: 10.5px; }

.btn-cons {
  margin-right: 5px;
  min-width: 120px; }

/*
Rounded buttons
--------------------------------------------------
*/
.btn-rounded {
  border-radius: 100px !important; }

/*
 Dropdown menus
--------------------------------------------------
*/
.show .btn-group.show .dropdown-toggle,
.show .dropdown-toggle,
.show.dropdown-default .dropdown-toggle {
  box-shadow: none; }

.dropdown.open {
  display: block; }

.dropdown:focus {
  outline: none; }

/* default dropdown */
.btn-group > .el-tooltip:not(:last-child) .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .el-tooltip:not(:first-child) .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group > .el-tooltip:not(:first-child) .btn {
  margin-left: -1px; }

.btn-group.dropup .dropdown-toggle::after {
  margin-left: 10px; }

.dropdown-default {
  display: inline-block; }
  .dropdown-default.toggle-only {
    padding-left: 9px;
    padding-right: 9px; }
    .dropdown-default.toggle-only:after {
      position: initial; }
  .dropdown-default.show > .btn.dropdown-toggle {
    border-color: transparent !important;
    background: transparent !important;
    z-index: 791 !important; }
  .dropdown-default.show .dropdown-menu {
    opacity: 1 !important;
    transform: scale(1, 1) !important;
    z-index: 700 !important;
    display: block;
    visibility: visible; }
    .dropdown-default.show .dropdown-menu .dropdown-item {
      visibility: visible; }
  .dropdown-default.show.dropup > .btn-primary + .dropdown-menu:after,
  .dropdown-default.show.dropup > .btn-success + .dropdown-menu:after,
  .dropdown-default.show.dropup > .btn-complete + .dropdown-menu:after,
  .dropdown-default.show.dropup > .btn-warning + .dropdown-menu:after,
  .dropdown-default.show.dropup > .btn-danger + .dropdown-menu:after,
  .dropdown-default.show.dropup > .btn-info + .dropdown-menu:after {
    top: auto;
    bottom: 0; }
  .dropdown-default.show > .btn-primary + .dropdown-menu:after,
  .dropdown-default.show > .btn-success + .dropdown-menu:after,
  .dropdown-default.show > .btn-complete + .dropdown-menu:after,
  .dropdown-default.show > .btn-warning + .dropdown-menu:after,
  .dropdown-default.show > .btn-danger + .dropdown-menu:after,
  .dropdown-default.show > .btn-info + .dropdown-menu:after {
    top: 0;
    height: 42px; }
  .dropdown-default.show > .btn-primary + .dropdown-menu:after {
    background-color: #FF6666; }
  .dropdown-default.show > .btn-success + .dropdown-menu:after {
    background-color: #10cfbd; }
  .dropdown-default.show > .btn-complete + .dropdown-menu:after {
    background-color: #48b0f7; }
  .dropdown-default.show > .btn-warning + .dropdown-menu:after {
    background-color: #f8d053; }
  .dropdown-default.show > .btn-danger + .dropdown-menu:after {
    background-color: #f55753; }
  .dropdown-default.show > .btn-info + .dropdown-menu:after {
    background-color: #2979FF; }
  .dropdown-default.dropup .btn.dropdown-toggle.btn-lg + .dropdown-menu {
    margin-bottom: -47px;
    padding-bottom: 49px; }
    .dropdown-default.dropup .btn.dropdown-toggle.btn-lg + .dropdown-menu:after {
      bottom: 49px; }
  .dropdown-default.dropup .btn.dropdown-toggle.btn-sm + .dropdown-menu {
    margin-bottom: -34px;
    padding-bottom: 36px; }
    .dropdown-default.dropup .btn.dropdown-toggle.btn-sm + .dropdown-menu:after {
      bottom: 36px; }
  .dropdown-default.dropup .btn.dropdown-toggle.btn-xs + .dropdown-menu {
    margin-bottom: -29px;
    padding-bottom: 31px; }
    .dropdown-default.dropup .btn.dropdown-toggle.btn-xs + .dropdown-menu:after {
      bottom: 31px; }
  .dropdown-default.dropup .dropdown-menu {
    margin-bottom: -40px;
    padding: 0 3px 44px 0 !important;
    transform-origin: center bottom 0; }
    .dropdown-default.dropup .dropdown-menu:after {
      bottom: 43px;
      top: auto !important; }
  .dropdown-default .btn.dropdown-toggle {
    text-align: left;
    padding-right: 27px; }
    .dropdown-default .btn.dropdown-toggle.btn-lg {
      padding-right: 42px; }
      .dropdown-default .btn.dropdown-toggle.btn-lg:after {
        right: 26px; }
      .dropdown-default .btn.dropdown-toggle.btn-lg + .dropdown-menu {
        margin-top: -47px;
        padding-top: 49px; }
        .dropdown-default .btn.dropdown-toggle.btn-lg + .dropdown-menu:after {
          top: 49px; }
    .dropdown-default .btn.dropdown-toggle.btn-sm {
      padding-right: 26px; }
      .dropdown-default .btn.dropdown-toggle.btn-sm:after {
        right: 16px; }
      .dropdown-default .btn.dropdown-toggle.btn-sm + .dropdown-menu {
        margin-top: -34px;
        padding-top: 36px; }
        .dropdown-default .btn.dropdown-toggle.btn-sm + .dropdown-menu:after {
          top: 36px; }
    .dropdown-default .btn.dropdown-toggle.btn-xs {
      padding-right: 21px; }
      .dropdown-default .btn.dropdown-toggle.btn-xs:after {
        right: 8px; }
      .dropdown-default .btn.dropdown-toggle.btn-xs + .dropdown-menu {
        margin-top: -29px;
        padding-top: 31px; }
        .dropdown-default .btn.dropdown-toggle.btn-xs + .dropdown-menu:after {
          top: 31px; }
    .dropdown-default .btn.dropdown-toggle:after {
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -2px; }
  .dropdown-default .btn-rounded {
    padding-left: 17px;
    padding-right: 17px; }
  .dropdown-default .btn-rounded + .dropdown-menu {
    border-radius: 17px; }
  .dropdown-default .dropdown-menu {
    margin-top: -4px;
    width: 100%;
    padding-top: 42px;
    overflow: hidden;
    backface-visibility: hidden;
    display: none;
    visibility: hidden;
    opacity: 0;
    transform: scale(1, 0);
    transform-origin: center top 0;
    -webkit-transition: all 170ms cubic-bezier(0.05, 0.74, 0.27, 0.99) 0s;
    transition: all 170ms cubic-bezier(0.05, 0.74, 0.27, 0.99) 0s;
    z-index: -1; }
    .dropdown-default .dropdown-menu:after {
      content: "";
      position: absolute;
      height: 1px;
      left: 0;
      top: 42px;
      background: #e6e6e6;
      width: 100%; }
    .dropdown-default .dropdown-menu .dropdown-item {
      visibility: hidden; }

.navbar-nav .dropdown-menu {
  position: absolute; }

.dropdown-menu {
  position: absolute;
  display: none;
  float: left;
  list-style: none;
  text-shadow: none;
  box-shadow: 0px 0px 5px rgba(98, 98, 98, 0.2);
  border: none;
  border-radius: 3px;
  font-size: 13px;
  margin: 0;
  background: #fafafa;
  min-width: 50px;
  z-index: 999999 !important; }
  .dropdown-menu:focus {
    outline: none; }
  .dropdown-menu .divider {
    background-color: #e6e6e6;
    height: 1px;
    margin: 3px 0;
    border-bottom: 0px; }
  .dropdown-menu > .dropdown-item {
    padding-left: 0px;
    line-height: 35px;
    color: #626262;
    padding: 0 20px;
    display: block;
    clear: both;
    white-space: nowrap;
    text-align: left; }
    .dropdown-menu > .dropdown-item:hover, .dropdown-menu > .dropdown-item:focus, .dropdown-menu > .dropdown-item:active {
      color: #1a1a1a;
      text-decoration: none;
      background-color: transparent; }
    .dropdown-menu > .dropdown-item.dropdown-header {
      padding: 3px 20px; }
    .dropdown-menu > .dropdown-item.active, .dropdown-menu > .dropdown-item.active:hover, .dropdown-menu > .dropdown-item.active:focus {
      text-decoration: none; }
    .dropdown-menu > .dropdown-item.disabled, .dropdown-menu > .dropdown-item.disabled:hover, .dropdown-menu > .dropdown-item.disabled:focus {
      color: #959595; }
    .dropdown-menu > .dropdown-item.disabled:hover, .dropdown-menu > .dropdown-item.disabled:focus {
      text-decoration: none;
      cursor: default; }

.dropdown-backdrop {
  z-index: 600; }

/*
Animated buttons
--------------------------------------------------
*/
.btn-animated {
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d; }
  .btn-animated > span {
    display: inline-block;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d; }
  .btn-animated:before {
    position: absolute;
    height: 100%;
    width: 100%;
    font-size: 100%;
    line-height: 2.5;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
  .btn-animated:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
  .btn-animated.from-top:before {
    left: 0;
    top: -100%; }
  .btn-animated.from-top:hover:before, .btn-animated.from-top.show-icon:before {
    top: 0; }
  .btn-animated.from-top:hover > span, .btn-animated.from-top.show-icon > span {
    -webkit-transform: translateY(300%);
    -ms-transform: translateY(300%);
    transform: translateY(300%); }
  .btn-animated.from-left:before {
    left: -100%;
    top: 0; }
  .btn-animated.from-left:hover:before, .btn-animated.from-left.show-icon:before {
    left: 0; }
  .btn-animated.from-left:hover > span, .btn-animated.from-left.show-icon > span {
    -webkit-transform: translateX(200%);
    -ms-transform: translateX(200%);
    transform: translateX(200%); }
  .btn-animated.fa:before {
    font-family: FontAwesome; }
  .btn-animated.pg:before {
    font-family: "pages-icon"; }

/*
Tag buttons
--------------------------------------------------
*/
.btn-tag {
  line-height: 17px;
  border-radius: 17px 3px 3px 17px;
  padding: 5px 19px; }
  .btn-tag:hover, .btn-tag.hover {
    border-color: transparent; }
  .btn-tag.btn-tag-light {
    background: #fafdff;
    color: #5b8ca5;
    border: 1px solid #cbe4f0; }
    .btn-tag.btn-tag-light:hover {
      background: #fff; }
  .btn-tag.btn-tag-dark {
    background: #e6e6e6;
    color: #626262; }
    .btn-tag.btn-tag-dark:hover {
      background: #ebebeb; }
  .btn-tag.btn-tag-rounded {
    border-radius: 17px; }

/*
Misc buttons
--------------------------------------------------
*/
.btn-toolbar .btn {
  padding-left: 14px;
  padding-right: 14px; }

.modal-content a.btn-primary:not([href]):not([tabindex]) {
  color: #fff; }
  .modal-content a.btn-primary:not([href]):not([tabindex]):hover, .modal-content a.btn-primary:not([href]):not([tabindex]):focus {
    color: #fff; }

.sgbadge {
  position: relative;
  white-space: nowrap;
  margin-right: 24px;
  color: rgba(0, 0, 0, 0.38); }
  .sgbadge[data-badge]:after {
    content: attr(data-badge);
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    top: -11px;
    right: -24px;
    font-weight: 600;
    font-size: 12px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #FF6666;
    color: #fff; }
  .sgbadge[data-badge].secondary:after {
    background: #999999; }
  .sgbadge[data-badge].info:after {
    background: #2196f3; }
  .sgbadge[data-badge].danger:after {
    background: #f44336; }
  .sgbadge[data-badge].warning:after {
    background: #ffc107; }
  .sgbadge.sgbadge-overlap {
    margin-right: 10px; }
  .sgbadge.sgbadge-overlap:after {
    right: -10px; }

/* Typography */
/* Standard elements */
html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

html > body {
  font-family: ‘Hiragino Kaku Gothic ProN’, ‘ヒラギノ角ゴ ProN W3’, Meiryo, メイリオ, wf_segoe-ui_light, wf_segoe-ui_normal, "Helvetica Neue", Helvetica, Arial, sans-serif; }

html[lang="en"] > body {
  font-family: wf_segoe-ui_light, wf_segoe-ui_normal, "Helvetica Neue", Helvetica, Arial, sans-serif; }

html[translate="no"] > body {
  font-family: wf_segoe-ui_light, wf_segoe-ui_normal, "Helvetica Neue", Helvetica, Arial, sans-serif; }

body {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 160%;
  position: relative;
  color: rgba(0, 0, 0, 0.87);
  background-color: #eee;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1; }
  @media screen and (max-width: 576px) {
    body {
      font-size: 16px;
      font-size: 1rem; } }

/* Headings
------------------------------------
*/
h1, h2, h3 {
  line-height: 120%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-family: wf_segoe-ui_light, wf_segoe-ui_normal, "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-weight: 300;
  color: #2c2c2c; }

h1 {
  font-size: 34px;
  font-size: 2.125rem;
  line-height: 55px;
  letter-spacing: -0.08px; }

h2 {
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 40px; }

h3 {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 35px; }

h4 {
  font-size: 20px;
  line-height: 31.88px; }

h5 {
  font-size: 18px;
  line-height: 25.88px; }

h3 small,
h4 small,
h5 small {
  font-weight: 300; }

h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
  padding-bottom: 10px; }

/* Lins and Others
------------------------------------
*/
a {
  text-shadow: none !important;
  color: #0033FF;
  transition: color 0.1s linear 0s,background-color 0.1s linear 0s,opacity 0.2s linear 0s !important; }

a:focus, a:hover, a:active {
  color: #0029cf; }

a, a:focus, a:hover, a:active {
  outline: 0 !important;
  text-decoration: none; }

a[ng-click] {
  cursor: pointer; }

br {
  line-height: normal;
  clear: both; }

code {
  color: #c64643;
  background-color: #f0f0f0; }
  code:hover {
    background-color: #fddddd; }

p {
  display: block;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  line-height: 22px;
  margin: 0px 0px 10px 0px;
  font-style: normal;
  white-space: normal; }

small,
.small {
  line-height: 18px;
  font-size: 85%; }

label.inline {
  display: inline-block;
  position: relative;
  top: 0px;
  font-size: 13px; }

ul, ol {
  margin-bottom: 10px; }
  ul > li, ol > li {
    padding-left: 3px;
    line-height: 24px; }
  ul.lg-icon > li, ol.lg-icon > li {
    font-size: 21px; }
    ul.lg-icon > li span, ol.lg-icon > li span {
      font-size: 14px; }
  ul.no-style, ol.no-style {
    list-style: none;
    padding-left: 5px; }

address {
  margin-bottom: 0px; }
  address a {
    color: #626262; }

blockquote {
  padding: 0 0 0 18px;
  border-left: 0; }
  blockquote:before {
    content: '';
    font-family: FontAwesome;
    content: "\f10d";
    margin-right: 13px;
    float: left; }
  blockquote p {
    font-size: 16px; }
  blockquote small {
    line-height: 29px;
    color: #8b91a0;
    padding-left: 30px; }
    blockquote small:before {
      content: ""; }
  blockquote.pull-right {
    border-right: 0; }
    blockquote.pull-right:before {
      float: right;
      content: '';
      font-family: FontAwesome;
      content: "\f10d";
      margin-left: 13px;
      margin-right: 0; }
    blockquote.pull-right small {
      padding-right: 30px; }
      blockquote.pull-right small:after {
        content: ""; }

hr {
  border-color: #e6e6e6; }
  hr.double {
    border-width: 2px; }
  hr.dotted {
    border-style: dotted none none; }

/* Font Sizes
------------------------------------
*/
.small-text {
  font-size: 12px !important; }

.normal-text {
  font-size: 13px !important; }

.large-text {
  font-size: 15px !important; }

/* Font Weights
------------------------------------
 */
.normal {
  font-weight: normal; }

.semi-bold {
  font-weight: 400 !important; }

.bold {
  font-weight: bold !important; }

.light {
  font-weight: 300 !important; }

/* Misc
------------------------------------
*/
.logo {
  margin: 18px 14px; }

.all-caps {
  text-transform: uppercase; }

.muted {
  color: #e2e2e2; }

.hint-text {
  opacity: .7; }

.no-decoration {
  text-decoration: none !important; }

.text-caption {
  font-size: 12px;
  font-size: 0.75rem; }

.subtext {
  color: #999999; }

.text-muted {
  opacity: .7; }

strong {
  font-weight: 500; }

/* Monochrome Colors
------------------------------------
 */
.bg-master {
  background-color: #626262; }

.bg-master-light {
  background-color: #e6e6e6; }

.bg-master-lighter {
  background-color: #f0f0f0; }

.bg-master-lightest {
  background-color: #fafafa; }

.bg-master-dark {
  background-color: #2c2c2c; }

.bg-master-darker {
  background-color: #1a1a1a; }

.bg-master-darkest {
  background-color: #121212; }

/* Contextual Colors
------------------------------------
*/
/* Primary
------------------------------------
*/
.bg-primary {
  background-color: #FF6666 !important; }

.bg-primary-dark {
  background-color: #cf5353; }

.bg-primary-darker {
  background-color: #993d3d; }

.bg-primary-light {
  background-color: #ff8585; }

.bg-primary-lighter {
  background-color: #ffe0e0; }

/* Complete
------------------------------------
*/
.bg-complete {
  background-color: #48b0f7; }

.bg-complete-dark {
  background-color: #3a8fc8; }

.bg-complete-darker {
  background-color: #2b6a94; }

.bg-complete-light {
  background-color: #6dc0f9; }

.bg-complete-lighter {
  background-color: #daeffd; }

/* Success
------------------------------------
*/
.bg-success {
  background-color: #10cfbd !important; }

.bg-success-dark {
  background-color: #0da899; }

.bg-success-darker {
  background-color: #0a7c71; }

.bg-success-light {
  background-color: #40d9ca; }

.bg-success-lighter {
  background-color: #cff5f2; }

/* Info
------------------------------------
*/
.bg-info {
  background-color: #2979FF !important; }

.bg-info-dark {
  background-color: #2162cf; }

.bg-info-darker {
  background-color: #194999; }

.bg-info-light {
  background-color: #5494ff; }

.bg-info-lighter {
  background-color: #d4e4ff; }

/* Danger
------------------------------------
*/
.bg-danger {
  background-color: #f55753 !important; }

.bg-danger-dark {
  background-color: #c64643; }

.bg-danger-darker {
  background-color: #933432; }

.bg-danger-light {
  background-color: #f77975; }

.bg-danger-lighter {
  background-color: #fddddd; }

/* Warning
------------------------------------
 */
.bg-warning {
  background-color: #f8d053 !important; }

.bg-warning-dark {
  background-color: #c9a843; }

.bg-warning-darker {
  background-color: #957d32; }

.bg-warning-light {
  background-color: #f9d975; }

.bg-warning-lighter {
  background-color: #fef6dd; }

/* More Color Options
------------------------------------
*/
/* Gradients
------------------------------------
*/
.gradient-grey {
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 75%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 75%); }

.gradient-black {
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 75%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 75%); }

/* Other Colors
------------------------------------
*/
.bg-white {
  background-color: #fff; }

.bg-transparent {
  background-color: transparent !important; }

/* Text Colors */
.link {
  opacity: .7; }
  .link:hover {
    opacity: 1; }

.text-master {
  color: #626262 !important; }

.text-master-light {
  color: #e6e6e6 !important; }

.text-black {
  color: #2c2c2c !important; }

.text-white {
  color: #fff !important; }

.text-complete {
  color: #48b0f7 !important; }

.text-success {
  color: #10cfbd !important; }

.text-info {
  color: #2979FF !important; }

.text-warning {
  color: #f8d053 !important; }

.text-warning-dark {
  color: #c9a843 !important; }

.text-danger {
  color: #f55753 !important; }

.text-primary {
  color: #FF6666 !important; }

/* Text Aligngments
------------------------------------
*/
.text-right {
  text-align: right !important; }

.text-left {
  text-align: left !important; }

.text-center {
  text-align: center !important; }

/* Labels
------------------------------------
*/
.label {
  padding: 3px 9px;
  font-size: 11px;
  line-height: 1;
  text-shadow: none;
  background-color: #e6e6e6;
  font-weight: 600;
  color: #626262;
  border-radius: .25em; }

.label-success {
  background-color: #10cfbd;
  color: #fff; }

.label-warning {
  background-color: #f8d053;
  color: #fff; }

.label-important, .label-danger {
  background-color: #f55753;
  color: #fff; }

.label-info {
  background-color: #48b0f7;
  color: #fff; }

.label-inverse {
  background-color: #3a8fc8;
  color: #fff; }

.label-white {
  background-color: #fff;
  color: #626262; }

/* Font Sizes
------------------------------------
*/
.fs-10 {
  font-size: 10px !important; }

.fs-11 {
  font-size: 10.5px !important; }

.fs-12 {
  font-size: 12px !important; }

.fs-13 {
  font-size: 13px !important; }

.fs-14 {
  font-size: 14px !important; }

.fs-15 {
  font-size: 15px !important; }

.fs-16 {
  font-size: 16px !important; }

/* Line-heights
------------------------------------
*/
.lh-normal {
  line-height: normal; }

.lh-10 {
  line-height: 10px; }

.lh-11 {
  line-height: 11px; }

.lh-12 {
  line-height: 12px; }

.lh-13 {
  line-height: 13px; }

.lh-14 {
  line-height: 14px; }

.lh-15 {
  line-height: 15px; }

.lh-16 {
  line-height: 16px; }

.lh-17 {
  line-height: 17px; }

.lh-18 {
  line-height: 18px; }

/* Font Faces
------------------------------------
*/
.font-arial {
  font-family: Arial, sans-serif !important; }

.font-montserrat {
  font-family: 'Montserrat' !important; }

.font-georgia {
  font-family: Georgia !important; }

.font-heading {
  font-family: wf_segoe-ui_light, wf_segoe-ui_normal, "Helvetica Neue", Helvetica, Arial, sans-serif; }

/* Wells
------------------------------------
*/
.well {
  background-color: #e6e6e6;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  background-image: none; }
  .well.well-large {
    padding: 24px;
    width: auto; }
  .well.well-small {
    padding: 13px;
    width: auto; }
  .well.green {
    background-color: #48b0f7;
    color: #fff;
    border: none; }

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.jumbotron {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0; }
  .jumbotron p {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: inherit; }
    .jumbotron p.small {
      font-size: 85%; }

/* Responsive Handlers : Typo
------------------------------------
*/
@media (max-width: 1400px) {
  body, p {
    font-size: 14px;
    line-height: 20px; }
  h1 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 44px;
    letter-spacing: -0.08px; }
  h2 {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 40px; }
  h3 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 35.88px; }
  h4 {
    font-size: 18px;
    line-height: 33.88px; }
  h5 {
    font-size: 16px;
    line-height: 25.88px; }
  small,
  .small {
    font-size: 89%;
    line-height: 17px; } }

/* For Windows : Fixes
------------------------------------
*/
.windows body,
.windows p {
  font-size: 13px;
  letter-spacing: normal; }

.windows h3 {
  font-size: 29px;
  line-height: 33px; }

.windows h4 {
  font-size: 23px;
  line-height: 32px; }

.windows h5 {
  font-size: 19px;
  line-height: 28px;
  font-weight: normal; }

.windows h1,
.windows h2,
.windows h3,
.windows h4,
.windows h5 {
  letter-spacing: normal; }

.windows .jumbotron p {
  font-size: 13px; }
  .windows .jumbotron p.small {
    font-size: 85%; }

.windows small,
.windows .small {
  font-size: 89%; }

.alert > p, .alert > ul {
  margin-bottom: 0; }

.table > tbody > tr > td, .table > tbody > tr > th {
  line-height: 1.42857143; }

.table > tfoot > tr > td, .table > tfoot > tr > th {
  line-height: 1.42857143; }

.table > thead > tr > td, .table > thead > tr > th {
  line-height: 1.42857143; }

.react-autosuggest__suggestions-container {
  position: fixed;
  display: none;
  overflow: auto;
  z-index: 99999999;
  top: 0;
  left: 0; }

.react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
  display: block; }

.react-autosuggest__suggestions-list {
  width: 200px;
  height: auto;
  max-height: 400px;
  border: 1px solid #ddd;
  background-color: #fafafa;
  list-style: none;
  padding-left: 0; }
  .react-autosuggest__suggestions-list li {
    padding: 5px 10px;
    cursor: pointer; }
  .react-autosuggest__suggestions-list li.react-autosuggest__suggestion--highlighted {
    background-color: #ffffff; }

.input-group .react-autosuggest__container:not(:first-child) .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .form-control + .react-autosuggest__container,
.input-group > .custom-select + .react-autosuggest__container,
.input-group > .react-autosuggest__container + .custom-select,
.input-group > .react-autosuggest__container + .form-control {
  margin-left: -1px; }

.input-group > .react-autosuggest__container {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0; }

/* form
*/
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #ced4da; }

/* radio
*/
.ui-radio {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin: 2px auto;
  margin-right: 10px;
  position: relative;
  display: inline-block; }
  .ui-radio input[type=radio] {
    visibility: hidden; }
  .ui-radio label {
    cursor: pointer;
    position: absolute;
    width: 22px;
    height: 22px;
    min-height: 20px !important;
    top: 0;
    border-radius: 50%;
    left: 0;
    border: 2px solid rgba(0, 0, 0, 0.54);
    background: #ffffff;
    margin: 0; }
  .ui-radio label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FF6666;
    top: 4px;
    left: 4px; }
  .ui-radio input[type=radio]:checked + label {
    border-color: #FF6666;
    color: #ffffff; }
  .ui-radio input[type=radio]:checked + label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1; }
  .ui-radio input[type=radio]:disabled + label,
  .ui-radio.disabled > label {
    background: #f8f8f8;
    border: 2px solid rgba(0, 0, 0, 0.26);
    cursor: not-allowed; }
    .ui-radio input[type=radio]:disabled + label:after,
    .ui-radio.disabled > label:after {
      border-color: #999; }
  .ui-radio input[type=radio]:checked:disabled + label {
    background: #ffffff;
    border-color: rgba(255, 102, 102, 0.26); }
    .ui-radio input[type=radio]:checked:disabled + label:after {
      background: rgba(255, 102, 102, 0.26); }

/* file
*/
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block; }
  .upload-btn-wrapper input[type=file] {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer; }

/* checkbox */
.was-validated .form-check-input:invalid + .form-check-label {
  border-color: #f44336 !important; }

.ui-checkbox {
  width: 20px;
  height: 20px;
  margin: 2px auto;
  position: relative;
  display: inline-block; }
  .ui-checkbox input[type=checkbox] {
    visibility: hidden; }
  .ui-checkbox label {
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    min-height: 20px !important;
    top: 0;
    border-radius: 2px;
    left: 0;
    border: 2px solid rgba(0, 0, 0, 0.54);
    background: #ffffff;
    margin: 0; }
  .ui-checkbox label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 13px;
    height: 8px;
    background: transparent;
    top: 2px;
    left: 2px;
    border: 3px solid #ffffff;
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .ui-checkbox label:hover::after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0; }
  .ui-checkbox input[type=checkbox]:checked + label {
    background: #FF6666;
    border-color: #FF6666;
    color: #ffffff; }
  .ui-checkbox input[type=checkbox]:checked + label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1; }
  .ui-checkbox input[type=checkbox]:disabled + label,
  .ui-checkbox.disabled > label {
    background: #f8f8f8;
    border: 2px solid rgba(0, 0, 0, 0.26);
    cursor: not-allowed; }
    .ui-checkbox input[type=checkbox]:disabled + label:after,
    .ui-checkbox.disabled > label:after {
      border-color: #999; }
  .ui-checkbox input[type=checkbox]:checked:disabled + label {
    background: rgba(0, 0, 0, 0.26);
    border-color: transparent; }
    .ui-checkbox input[type=checkbox]:checked:disabled + label:after {
      border-color: #ffffff; }
  .ui-checkbox label .label-text {
    width: 200px;
    margin-left: 30px;
    display: block;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.87) !important; }

/* input form
*/
::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.24) !important; }

::-moz-placeholder {
  color: rgba(0, 0, 0, 0.24) !important; }

::-ms-placeholder {
  color: rgba(0, 0, 0, 0.24) !important; }

::placeholder {
  color: rgba(0, 0, 0, 0.24) !important; }

input.form-control, textarea.form-control {
  padding: 10px 15px;
  font-weight: 300;
  font-size: 14px;
  border-radius: 2px; }
  input.form-control:focus, input.form-control:hover, textarea.form-control:focus, textarea.form-control:hover {
    border-color: #2979FF;
    outline: none;
    box-shadow: none; }

.img-round {
  background-size: cover;
  border-radius: 50%; }

.modal .modal-dialog {
  box-shadow: 0 1px 1px 0 rgba(155, 155, 155, 0.08), 0 1px 3px 1px rgba(155, 155, 155, 0.16); }
  .modal .modal-dialog .modal-header .modal-title {
    font-size: 22px;
    font-size: 1.375rem; }
  .modal .modal-dialog .modal-header h5 {
    margin: 0; }
  .modal .modal-dialog.modal-fluid {
    width: 100%;
    max-width: 100% !important; }
  .modal .modal-dialog.modal-xs {
    max-width: 400px; }
  .modal .modal-dialog.modal-md {
    max-width: 750px; }
  .modal .modal-dialog .modal-footer button {
    text-transform: uppercase;
    margin-left: 5px; }
  .modal .modal-dialog .modal-footer > :not(:last-child) {
    margin-right: 0; }

.modal.modal-info .modal-content {
  border-radius: 2px; }

.modal.modal-info .modal-header {
  border-bottom: none;
  padding-bottom: 0;
  padding-left: 25px;
  padding-right: 25px; }

.modal.modal-info .modal-header .close[data-dismiss="modal"] {
  display: none; }

.modal.modal-info .modal-body {
  padding-bottom: 0; }

.modal.modal-info .modal-footer {
  border-top: none; }

.modal .close {
  position: absolute;
  right: 25px;
  top: 15px; }

.modal .close:focus {
  outline: 0; }

.modal .modal-dialog {
  transition: all .2s !important; }

.modal .modal-content {
  border: 1px solid #f2f6f7;
  border-radius: 3px;
  box-shadow: none; }

.modal .modal-header {
  text-align: center;
  position: relative;
  flex-direction: column;
  align-items: flex-start; }
  .modal .modal-header p {
    color: #8b91a0; }

.modal .modal-body {
  box-shadow: none;
  padding: 25px;
  white-space: normal; }

.modal .modal-footer {
  box-shadow: none;
  margin-top: 0;
  padding: 10px 25px; }

.modal .drop-shadow {
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36) !important; }

.modal.fade.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0); }

.modal.fade {
  opacity: 1 !important; }
  .modal.fade .modal-dialog {
    transform: none !important; }
  .modal.fade.stick-up .modal-dialog {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    -ms-transform: translate(0, -100%);
    margin-top: -5px; }
  .modal.fade.stick-up.show .modal-dialog, .modal.fade.stick-up.in .modal-dialog {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -ms-transform: translate(0, 0); }
  .modal.fade.slide-up {
    height: 100%;
    top: auto; }
    .modal.fade.slide-up.show .modal-dialog, .modal.fade.slide-up.in .modal-dialog {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      -ms-transform: translate(0, 0); }
    .modal.fade.slide-up.disable-scroll {
      overflow: hidden; }
    .modal.fade.slide-up .modal-dialog {
      display: table;
      height: 100%;
      -webkit-transform: translate3d(0, 75%, 0);
      transform: translate3d(0, 75%, 0);
      -ms-transform: translate(0, 75%); }
    .modal.fade.slide-up .modal-content-wrapper {
      display: table-cell;
      vertical-align: middle; }
  .modal.fade.center-scale .modal-dialog {
    display: table;
    height: 100%; }
  .modal.fade.center-scale .modal-content {
    display: table-cell;
    vertical-align: middle;
    border: 0;
    background: transparent; }
    .modal.fade.center-scale .modal-content .modal-header {
      border-bottom: 0px; }
  .modal.fade.center-scale.show .modal-dialog, .modal.fade.center-scale.in .modal-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  .modal.fade.center-scale .modal-dialog {
    opacity: 0;
    -webkit-transform: scale(0.6);
    -ms-transform: scale(0.6);
    transform: scale(0.6); }
  .modal.fade.fill-in {
    background-color: rgba(255, 255, 255, 0);
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s; }
    .modal.fade.fill-in > .close {
      opacity: 0.6;
      z-index: 9999; }
      .modal.fade.fill-in > .close > i {
        font-size: 16px; }
    .modal.fade.fill-in .modal-dialog {
      display: table;
      height: 100%; }
    .modal.fade.fill-in .modal-content {
      display: table-cell;
      vertical-align: middle;
      border: 0;
      background: transparent; }
      .modal.fade.fill-in .modal-content .modal-header {
        border-bottom: 0px; }
    .modal.fade.fill-in.show, .modal.fade.fill-in.in {
      background-color: rgba(255, 255, 255, 0.85); }
      .modal.fade.fill-in.show .modal-dialog, .modal.fade.fill-in.in .modal-dialog {
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1); }
    .modal.fade.fill-in .modal-dialog {
      opacity: 0;
      -webkit-transform: scale(0.6);
      -ms-transform: scale(0.6);
      transform: scale(0.6); }
  .modal.fade.slide-right .close, .modal.fade .slide-right .close {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 10px;
    z-index: 10; }
  .modal.fade.slide-right.show .modal-dialog, .modal.fade.slide-right.in .modal-dialog, .modal.fade .slide-right.show .modal-dialog, .modal.fade .slide-right.in .modal-dialog {
    right: 0; }
  .modal.fade.slide-right .modal-dialog, .modal.fade.slide-right.modal-dialog, .modal.fade .slide-right .modal-dialog, .modal.fade .slide-right.modal-dialog {
    position: absolute;
    right: -100%;
    margin: 0;
    height: 100%; }
    .modal.fade.slide-right .modal-dialog .modal-content-wrapper, .modal.fade.slide-right.modal-dialog .modal-content-wrapper, .modal.fade .slide-right .modal-dialog .modal-content-wrapper, .modal.fade .slide-right.modal-dialog .modal-content-wrapper {
      height: 100%; }
    .modal.fade.slide-right .modal-dialog .modal-content, .modal.fade.slide-right.modal-dialog .modal-content, .modal.fade .slide-right .modal-dialog .modal-content, .modal.fade .slide-right.modal-dialog .modal-content {
      height: 100%; }
    .modal.fade.slide-right .modal-dialog .modal-body, .modal.fade.slide-right.modal-dialog .modal-body, .modal.fade .slide-right .modal-dialog .modal-body, .modal.fade .slide-right.modal-dialog .modal-body {
      background-color: #fff; }
  .modal.fade.slide-right .modal-content, .modal.fade .slide-right .modal-content {
    border-radius: 0px; }
  .modal.fade.show .slide-right {
    left: auto;
    right: 0;
    transform: translate3d(0, 0, 0) !important; }

.fill-in-modal .modal-backdrop {
  background-color: transparent; }

.modal-backdrop {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear; }
  .modal-backdrop.show, .modal-backdrop.in {
    opacity: 0.30; }

/* Responsive Handlers: Modals
------------------------------------
*/
@media (min-width: 992px) {
  .modal-lg,
  .modal-lg > .modal-content {
    width: 900px;
    max-width: 100%; } }

@media (max-width: 768px) {
  .modal.fill-in .modal-dialog {
    width: calc(100% - 20px); }
  .modal.slide-up .modal-dialog {
    width: calc(100% - 20px); }
  .modal-lg, .modal-xl, .modal-md,
  .modal-lg > .modal-content,
  .modal-xl > .modal-content,
  .modal-md > .modal-content {
    width: 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .modal.stick-up .modal-dialog {
    margin: -5px auto; }
  .modal.slide-up .modal-dialog {
    margin: 0 auto; }
  .modal.fill-in .modal-dialog {
    margin: 0 auto; }
  .modal .modal-content {
    box-shadow: none; }
  .modal .modal-dialog {
    width: 600px; }
  .modal .modal-sm, .modal .modal-sm > .modal-content {
    width: 300px; }
  .modal .modal-sm, .modal .modal-sm > .modal-content {
    min-width: auto; }
  .modal .modal-md, .modal .modal-md > .modal-content {
    width: 750px;
    max-width: 100%; }
  .modal .modal-xl, .modal .modal-xl > .modal-content {
    width: 1100px;
    max-width: 100%; }
  .modal .modal-lg, .modal .modal-lg > .modal-content {
    width: 900px;
    max-width: 100%; } }

/* animate open editor
*/
@-webkit-keyframes openEditor {
  0% {
    right: -100%; }
  50% {
    right: -50%; }
  100% {
    right: 0; } }

.custom-modal .modal-content {
  padding: 10px !important; }

.nav .nav-item a.nav-link.nav-submenu:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 5px 5px 5px;
  border-top-color: #666;
  margin-top: 10px;
  margin-right: -5px; }

.nav .nav-item a.nav-link.nav-submenu.open:after {
  border-top: none;
  border-bottom-color: #666; }

.nav .nav-item a.nav-link.nav-submenu + .nav {
  margin-bottom: 0;
  border-bottom: 1px solid #ddd;
  display: none; }

.nav .nav-item a.nav-link.nav-submenu.open {
  border-bottom: none; }

.nav .nav-item a.nav-link.nav-submenu.open + .nav {
  display: block; }

.nav .nav-item a.nav-link.nav-submenu + .nav .nav-item {
  display: block;
  width: 100%; }

.nav .nav-item a.nav-link.nav-submenu + .nav .nav-item a.nav-link {
  padding-left: 62px;
  border-bottom: none;
  line-height: 12px; }

.list-group.list-no-border .list-group-item {
  border: none; }

.list-group.list-form {
  position: relative; }

.list-group.list-form .ui-checkbox {
  float: left;
  margin-right: 30px; }

.list-group.list-info .list-group-item {
  position: relative; }

.list-group.list-info .thumb {
  margin-right: 20px;
  width: 50px;
  vertical-align: top;
  margin-top: 5px; }

.list-group.list-info .list-content {
  display: inline-block; }

.list-group.list-info h3 {
  margin-bottom: 5px;
  margin-top: 0; }

.list-group.list-info .corner-info {
  position: absolute;
  right: 10px;
  top: 10px; }

.table {
  margin-bottom: 0; }

.table + .table {
  margin-top: 24px; }

.table thead > tr > th {
  color: #999999;
  font-weight: normal; }

.table tbody > tr.selected {
  background-color: #f5f5f5; }

.table.table-border {
  border: 1px solid #dddddd; }

.table.table-shadow {
  box-shadow: 0 1px 1px 0 rgba(155, 155, 155, 0.08), 0 1px 3px 1px rgba(155, 155, 155, 0.16); }

.ReactTable .rt-noData {
  background: transparent !important; }

.ReactTable .rt-table .rt-thead .rt-th {
  padding: 13px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold; }

.ReactTable .rt-tbody .rt-tr .rt-td {
  padding: 7px 10px;
  display: flex;
  align-items: center; }

.ReactTable .rt-tbody .rt-tr .rt-td input {
  margin-left: -2px; }

.ReactTable .rt-th.-cursor-pointer > div:after {
  content: 'unfold_more';
  font-size: 18px !important;
  top: 4px;
  position: relative; }

.ReactTable .rt-th.-cursor-pointer.-sort-asc {
  -webkit-box-shadow: inset 0 3px 0 0 #FF6666;
  box-shadow: inset 0 3px 0 0 #FF6666; }

.ReactTable .rt-th.-cursor-pointer.-sort-asc > div:after {
  content: 'keyboard_arrow_up'; }

.ReactTable .rt-th.-cursor-pointer.-sort-desc {
  -webkit-box-shadow: inset 0 -3px 0 0 #FF6666;
  box-shadow: inset 0 -3px 0 0 #FF6666; }

.ReactTable .rt-th.-cursor-pointer.-sort-desc > div:after {
  content: 'keyboard_arrow_down'; }

.ReactTable .-pagination .-btn {
  background: #2979FF;
  color: white; }

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: #5c99ff; }

.ReactTable .-pagination .-btn:disabled {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6); }

.card:first-child {
  margin-bottom: 0; }

.card.card-shadow {
  box-shadow: 0 1px 1px 0 rgba(155, 155, 155, 0.08), 0 1px 3px 1px rgba(155, 155, 155, 0.16); }

.card .card-header.card-header-toolbar {
  background-color: #eeeeee;
  border-bottom: 1px solid #dddddd;
  padding: 10px; }

.card .header-info h3 {
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 0; }

.card .header-info .secondary {
  font-size: 14px;
  color: #999999; }

.card .card-header {
  padding: 14px;
  background-color: #ffffff;
  border-bottom: none !important; }
  .card .card-header.w-thumb {
    padding: 14px; }
  .card .card-header.w-thumb img.thumb {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    margin-top: 5px;
    float: left; }
  .card .card-header.w-thumb .header-info h3 {
    margin: 0; }

.card .card-title {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: block;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  line-height: normal;
  padding: 16px;
  box-sizing: border-box; }

.card .card-media {
  align-items: center;
  display: flex; }
  .card .card-media .header-info {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    display: block;
    display: -webkit-flex;
    padding: 10px; }
  .card .card-media .header-info,
  .card .card-media .header-info h3 {
    color: #ffffff;
    font-weight: 400; }

.card .card-content,
.card .card-body {
  overflow: hidden;
  padding: 16px; }

.card > .card-content > .header-info {
  color: #000000;
  margin-bottom: 20px; }

.card .card-action {
  font-size: 16px;
  width: 100%;
  background-color: transparent;
  padding: 8px;
  text-align: right; }

.card .card-menu {
  position: absolute;
  right: 16px;
  top: 16px; }

.card .card-border {
  border-top: 1px solid #dddddd; }

.card {
  box-shadow: none;
  border-radius: 1px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  word-wrap: none; }
  .card .card-header {
    background: transparent;
    border-radius: 0px;
    padding: 20px 20px 15px 20px;
    position: relative;
    z-index: 3;
    min-height: 48px; }
    .card .card-header.separator:after {
      content: '';
      height: 1px;
      background: rgba(0, 0, 0, 0.08);
      left: 16px;
      right: 16px;
      position: absolute;
      bottom: 0; }
    .card .card-header .card-title {
      font-family: 'Montserrat';
      text-transform: uppercase;
      display: inline-block;
      letter-spacing: 0.06em;
      font-size: 12px;
      font-weight: 500;
      margin: 0;
      padding: 0;
      line-height: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-text-stroke: 0px;
      filter: alpha(opacity=40);
      -webkit-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease; }
    .card .card-header .card-controls {
      float: right;
      margin-top: -3px; }
      .card .card-header .card-controls ul {
        margin: 0;
        padding: 0; }
        .card .card-header .card-controls ul li {
          display: inline-block;
          list-style: none;
          line-height: 0; }
      .card .card-header .card-controls:after {
        content: '';
        display: table;
        clear: both; }
    .card .card-header.card-header-hover .card-title {
      opacity: 0.4; }
    .card .card-header.card-header-hover:hover .card-title {
      opacity: 1; }
  .card.card-default {
    border: 1px solid rgba(0, 0, 0, 0.15); }
    .card.card-default > .card-header {
      color: #333; }
  .card.card-bordered {
    border: 1px solid rgba(230, 230, 230, 0.7); }
  .card.card-borderless {
    border: none; }
  .card.card-condensed .card-header {
    padding: 13px 13px 0 13px;
    min-height: 30px; }
    .card.card-condensed .card-header .card-title {
      opacity: 0.4; }
  .card.card-condensed .card-body {
    padding: 13px; }
  .card.card-hover .card-header .card-title {
    opacity: 0.4; }
  .card.card-hover:hover .card-header .card-title {
    opacity: 1; }
  .card.card-transparent {
    background: transparent;
    border: none;
    box-shadow: none; }
    .card.card-transparent .card-body {
      background: transparent; }
  .card.full-height {
    height: 100%; }
    .card.full-height .card-body {
      height: auto;
      width: 100%;
      height: 100%; }
  .card.card-featured {
    box-shadow: -1px 1px 3px 0px rgba(121, 129, 135, 0.14);
    width: calc(100% - 50px);
    float: right; }
    .card.card-featured .card-title h4 {
      font-family: 'Montserrat';
      font-size: 16px;
      text-transform: uppercase;
      color: #f0f0f0; }
    .card.card-featured .card-body h3 {
      line-height: 34px;
      font-size: 26px; }
    .card.card-featured .footer .username {
      line-height: 8px;
      padding-top: 10px;
      font-size: 16px; }
    .card.card-featured .footer .buttons li {
      display: inline;
      list-style: none;
      font-weight: bold;
      margin-left: 20px; }
      .card.card-featured .footer .buttons li:first-child {
        margin-left: 0; }
    .card.card-featured .footer .buttons .heart {
      color: #f55753; }
    .card.card-featured .footer .buttons .comment {
      color: #626262; }
    .card.card-featured .ribbon {
      width: 38px;
      height: 38px;
      margin-left: -39px;
      float: left;
      box-shadow: inset -3px 0px 3px 0px rgba(0, 0, 0, 0.14); }
      .card.card-featured .ribbon.green {
        background: #48b0f7; }
      .card.card-featured .ribbon.blue {
        background: #10cfbd; }
  .card.hover-fill:hover {
    background: #f0f0f0; }
  .card.hover-stroke:hover {
    border: 1px solid #e6e6e6; }
  .card .card-body {
    padding: 20px; }
    .card .card-body.no-padding .row {
      margin-left: 0;
      margin-right: 0; }
    .card .card-body.no-bottom-padding {
      padding-bottom: 0; }
    .card .card-body.no-top-padding {
      padding-top: 0; }
    .card .card-body .title {
      margin-top: 0px; }
    .card .card-body.scrollable {
      margin-bottom: 20px; }

/* Portlets
------------------------------------
*/
.card-progress {
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  left: 0;
  position: absolute !important;
  right: 0;
  top: 0;
  display: none;
  z-index: 2; }

.card-progress .progress,
.card-progress .progress.progress-small {
  height: 3px; }

.card-progress .progress-circle-indeterminate,
.card-progress .card-bar-indeterminate {
  display: block;
  left: 50%;
  margin-left: -17px;
  margin-top: -17px;
  position: absolute;
  top: 50%; }

.card-progress .progress-circle-indeterminate {
  width: 35px;
  height: 35px; }

.card-maximized {
  position: fixed !important;
  left: 70px;
  top: 59px;
  bottom: 0;
  right: 0;
  z-index: 600;
  margin: 0;
  width: auto; }

/* Pages notification holder */
.card .pgn-wrapper {
  position: absolute;
  z-index: 602;
  left: 0 !important;
  top: 0 !important; }

.card-header a:not(.btn) {
  color: #626262 !important;
  opacity: 0.6;
  padding-top: 10px;
  padding-bottom: 10px; }
  .card-header a:not(.btn).card-refresh {
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease; }
    .card-header a:not(.btn).card-refresh.refreshing {
      opacity: 1; }
    .card-header a:not(.btn).card-refresh.minimal {
      position: relative;
      display: block;
      padding: 0; }

.card-header .dropdown-menu .dropdown-item {
  padding: 0 20px !important; }
  .card-header .dropdown-menu .dropdown-item:hover {
    opacity: 1;
    background-color: transparent; }

.card-header a[data-toggle]:hover {
  opacity: 1; }

.card-icon {
  -moz-osx-font-smoothing: grayscale;
  font-family: 'pages-icon';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none; }

.card-icon-close:before {
  content: '\e60a'; }

.card-icon-maximize:before {
  content: '\e634'; }

.card-icon-refresh:before {
  content: '\e600'; }

.card-icon-collapse:before {
  content: '\e62c'; }

.card-icon-settings:before {
  content: '\e655'; }

.card-icon-refresh-lg-master,
.card-icon-refresh-lg-white {
  width: 15px;
  height: 15px;
  display: block;
  background-size: cover;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }
  .card-icon-refresh-lg-master.fade,
  .card-icon-refresh-lg-white.fade {
    opacity: 0.1; }

.card-icon-refresh-lg-master {
  background-image: url("~assets/img/progress/progress-circle-lg-master-static.svg"); }

.card-icon-refresh-lg-white {
  background-image: url("~assets/img/progress/progress-circle-lg-white-static.svg"); }

.card-icon-refresh-lg-master-animated,
.card-icon-refresh-lg-white-animated {
  width: 15px;
  height: 15px;
  display: block;
  background-size: cover;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }
  .card-icon-refresh-lg-master-animated.active,
  .card-icon-refresh-lg-white-animated.active {
    opacity: 1; }

.card-icon-refresh-lg-master-animated {
  background-image: url("~assets/img/progress/progress-circle-lg-master.svg"); }

.card-icon-refresh-lg-white-animated {
  background-image: url("~assets/img/progress/progress-circle-lg-white.svg"); }

/* For demo purpose only */
.card-scroll {
  height: 100px; }

.sortable .card-header {
  cursor: move; }

/* To prevent lag while dragging */
.ui-sortable-handle {
  transition: max-height 0.3s ease 0s; }

.sortable .grid .grid-title {
  cursor: move; }

.ui-sortable {
  min-height: 0px !important; }

.ui-sortable-placeholder {
  border: 1px dotted black;
  visibility: visible !important;
  height: 100% !important; }
  .ui-sortable-placeholder * {
    visibility: hidden; }

.sortable-box-placeholder {
  background-color: #f0f0f0;
  border: 1px dashed #e6e6e6;
  display: block;
  margin-top: 0px !important;
  margin-bottom: 24px !important; }
  .sortable-box-placeholder * {
    visibility: hidden; }

.sortable .card {
  transition: none; }

.sortable-column {
  padding-bottom: 100px; }

.demo-card-scrollable {
  height: 158px; }

.card-body {
  padding: 15px; }

.resource-list > div {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  .resource-list > div .row {
    width: 100%; }

.resource-list .card {
  box-shadow: 0px 0px 7px 0px #eee;
  margin: 0 20px 20px 0 !important;
  height: 230px;
  width: 100%;
  padding: 1px; }
  .resource-list .card:hover {
    border: 1px solid rgba(255, 102, 102, 0.8);
    border-radius: 2px;
    cursor: pointer;
    transition: 0.18s ease-out; }
  .resource-list .card:before,
  .resource-list .card:hover .access {
    opacity: 1; }
  .resource-list .card:hover .access .btn,
  .resource-list .card:hover .mask,
  .resource-list .card:hover .access .access-link {
    transform: translateY(0px); }
  .resource-list .card:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.3);
    z-index: 0;
    transition: 0.5s;
    opacity: 0; }
  .resource-list .card .mask {
    background: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3; }
  .resource-list .card .access {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    opacity: 0; }
  .resource-list .card .access .access-link {
    position: absolute;
    width: 100%;
    top: 50%;
    text-align: center;
    margin-top: -20px;
    transform: translateY(30px);
    transition: 0.5s;
    z-index: 4; }

.resource-list .card-body .name {
  height: 45px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.resource-list .card-img {
  margin: 10px auto 0;
  height: 144px;
  width: 144px;
  overflow: hidden;
  float: left;
  position: relative;
  text-align: center; }
  .resource-list .card-img img {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    margin: 0;
    max-width: 144px !important;
    max-height: 144px; }
  .resource-list .card-img.card-img:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle; }

.resource-list :root {
  /* Not my favorite that line-height has to be united, but needed */
  --lh: 1rem; }

.resource-list .card-title, .resource-list .description {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 16px !important; }

.resource-list .resource-empty {
  margin: 50px;
  text-align: center;
  font-size: 20px;
  width: 100%; }

.resource-list .card-content {
  margin-top: 10px;
  border-top: 1px solid #EEE; }

.resource-list .description {
  font-size: 12px !important; }

.nav-tabs .nav-item a {
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  font-weight: 500; }

.nav-tabs .nav-item .nav-link:hover,
.nav-tabs .nav-item .nav-link.active {
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer; }

.nav-tabs.tab-md .nav-link,
.nav-tabs > li.tab-md > a {
  font-size: 12px;
  font-size: 0.75rem;
  padding: 8px 15px; }

.nav-tabs.tab-horizontal {
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  flex-wrap: nowrap; }

.nav-tabs > li:not(.nav-item) {
  border: none;
  margin-bottom: -1px; }

.nav-tabs > li > a {
  opacity: .5;
  color: rgba(0, 0, 0, 0.87); }

.nav-tabs > li.active {
  border-radius: 2px;
  border: 1px solid #dddddd;
  border-bottom-color: #ffffff;
  background-color: #ffffff; }
  .nav-tabs > li.active a {
    opacity: 1; }

.tab-pane.fade.in {
  opacity: 1; }

.nav-tabs + .tab-content {
  padding: 15px; }
  .nav-tabs + .tab-content.tab-border {
    border: 1px solid #dddddd;
    margin-top: -1px; }

/* Tabs
------------------------------------
*/
.nav-tabs {
  position: relative; }
  .nav-tabs > li {
    padding-left: 0;
    padding-right: 0; }
    .nav-tabs > li > a.active:hover, .nav-tabs > li > a.active:focus {
      border-color: #e6e6e6;
      color: #626262;
      background-color: #e6e6e6; }
    .nav-tabs > li > a {
      display: block;
      border-radius: 0;
      padding: 13px 20px;
      margin-right: 0;
      font-family: 'Montserrat';
      font-weight: 500;
      letter-spacing: 0.06em;
      color: fade(#626262, 70%);
      font-size: 14px;
      font-size: 0.875rem;
      min-width: 70px;
      text-transform: uppercase;
      border-color: transparent;
      position: relative;
      line-height: 1.7em; }
      .nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
        background: transparent;
        border-color: transparent;
        color: #626262; }
      .nav-tabs > li > a .tab-icon {
        margin-right: 6px; }
  .nav-tabs ~ .tab-content {
    overflow: hidden;
    padding: 15px; }
    .nav-tabs ~ .tab-content > .tab-pane.slide-left, .nav-tabs ~ .tab-content > .tab-pane.slide-right {
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      .nav-tabs ~ .tab-content > .tab-pane.slide-left.sliding, .nav-tabs ~ .tab-content > .tab-pane.slide-right.sliding {
        opacity: 0 !important; }
      .nav-tabs ~ .tab-content > .tab-pane.slide-left.active, .nav-tabs ~ .tab-content > .tab-pane.slide-right.active {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -ms-transform: translate(0, 0);
        opacity: 1; }
    .nav-tabs ~ .tab-content > .tab-pane.slide-left.sliding {
      -webkit-transform: translate3d(10%, 0, 0);
      transform: translate3d(10%, 0, 0);
      -ms-transform: translate(10%, 0); }
    .nav-tabs ~ .tab-content > .tab-pane.slide-right.sliding {
      -webkit-transform: translate3d(-10%, 0, 0);
      transform: translate3d(-10%, 0, 0);
      -ms-transform: translate(-10%, 0); }
  .nav-tabs.nav-tabs-left, .nav-tabs.nav-tabs-right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
    .nav-tabs.nav-tabs-left:after, .nav-tabs.nav-tabs-right:after {
      border-bottom: 0px; }
    .nav-tabs.nav-tabs-left > li, .nav-tabs.nav-tabs-right > li {
      float: none; }
  .nav-tabs.nav-tabs-left {
    float: left;
    padding-right: 0; }
    .nav-tabs.nav-tabs-left ~ .tab-content {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      -ms-flex: 1;
      -ms-flex-negative: 0; }
  .nav-tabs.nav-tabs-right {
    float: right;
    padding-right: 0; }
    .nav-tabs.nav-tabs-right ~ .tab-content {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      -ms-flex: 1;
      -ms-flex-negative: 0; }

/* Tabs : Simple
------------------------------------
*/
.nav-tabs-simple {
  border-bottom: 0px; }
  .nav-tabs-simple:after {
    content: '';
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    position: absolute;
    bottom: -2px;
    z-index: 120;
    left: 0; }
  .nav-tabs-simple > li {
    margin-bottom: 0; }
    .nav-tabs-simple > li > a {
      border: none !important;
      opacity: .5; }
      .nav-tabs-simple > li > a:after {
        position: absolute;
        -webkit-transition: all 0.1s linear 0s;
        transition: all 0.1s linear 0s;
        -webkit-backface-visibility: hidden;
        width: 100%;
        display: block;
        background-color: #f55753;
        height: 0px;
        content: '';
        left: 0;
        z-index: 125;
        top: 100%; }
        .nav-tabs-simple > li > a:after.nav-tabs-primary {
          background-color: #FF6666; }
        .nav-tabs-simple > li > a:after.nav-tabs-success {
          background-color: #10cfbd; }
        .nav-tabs-simple > li > a:after.nav-tabs-complete {
          background-color: #48b0f7; }
        .nav-tabs-simple > li > a:after.nav-tabs-danger {
          background-color: #f55753; }
        .nav-tabs-simple > li > a:after.nav-tabs-warning {
          background-color: #f8d053; }
        .nav-tabs-simple > li > a:after.nav-tabs-info {
          background-color: #2979FF; }
      .nav-tabs-simple > li > a.active {
        opacity: 1; }
        .nav-tabs-simple > li > a.active:hover, .nav-tabs-simple > li > a.active:focus {
          background-color: transparent;
          border-color: transparent; }
      .nav-tabs-simple > li > a.active:after, .nav-tabs-simple > li > a:hover:after {
        height: 3px; }
  .nav-tabs-simple.nav-tabs-left:after, .nav-tabs-simple.nav-tabs-right:after {
    border-bottom: 0px; }
  .nav-tabs-simple.nav-tabs-left > li > a:after, .nav-tabs-simple.nav-tabs-right > li > a:after {
    width: 0px;
    height: 100%;
    top: 0;
    bottom: 0;
    position: absolute; }
  .nav-tabs-simple.nav-tabs-left > li > a.active:after, .nav-tabs-simple.nav-tabs-left > li > a:hover:after, .nav-tabs-simple.nav-tabs-right > li > a.active:after, .nav-tabs-simple.nav-tabs-right > li > a:hover:after {
    width: 3px; }
  .nav-tabs-simple.nav-tabs-left > li > a:after {
    right: 0;
    left: auto; }
  .nav-tabs-simple.nav-tabs-right > li > a:after {
    left: 0; }

/* Tabs : Line Triangles
------------------------------------
*/
.nav-tabs-linetriangle {
  border-bottom: 0; }
  .nav-tabs-linetriangle:after {
    content: '';
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    position: absolute;
    bottom: -1px;
    z-index: 100;
    left: 0; }
  .nav-tabs-linetriangle > li > a.active {
    background: transparent;
    box-shadow: none;
    border-color: transparent; }
    .nav-tabs-linetriangle > li > a.active:hover, .nav-tabs-linetriangle > li > a.active:focus, .nav-tabs-linetriangle > li > a.active:active {
      border-color: transparent;
      background-color: transparent; }
    .nav-tabs-linetriangle > li > a.active:after, .nav-tabs-linetriangle > li > a.active:before {
      border: medium solid transparent;
      content: "";
      height: 0;
      left: 50%;
      pointer-events: none;
      position: absolute;
      width: 0;
      z-index: 120;
      top: 98%; }
    .nav-tabs-linetriangle > li > a.active:after {
      border-top-color: #fafafa;
      border-width: 10px;
      margin-left: -10px; }
    .nav-tabs-linetriangle > li > a.active:before {
      border-top-color: rgba(0, 0, 0, 0.2);
      border-width: 11px;
      margin-left: -11px; }
  .nav-tabs-linetriangle > li > a span {
    font-size: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .nav-tabs-linetriangle > li > a:hover, .nav-tabs-linetriangle > li > a:focus {
    overflow: visible;
    -webkit-transition: color 0.2s ease 0s;
    transition: color 0.2s ease 0s; }
  .nav-tabs-linetriangle ~ .tab-content {
    background: #fff; }
  .nav-tabs-linetriangle.nav-tabs-left.nav-tabs:after {
    border-bottom: none; }
  .nav-tabs-linetriangle.nav-tabs-left.nav-tabs > li > a.active:before {
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-left: 11px solid rgba(0, 0, 0, 0.2); }
  .nav-tabs-linetriangle.nav-tabs-left.nav-tabs > li > a.active:after {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #fafafa;
    margin-top: 1px;
    margin-right: 2px; }
  .nav-tabs-linetriangle.nav-tabs-left.nav-tabs > li > a.active:after, .nav-tabs-linetriangle.nav-tabs-left.nav-tabs > li > a.active:before {
    top: auto;
    right: -22px;
    left: auto; }
  .nav-tabs-linetriangle.nav-tabs-right.nav-tabs:after {
    border-bottom: none; }
  .nav-tabs-linetriangle.nav-tabs-right.nav-tabs > li > a.active:before {
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-right: 11px solid rgba(0, 0, 0, 0.2); }
  .nav-tabs-linetriangle.nav-tabs-right.nav-tabs > li > a.active:after {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #fafafa;
    margin-top: 1px;
    margin-left: -9px; }
  .nav-tabs-linetriangle.nav-tabs-right.nav-tabs > li > a.active:after, .nav-tabs-linetriangle.nav-tabs-right.nav-tabs > li > a.active:before {
    top: auto;
    left: -11px;
    right: auto; }

@media screen and (max-width: 58em) {
  .nav-tabs-linetriangle {
    font-size: 0.6em; } }

@media screen and (max-width: 991px) {
  .nav-tabs.reponsive-tab {
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    flex-wrap: nowrap; }
  .nav-tabs.nav-tabs-simple.reponsive-tab {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .nav-tabs.reponsive-tab .nav-item > a.active {
    border-bottom: 3px solid #FF6666 !important; } }

/* Tabs : Fill-up
------------------------------------
*/
.nav-tabs-fillup {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  perspective: 1000; }
  .nav-tabs-fillup > li {
    overflow: hidden; }
    .nav-tabs-fillup > li > a {
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transition: color 0.3s ease 0s;
      transition: color 0.3s ease 0s;
      background: transparent; }
      .nav-tabs-fillup > li > a:after {
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        background: none repeat scroll 0 0 #10cfbd;
        border: 1px solid #10cfbd;
        content: "";
        height: calc(100% + 1px);
        left: 0;
        position: absolute;
        top: 0;
        -webkit-transform: translate3d(0, 100%, 0px);
        transform: translate3d(0, 100%, 0px);
        -webkit-transition: -webkit-transform 0.3s ease 0s;
        transition: transform 0.3s ease 0s;
        width: 100%;
        z-index: -1; }
        .nav-tabs-fillup > li > a:after.nav-tabs-primary {
          background: none repeat scroll 0 0 #FF6666;
          border: 1px solid #FF6666; }
        .nav-tabs-fillup > li > a:after.nav-tabs-success {
          background: none repeat scroll 0 0 #10cfbd;
          border: 1px solid #10cfbd; }
        .nav-tabs-fillup > li > a:after.nav-tabs-complete {
          background: none repeat scroll 0 0 #48b0f7;
          border: 1px solid #48b0f7; }
        .nav-tabs-fillup > li > a:after.nav-tabs-warning {
          background: none repeat scroll 0 0 #f8d053;
          border: 1px solid #f8d053; }
        .nav-tabs-fillup > li > a:after.nav-tabs-danger {
          background: none repeat scroll 0 0 #f55753;
          border: 1px solid #f55753; }
        .nav-tabs-fillup > li > a:after.nav-tabs-info {
          background: none repeat scroll 0 0 #2979FF;
          border: 1px solid #2979FF; }
      .nav-tabs-fillup > li > a span {
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translate3d(0px, 5px, 0px);
        transform: translate3d(0px, 5px, 0px);
        -webkit-transition: -webkit-transform 0.5s ease 0s;
        transition: transform 0.5s ease 0s;
        display: block;
        font-weight: 700;
        line-height: 1.5; }
      .nav-tabs-fillup > li > a.active {
        z-index: 100;
        color: #fff; }
        .nav-tabs-fillup > li > a.active:hover, .nav-tabs-fillup > li > a.active:focus {
          border-color: transparent;
          background: transparent;
          color: #fff; }
        .nav-tabs-fillup > li > a.active:after {
          -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
          -ms-transform: translate(0px, 0px); }
        .nav-tabs-fillup > li > a.active span {
          -webkit-transform: translate3d(0px, -5px, 0px);
          transform: translate3d(0px, -5px, 0px);
          -ms-transform: translate(0px, -5px); }
  .nav-tabs-fillup .icon:before {
    -webkit-transform: translate3d(0px, 5px, 0px);
    transform: translate3d(0px, 5px, 0px);
    -ms-transform: translate(0px, 5px);
    -webkit-transition: transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s;
    display: block;
    margin: 0; }
  .nav-tabs-fillup ~ .tab-content {
    background: #fff; }
  .nav-tabs-fillup.nav-tabs-left, .nav-tabs-fillup.nav-tabs-right {
    border-bottom: none; }
    .nav-tabs-fillup.nav-tabs-left:after, .nav-tabs-fillup.nav-tabs-right:after {
      border-bottom: none; }
    .nav-tabs-fillup.nav-tabs-left > li > a:after, .nav-tabs-fillup.nav-tabs-right > li > a:after {
      width: calc(100% + 1px);
      height: 100%; }
    .nav-tabs-fillup.nav-tabs-left > li.active a:after, .nav-tabs-fillup.nav-tabs-right > li.active a:after {
      -webkit-transform: translate3d(0px, 0px, 0px);
      transform: translate3d(0px, 0px, 0px);
      -ms-transform: translate(0px, 0px); }
  .nav-tabs-fillup.nav-tabs-left > li > a:after {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    -ms-transform: translate(100%, 0); }
  .nav-tabs-fillup.nav-tabs-left > li > a span {
    -webkit-transform: translate3d(5px, 0, 0px);
    transform: translate3d(5px, 0, 0px);
    -ms-transform: translate(5px, 0); }
  .nav-tabs-fillup.nav-tabs-left > li.active a span {
    -webkit-transform: translate3d(-5px, 0, 0px);
    transform: translate3d(-5px, 0, 0px);
    -ms-transform: translate(-5px, 0); }
  .nav-tabs-fillup.nav-tabs-left > li.active .icon:before {
    -webkit-transform: translate3d(-5px, 0, 0px);
    transform: translate3d(-5px, 0, 0px);
    -ms-transform: translate(-5px, 0); }
  .nav-tabs-fillup.nav-tabs-right > li > a:after {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    -ms-transform: translate(-100%, 0);
    left: -1px; }
  .nav-tabs-fillup.nav-tabs-right > li > a span {
    -webkit-transform: translate3d(-5px, 0, 0px);
    transform: translate3d(-5px, 0, 0px);
    -ms-transform: translate(-5px, 0); }
  .nav-tabs-fillup.nav-tabs-right > li.active a span {
    -webkit-transform: translate3d(5px, 0, 0px);
    transform: translate3d(5px, 0, 0px);
    -ms-transform: translate(5px, 0); }
  .nav-tabs-fillup.nav-tabs-right > li.active .icon:before {
    -webkit-transform: translate3d(5px, 0, 0px);
    transform: translate3d(5px, 0, 0px);
    -ms-transform: translate(5px, 0); }

.nav-tabs-header {
  overflow: scroll;
  width: 100%; }
  .nav-tabs-header .nav-tabs {
    width: auto;
    white-space: nowrap; }
    .nav-tabs-header .nav-tabs > li {
      display: inline-block;
      float: inherit; }
  .nav-tabs-header.nav-tabs-linetriangle {
    height: 54px;
    overflow-y: hidden; }
    .nav-tabs-header.nav-tabs-linetriangle:after {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      width: 100%;
      position: relative;
      bottom: 3px;
      z-index: 120;
      content: "";
      clear: both; }
    .nav-tabs-header.nav-tabs-linetriangle ~ .tab-content {
      position: relative;
      top: -12px; }

.nav-tabs-header ~ .tab-content {
  overflow: hidden;
  padding: 15px; }

.nav-tab-dropdown .cs-select {
  width: 100% !important;
  max-width: 100%; }
  .nav-tab-dropdown .cs-select .cs-placeholder {
    height: 48px;
    padding: 12px 34px 15px 15px;
    font-family: 'Montserrat';
    font-weight: 500;
    letter-spacing: 0.06em;
    font-size: 10.5px;
    text-transform: uppercase; }
  .nav-tab-dropdown .cs-select .cs-backdrop {
    border: 1px solid transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }

/* Panel Groups
------------------------------------
*/
.card-group {
  margin-bottom: 18px; }
  .card-group.horizontal {
    display: block; }
    .card-group.horizontal .card + .card {
      border-left: 1px solid rgba(0, 0, 0, 0.15); }
  .card-group .card-header {
    padding: 13px 18px 10px 22px; }
    .card-group .card-header.collapsed {
      background-color: #fff; }
    .card-group .card-header + .card-collapse .card-body {
      border: 0; }
    .card-group .card-header .card-title {
      width: 100%; }
      .card-group .card-header .card-title > a {
        color: #626262;
        font-size: 13px;
        font-weight: normal;
        display: block;
        opacity: 1; }
        .card-group .card-header .card-title > a:hover {
          color: #626262 !important; }
          .card-group .card-header .card-title > a:hover:after {
            color: #626262 !important; }
        .card-group .card-header .card-title > a:after {
          font-family: 'FontAwesome';
          content: "\f056";
          position: absolute;
          right: 13px;
          top: 36%;
          color: #626262; }
        .card-group .card-header .card-title > a.collapsed {
          color: fade(#626262, 70%);
          opacity: 1; }
          .card-group .card-header .card-title > a.collapsed:after {
            content: "\f055";
            color: fade(#626262, 70%); }
  .card-group .card {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0;
    flex: 1 0 0; }
    .card-group .card + .card {
      margin-top: 2px; }
    .card-group .card .card-body {
      height: auto; }

.nav-pills > li:first-child {
  padding-left: 0; }

.nav-pills > li > a {
  color: #626262; }

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: #626262;
  background-color: #e6e6e6; }

@media (max-width: 767px) {
  .nav.nav-tabs.nav-stack-sm li {
    float: none; }
  .nav.nav-tabs.nav-stack-sm.nav-tabs-linetriangle > li.active > a:after,
  .nav.nav-tabs.nav-stack-sm.nav-tabs-linetriangle > li.active > a:before {
    display: none; } }

.toast-title {
  font-weight: bold; }

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  display: inline; }

.toast-message a, .toast-message label {
  color: #ffffff; }

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none; }

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80); }

.toast-close-button:hover, .toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40); }

/*Additional properties for button version
iOS requires the button element instead of an anchor tag.
If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%; }

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%; }

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%; }

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%; }

.toast-top-left {
  top: 12px;
  left: 12px; }

.toast-top-right {
  top: 63px;
  right: 12px; }

.toast-bottom-right {
  right: 12px;
  bottom: 12px; }

.toast-bottom-left {
  bottom: 12px;
  left: 12px; }

#toast-container {
  position: fixed;
  z-index: 999999;
  /*overrides*/ }

#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

#toast-container > div {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px;
  width: 300px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999999;
  -webkit-box-shadow: 0 0 12px #999999;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80); }

#toast-container > div:hover {
  -moz-box-shadow: 0 0 12px #000000;
  -webkit-box-shadow: 0 0 12px #000000;
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer; }

#toast-container > .toast:before {
  display: inline;
  vertical-align: middle;
  margin-right: 5px; }

#toast-container > .toast-info:before {
  content: "info"; }

#toast-container > .toast-error:before {
  content: "error_outline"; }

#toast-container > .toast-success:before {
  content: "check"; }

#toast-container > .toast-warning:before {
  content: "warning"; }

#toast-container.toast-top-center > div, #toast-container.toast-bottom-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto; }

#toast-container.toast-top-full-width > div, #toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto; }

.toast {
  background-color: #030303; }

.toast-success {
  background-color: #28a745; }

.toast-error {
  background-color: #f44336; }

.toast-info {
  background-color: #2196f3; }

.toast-warning {
  background-color: #FF6666; }

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40); }

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em; }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em; } }

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em; }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em; } }

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em; } }

.placeholderload {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative; }
  .placeholderload .bg-masker {
    background: #fff;
    position: absolute; }

/* post type placeholderload
*/
.post-placeholderload {
  height: 96px;
  /* Every thing below this is just positioning */ }
  .post-placeholderload .bg-masker.header-top, .post-placeholderload .bg-masker.header-bottom, .post-placeholderload .bg-masker.subheader-bottom {
    top: 0;
    left: 40px;
    right: 0;
    height: 10px; }
  .post-placeholderload .bg-masker.header-left, .post-placeholderload .bg-masker.subheader-left, .post-placeholderload .bg-masker.header-right, .post-placeholderload .bg-masker.subheader-right {
    top: 10px;
    left: 40px;
    height: 8px;
    width: 10px; }
  .post-placeholderload .bg-masker.header-bottom {
    top: 18px;
    height: 6px; }
  .post-placeholderload .bg-masker.subheader-left, .post-placeholderload .bg-masker.subheader-right {
    top: 24px;
    height: 6px; }
  .post-placeholderload .bg-masker.header-right, .post-placeholderload .bg-masker.subheader-right {
    width: auto;
    left: 300px;
    right: 0; }
  .post-placeholderload .bg-masker.subheader-right {
    left: 230px; }
  .post-placeholderload .bg-masker.subheader-bottom {
    top: 30px;
    height: 10px; }
  .post-placeholderload .bg-masker.content-top, .post-placeholderload .bg-masker.content-second-line, .post-placeholderload .bg-masker.content-third-line, .post-placeholderload .bg-masker.content-second-end, .post-placeholderload .bg-masker.content-third-end, .post-placeholderload .bg-masker.content-first-end {
    top: 40px;
    left: 0;
    right: 0;
    height: 6px; }
  .post-placeholderload .bg-masker.content-top {
    height: 20px; }
  .post-placeholderload .bg-masker.content-first-end, .post-placeholderload .bg-masker.content-second-end, .post-placeholderload .bg-masker.content-third-end {
    width: auto;
    left: 380px;
    right: 0;
    top: 60px;
    height: 8px; }
  .post-placeholderload .bg-masker.content-second-line {
    top: 68px; }
  .post-placeholderload .bg-masker.content-second-end {
    left: 420px;
    top: 74px; }
  .post-placeholderload .bg-masker.content-third-line {
    top: 82px; }
  .post-placeholderload .bg-masker.content-third-end {
    left: 300px;
    top: 88px; }

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.popover {
  z-index: 790; }
  .popover .popover-header {
    font-size: 14px;
    padding: 10px;
    margin: 0; }
  .popover .popover-body {
    font-size: 12px;
    padding: 10px; }
  .popover.popover-info {
    z-index: 99999;
    min-width: 300px; }
    .popover.popover-info h3.popover-header {
      font-size: 16px; }
    .popover.popover-info .popover-body {
      min-height: 60px;
      font-size: 14px; }

/*! ========================================================================
 * Bootstrap Toggle: bootstrap-toggle.css v2.2.0
 * http://www.bootstraptoggle.com
 * ========================================================================
 * Copyright 2014 Min Hur, The New York Times Company
 * Licensed under MIT
 * ======================================================================== */
.checkbox label .toggle,
.checkbox-inline .toggle {
  margin-left: -20px;
  margin-right: 5px; }

.toggle {
  position: relative;
  border-radius: 15px;
  border: none;
  min-width: 30px !important;
  width: 30px !important; }
  .toggle[disabled="disabled"] {
    opacity: .7; }
  .toggle[disabled="disabled"], .toggle[disabled="disabled"].btn,
  .toggle[disabled="disabled"] .btn {
    cursor: not-allowed !important; }
  .toggle.off.btn {
    background: rgba(0, 0, 0, 0.26) !important;
    background-color: rgba(0, 0, 0, 0.26) !important; }
  .toggle .wrapper {
    width: 100%;
    height: 14px;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    border-radius: 15px; }

.toggle input[type="checkbox"] {
  display: none; }

.toggle-group {
  position: absolute;
  width: 200%;
  top: 0;
  bottom: 0;
  left: 0;
  transition: left 0.35s;
  -webkit-transition: left 0.35s;
  -moz-user-select: none;
  -webkit-user-select: none; }

.toggle.off .toggle-group {
  left: -100%; }

.toggle-handle {
  position: absolute;
  top: -3px;
  right: -5px;
  left: auto;
  margin: 0 auto;
  padding: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #FF6666;
  background-color: #FF6666;
  box-shadow: 0px 1px 1px #ccc; }

.toggle.off .toggle-handle {
  right: auto;
  left: -5px;
  border-color: #dddddd;
  background-color: #ffffff; }

.toggle-on {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: #f99e81 !important; }

.toggle-off {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.26); }

.toggle.btn {
  min-width: 59px;
  min-height: 14px;
  height: 14px !important; }

.toggle-on.btn {
  padding-right: 24px; }

.toggle-off.btn {
  padding-left: 24px; }

.toggle.btn-lg .wrapper {
  height: 19px; }

.toggle.btn-lg .toggle-handle {
  top: -6px; }

.toggle.btn-lg {
  min-width: 40px !important;
  min-height: 19px !important; }

.toggle-on.btn-lg {
  padding-right: 31px; }

.toggle-off.btn-lg {
  padding-left: 31px; }

.toggle-handle.btn-lg {
  width: 30px;
  height: 30px; }

.toggle.btn-sm {
  min-width: 50px;
  min-height: 30px; }

.toggle-on.btn-sm {
  padding-right: 20px; }

.toggle-off.btn-sm {
  padding-left: 20px; }

.toggle.btn-xs {
  min-width: 35px;
  min-height: 22px; }

.toggle-on.btn-xs {
  padding-right: 12px; }

.toggle-off.btn-xs {
  padding-left: 12px; }

.dropdown-submenu {
  position: relative; }

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
  border-radius: 2px; }

.dropdown-submenu:hover > ul.dropdown-menu,
.dropdown-submenu:hover > .dropdown-menu {
  display: block; }

.dropdown-menu > .dropdown-item {
  border-radius: 0;
  cursor: pointer; }

a.dropdown-item.active {
  color: #ffffff; }

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #666666;
  margin-top: 7px;
  margin-right: -5px; }

.dropdown-submenu.pull-left {
  float: none; }

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  border-radius: 2px; }

.dropdown-item {
  padding: 5px 15px;
  border-bottom: 1px solid #dddddd; }
  .dropdown-item:last-child {
    border-bottom: none; }
  .dropdown-item:hover {
    background-color: #ffffff !important; }

.dropdown-item a {
  color: rgba(0, 0, 0, 0.87);
  display: block; }

.dropdown-menu {
  padding: 0;
  font-size: 14px;
  border-radius: 2px;
  min-width: 220px; }

.dropdown-menu.md {
  min-width: 180px; }

/*------------------------------------------------------------------
[8. Notifications]
*/
/* Badges
--------------------------------------------------
*/
.badge {
  text-shadow: none;
  font-family: wf_segoe-ui_light, wf_segoe-ui_normal, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  background-color: #e6e6e6;
  font-size: 11px;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 4px;
  color: #626262;
  border-radius: 10px; }

.badge-success {
  background-color: #10cfbd;
  color: #fff; }

.badge-warning {
  background-color: #f8d053;
  color: #fff; }

.badge-important {
  background-color: #f55753;
  color: #fff; }

.badge-danger {
  background-color: #f55753;
  color: #fff; }

.badge-info {
  background-color: #2979FF;
  color: #fff; }

.badge-inverse {
  background-color: #2b6a94;
  color: #fff; }

.badge-white {
  background-color: #fff;
  color: #626262; }

.badge-disable {
  background-color: #2c2c2c;
  color: #626262; }

.badge-complete {
  background-color: #48b0f7;
  color: #fff; }

/* Tool Tip
--------------------------------------------------
*/
.tooltip-inner {
  font-size: 12px; }

/* Notification popup
--------------------------------------------------
*/
.popover {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36); }

.notification-toggle {
  top: 35px;
  left: -26px;
  padding: 0; }
  .notification-toggle:before {
    border-bottom: 0px !important; }
  .notification-toggle:after {
    border-bottom: 0px !important; }

.notification-panel {
  background-color: #fff;
  border: 1px solid #e6e6e6; }
  .notification-panel .notification-body {
    height: auto;
    max-height: 350px;
    position: relative;
    overflow: hidden; }
    .notification-panel .notification-body .notification-item {
      position: relative;
      margin-left: 25px;
      background-color: #fff;
      padding-right: 26px; }
      .notification-panel .notification-body .notification-item.unread .heading {
        opacity: 1; }
      .notification-panel .notification-body .notification-item.unread .option {
        background-color: #daeffd; }
      .notification-panel .notification-body .notification-item.unread .option .mark {
        color: #6dc0f9; }
      .notification-panel .notification-body .notification-item .heading {
        line-height: 43px;
        margin-right: 5px;
        background: #fff;
        border-bottom: 1px solid #e6e6e6;
        position: relative;
        float: left;
        width: 100%;
        font-size: 14px;
        opacity: 0.7; }
        .notification-panel .notification-body .notification-item .heading:after {
          content: '';
          position: absolute;
          width: 5px;
          height: 100%;
          background-color: #fff;
          right: 0;
          top: 1px; }
        .notification-panel .notification-body .notification-item .heading .thumbnail-wrapper {
          cursor: pointer; }
          .notification-panel .notification-body .notification-item .heading .thumbnail-wrapper i {
            -webkit-transition: all 0.12s linear;
            transition: all 0.12s linear; }
        .notification-panel .notification-body .notification-item .heading .time {
          font-size: 11px;
          margin-right: 8px; }
        .notification-panel .notification-body .notification-item .heading.open {
          display: block; }
          .notification-panel .notification-body .notification-item .heading.open .more-details {
            display: block; }
          .notification-panel .notification-body .notification-item .heading.open .thumbnail-wrapper i {
            -webkit-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            transform: rotate(-90deg); }
        .notification-panel .notification-body .notification-item .heading .thumbnail-wrapper.d24 {
          line-height: 20px; }
      .notification-panel .notification-body .notification-item .more-details {
        display: none;
        background-color: #fff;
        width: 100%;
        height: 100%;
        clear: both;
        position: relative; }
        .notification-panel .notification-body .notification-item .more-details .more-details-inner {
          margin-left: 10px;
          padding-left: 28px;
          padding-top: 15px;
          margin-bottom: 20px;
          position: relative;
          border-left: 1px solid fade(#e6e6e6, 70%);
          text-align: left; }
          .notification-panel .notification-body .notification-item .more-details .more-details-inner:after {
            width: 14px;
            height: 14px;
            position: absolute;
            content: '';
            background-color: #fff;
            left: -7px;
            top: 25px;
            border: 1px solid fade(#e6e6e6, 90%);
            border-radius: 99px;
            -webkit-border-radius: 99px;
            -moz-border-radius: 99px; }
          .notification-panel .notification-body .notification-item .more-details .more-details-inner:before {
            color: silver;
            position: absolute;
            bottom: 0;
            left: -5px;
            content: ' \25CF';
            font-size: 13px;
            line-height: 5px;
            background-color: #fff;
            height: 9px; }
          .notification-panel .notification-body .notification-item .more-details .more-details-inner .hint-text {
            opacity: .44; }
      .notification-panel .notification-body .notification-item .option {
        font-size: 10px;
        position: absolute;
        height: 44px;
        width: 26px;
        right: 0;
        text-align: center;
        vertical-align: middle;
        line-height: 44px;
        background-color: #fafafa;
        height: 100%; }
        .notification-panel .notification-body .notification-item .option .mark {
          background-color: transparent;
          color: silver; }
          .notification-panel .notification-body .notification-item .option .mark:before {
            content: ' \25CF';
            font-size: 12px; }
      .notification-panel .notification-body .notification-item:last-child .heading {
        border-bottom: 0px; }
  .notification-panel .notification-footer {
    padding: 10px;
    display: block;
    border-top: 1px solid #e6e6e6; }
    .notification-panel .notification-footer a {
      color: #626262;
      opacity: .54; }
      .notification-panel .notification-footer a:hover {
        opacity: .74; }

/* Simple alerts
--------------------------------------------------
*/
.alerts-container {
  position: fixed;
  width: 350px;
  right: 20px;
  z-index: 999;
  top: 80px; }
  .alerts-container[data-placement$='-left'] {
    left: 100px;
    right: auto; }
  .alerts-container[data-placement$='-right'] {
    right: 20px;
    left: auto; }
  .alerts-container[data-placement^='top-'] {
    top: 80px;
    bottom: auto; }
  .alerts-container[data-placement^='bottom-'] {
    top: auto;
    bottom: 20px; }
  .alerts-container[data-placement='bottom-right'] .alert:last-child, .alerts-container[data-placement='bottom-left'] .alert:last-child {
    margin-bottom: 0; }
  .alerts-container .alert {
    position: relative; }
    .alerts-container .alert .close {
      position: absolute;
      right: 9px;
      top: 15px; }

/* Pages Notifications plugin
--------------------------------------------------
*/
/* Pages Notification customizations */
body:not(.horizontal-app-menu) .pgn-wrapper[data-position="top"] {
  top: 60px; }

body:not(.horizontal-app-menu) .pgn-wrapper[data-position$='-left'] {
  right: auto; }

body:not(.horizontal-app-menu) .pgn-wrapper[data-position^='top-'] {
  top: 80px;
  bottom: auto; }

body.menu-pin .pgn-wrapper[data-position$='-left'],
body.menu-pin .pgn-wrapper[data-position="top"],
body.menu-pin .pgn-wrapper[data-position="bottom"] {
  left: 250px; }

body.horizontal-app-menu .pgn-wrapper {
  z-index: 799; }

.pgn-wrapper {
  position: fixed;
  z-index: 1000; }

.pgn-wrapper[data-position$='-left'] {
  left: 30px; }

.pgn-wrapper[data-position$='-right'] {
  right: 20px; }

.pgn-wrapper[data-position^='top-'] {
  top: 20px; }

.pgn-wrapper[data-position^='bottom-'] {
  bottom: 20px; }

.pgn-wrapper[data-position='top'] {
  top: 0;
  left: 0;
  right: 0; }

.pgn-wrapper[data-position='bottom'] {
  bottom: 0;
  left: 0;
  right: 0; }

.pgn {
  position: relative;
  margin: 10px; }

.pgn .alert {
  margin: 0; }

/* Simple
------------------------------------
*/
.pgn-simple .alert {
  padding-top: 13px;
  padding-bottom: 13px;
  max-width: 500px;
  animation: fadeIn 0.3s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  -webkit-animation: fadeIn 0.3s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  max-height: 250px;
  overflow: hidden; }

/* Bar
------------------------------------
*/
.pgn-bar {
  overflow: hidden;
  margin: 0; }

.pgn-bar .alert {
  border-radius: 0;
  padding-top: 13px;
  padding-bottom: 13px;
  max-height: 91px; }

.pgn-wrapper[data-position='top'] .pgn-bar .alert {
  animation: slideInFromTop 0.5s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  -webkit-animation: slideInFromTop 0.5s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  transform-origin: top left;
  -webkit-transform-origin: top left; }

.pgn-wrapper[data-position='bottom'] .pgn-bar .alert {
  animation: slideInFromBottom 0.5s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  -webkit-animation: slideInFromBottom 0.5s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  transform-origin: bottom left;
  -webkit-transform-origin: bottom left; }

.pgn-bar .alert span {
  opacity: 0;
  animation: fadeIn 0.3s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  -webkit-animation: fadeIn 0.3s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards; }

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0); } }

@-webkit-keyframes slideInFromTop {
  0% {
    -webkit-transform: translateY(-100%); }
  100% {
    -webkit-transform: translateY(0); } }

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@-webkit-keyframes slideInFromBottom {
  0% {
    -webkit-transform: translateY(100%); }
  100% {
    -webkit-transform: translateY(0); } }

/* Circle
------------------------------------
*/
.pgn-circle .alert {
  margin-bottom: 10px;
  border-radius: 300px;
  animation: fadeInCircle 0.3s ease forwards, resizeCircle 0.3s 0.4s cubic-bezier(0.25, 0.25, 0.4, 1.6) forwards;
  -webkit-animation: fadeInCircle 0.3s ease forwards, resizeCircle 0.3s 0.4s cubic-bezier(0.25, 0.25, 0.4, 1.6) forwards;
  height: 60px;
  overflow: hidden;
  padding: 6px 55px 6px 6px;
  -webkit-transform: translateZ(0);
  position: relative; }

.pgn-wrapper[data-position$='-right'] .pgn-circle .alert {
  float: right; }

.pgn-wrapper[data-position$='-left'] .pgn-circle .alert {
  float: left; }

.pgn-wrapper[data-position^='bottom-'] .pgn-circle .alert {
  margin-bottom: 20px; }

.pgn-circle .alert > div > div.pgn-thumbnail > div {
  border-radius: 50%;
  overflow: hidden;
  width: 48px;
  height: 48px; }

.pgn-circle .alert > div > div.pgn-thumbnail > div > img {
  width: 100%;
  height: 100%; }

.pgn-circle .alert > div > div.pgn-message > div {
  opacity: 0;
  height: 47px;
  padding-left: 9px;
  animation: fadeIn .3s .5s ease forwards;
  -webkit-animation: fadeIn .3s .5s ease forwards;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  word-wrap: break-word; }

.pgn-circle .alert > div > div.pgn-message > div p:only-child {
  padding: 12px 0; }

.pgn-circle .alert .close {
  margin-top: -12px;
  position: absolute;
  right: 18px;
  top: 50%;
  opacity: 0;
  animation: fadeIn .3s .5s ease forwards;
  -webkit-animation: fadeIn .3s .5s ease forwards; }

.pgn-circle .alert p {
  margin-bottom: 0; }

.pgn-circle .alert > div {
  display: table;
  height: 100%; }

.pgn-circle .alert > div > div {
  display: table-cell;
  vertical-align: middle; }

@keyframes fadeInCircle {
  0% {
    opacity: 0;
    width: 60px; }
  100% {
    opacity: 1;
    width: 60px; } }

@-webkit-keyframes fadeInCircle {
  0% {
    opacity: 0;
    width: 60px; }
  100% {
    opacity: 1;
    width: 60px; } }

@keyframes resizeCircle {
  0% {
    width: 60px; }
  100% {
    width: 300px; } }

@-webkit-keyframes resizeCircle {
  0% {
    width: 60px; }
  100% {
    width: 300px; } }

/* Flip
------------------------------------
*/
.pgn-wrapper[data-position^='top-'] .pgn-flip {
  top: -30px; }

.pgn-wrapper[data-position^='bottom-'] .pgn-flip {
  bottom: -30px; }

.pgn-wrapper[data-position^='bottom-'] .pgn-flip .alert {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%; }

.pgn-flip .alert {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  box-shadow: 0 6px 4px -3px rgba(0, 0, 0, 0.2);
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  border-radius: 0;
  padding: 25px 35px;
  max-width: 500px;
  max-height: 250px;
  overflow: hidden; }

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    -webkit-transition-timing-function: ease-in; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
    -webkit-transition-timing-function: ease-out; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
    -webkit-transition-timing-function: ease-in;
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 5deg);
    -webkit-transition-timing-function: ease-out; }
  100% {
    -webkit-transform: perspective(400px); } }

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 5deg);
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out; }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Pages Notification customizations */
body > .pgn-wrapper[data-position="top"] {
  top: 60px;
  left: 70px; }

body > .pgn-wrapper[data-position="bottom"] {
  left: 70px; }

body > .pgn-wrapper[data-position$='-left'] {
  left: 90px;
  right: auto; }

body > .pgn-wrapper[data-position^='top-'] {
  top: 80px;
  bottom: auto; }

.pgn-circle .alert .close {
  margin-top: -4px; }

body > .pgn-wrapper {
  z-index: 900; }

@media (max-width: 979px) {
  body .pgn-wrapper[data-position="top"] {
    left: 0 !important; }
  body .pgn-wrapper[data-position="bottom"] {
    left: 0 !important; } }

@media (max-width: 767px) {
  body .pgn-wrapper[data-position$='-left'],
  body .pgn-wrapper[data-position$='-right'] {
    left: 10px !important;
    right: 10px !important; }
  body .pgn-wrapper[data-position$='-right'] .alert,
  body .pgn-wrapper[data-position$='-left'] .alert {
    max-width: 100%;
    width: 100%; } }

/* Notifications demo
--------------------------------------------------
*/
.notification-positions {
  border-radius: 4px;
  border: 3px dashed #e6e6e6;
  height: 370px;
  position: relative; }
  .notification-positions > div > .position:not(:only-child) {
    max-width: 50%; }
  .notification-positions .position {
    padding: 15px; }
    .notification-positions .position img {
      border: 3px solid transparent; }
    .notification-positions .position:hover {
      cursor: pointer; }
    .notification-positions .position.active img {
      border-color: #0090d9; }
    .notification-positions .position.pull-top, .notification-positions .position.pull-bottom {
      left: 0;
      right: 0; }
    .notification-positions .position img {
      width: 100%; }

.notification-tabs {
  flex-wrap: wrap; }

.badge {
  font-weight: 500;
  padding: 5px 8px; }

.badge-primary {
  background-color: #FF6666;
  color: #ffffff; }

h3 > .badge {
  font-size: 15px; }

.accordion.accordion-shadow {
  box-shadow: 0 1px 1px 0 rgba(155, 155, 155, 0.08), 0 1px 3px 1px rgba(155, 155, 155, 0.16); }

.accordion .card {
  margin-bottom: 0;
  border-color: rgba(0, 0, 0, 0.08); }

.accordion .card > .card-header {
  padding: 15px;
  background-color: #ffffff;
  border-bottom: none;
  line-height: 25px; }

.accordion .card > .collapse {
  border-top: 1px solid rgba(0, 0, 0, 0.08); }

.accordion .card > .collapse.show {
  background-color: #f8f8f8;
  border-left: 3px solid #2196f3;
  border-top: none;
  position: relative; }
  .accordion .card > .collapse.show:after, .accordion .card > .collapse.show:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    visibility: visible; }
  .accordion .card > .collapse.show:after {
    border-width: 10px;
    z-index: 10;
    border-bottom-color: #eeeeee;
    top: -20px;
    right: 50%;
    margin-right: -10px; }
  .accordion .card > .collapse.show:before {
    border-width: 12px;
    border-bottom-color: #ddd;
    top: -24px;
    right: 50%;
    margin-right: -12px; }

.accordion button[data-toggle="collapse"] {
  padding: 0;
  color: #000000; }

.accordion .card:last-child {
  border-bottom: 1px solid #dddddd; }

/* tagging mention
*/
.mentions-input {
  position: relative; }
  .mentions-input .input {
    overflow: hidden;
    position: relative;
    max-width: 100%;
    resize: none; }
  .mentions-input .highlighter {
    position: absolute;
    left: 0;
    right: 0;
    color: transparent;
    overflow: hidden;
    border-style: solid;
    border-color: transparent; }
  .mentions-input .highlighter-content {
    position: relative; }
  .mentions-input .highlighter-content strong {
    font-weight: inherit;
    background-color: #d3e4ff;
    display: inline;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0); }

/* no border in mention */
.mention-n-border div[class*='__control'] {
  border: none; }

.mention-n-border div[class*='__highlighter'], .mention-n-border textarea[class*='__input'] {
  padding: 0 32px 0 0; }

.ui-autocomplete {
  border: 1px solid #ddd;
  list-style: none;
  padding: 0;
  position: absolute !important;
  top: 100%;
  max-height: 200px;
  overflow: auto;
  z-index: 20; }
  .ui-autocomplete li {
    display: block;
    border-bottom: 1px solid #ddd; }
  .ui-autocomplete li a {
    color: #333;
    background-color: #f8f8f8;
    display: block;
    padding: 10px; }
  .ui-autocomplete li a.ui-state-focus,
  .ui-autocomplete li a.ui-state-active {
    background-color: #FF6666;
    color: #ffffff; }
  .ui-autocomplete li:last-child {
    border-bottom: none; }

[data-mention] {
  background-color: #a3bcea;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0); }

.masonry .grid-item {
  margin: 0 10px 10px 0;
  float: left; }

.masonry,
.masonry .grid-item,
.masonry .grid-item img {
  -webkit-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  -ms-transition: opacity 0.4s;
  transition: opacity 0.4s; }

.masonry.is-loaded,
.masonry .grid-item,
.masonry .grid-item img {
  opacity: 1; }

.masonry,
.masonry .grid-item.is-loading img .grid-item.is-loading {
  opacity: 0; }

.demo-masonry .grid-item {
  width: 250px;
  background-color: #eee;
  border: 1px solid #dddddd;
  cursor: pointer; }
  .demo-masonry .grid-item:hover:after {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .demo-masonry .grid-item.ht200 {
    height: 200px; }
  .demo-masonry .grid-item.ht100 {
    height: 100px; }
  .demo-masonry .grid-item.ht300 {
    height: 300px; }

.sgLoader.onPage, .sgLoader.onContainer {
  background-color: rgba(36, 137, 197, 0.9);
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .sgLoader.onPage.onContainer, .sgLoader.onContainer.onContainer {
    position: absolute; }
  .sgLoader.onPage .wrap, .sgLoader.onContainer .wrap {
    top: 40%;
    position: relative; }
    .sgLoader.onPage .wrap.stickTop, .sgLoader.onContainer .wrap.stickTop {
      top: 10%; }
  .sgLoader.onPage .roundSpin, .sgLoader.onContainer .roundSpin {
    top: 50%;
    margin-top: -23px; }
  .sgLoader.onPage .message, .sgLoader.onContainer .message {
    text-align: center;
    -webkit-animation: none;
    animation: none;
    position: relative;
    top: 50%;
    margin: 0 auto;
    display: block;
    color: white;
    margin-top: -23px; }
    .sgLoader.onPage .message .title, .sgLoader.onContainer .message .title {
      font-size: 15px;
      font-weight: 600; }

.sgLoader.horizon {
  position: relative;
  width: 100%;
  height: 3px;
  overflow-x: hidden; }
  .sgLoader.horizon .progress, .sgLoader.horizon .buffer {
    background-color: #FF6666; }
  .sgLoader.horizon .progress {
    position: absolute;
    opacity: 0.4;
    width: 150%;
    height: 3px; }
  .sgLoader.horizon .buffer {
    position: absolute;
    height: 3px; }
  .sgLoader.horizon .inc {
    animation: bufferIncrease 2s infinite; }
  .sgLoader.horizon .dec {
    animation: bufferDecrease 2s 0.5s infinite; }
  .sgLoader.horizon.blue .progress, .sgLoader.horizon.blue .buffer {
    background-color: #2196f3; }
  .sgLoader.horizon.green .progress, .sgLoader.horizon.green .buffer {
    background-color: #28a745; }
  .sgLoader.horizon.white .progress, .sgLoader.horizon.white .buffer {
    background-color: #ffffff; }
  .sgLoader.horizon.yellow .progress, .sgLoader.horizon.yellow .buffer {
    background-color: #ffc107; }

.sgLoader .loader-backdrop {
  background-color: rgba(36, 137, 197, 0.9);
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .sgLoader .loader-backdrop .roundSpin {
    top: 50%;
    margin-top: 23px; }
  .sgLoader .loader-backdrop .message {
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    top: 50%;
    position: absolute;
    width: 100%;
    margin-top: 100px;
    max-width: 300px;
    left: 50%;
    margin: 100px 0 0 -150px; }

.sgLoader .roundSpin {
  margin: 10px auto;
  font-size: 10px;
  position: relative;
  border-top: 8px solid rgba(36, 137, 197, 0.2);
  border-right: 8px solid rgba(36, 137, 197, 0.2);
  border-bottom: 8px solid rgba(36, 137, 197, 0.2);
  border-left: 8px solid #2489C5;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loadRound 1.1s infinite linear;
  animation: loadRound 1.1s infinite linear; }
  .sgLoader .roundSpin.green {
    border-top-color: rgba(30, 166, 158, 0.2);
    border-right-color: rgba(30, 166, 158, 0.2);
    border-bottom-color: rgba(30, 166, 158, 0.2);
    border-left-color: #1ea69e; }
  .sgLoader .roundSpin.white {
    border-top-color: rgba(255, 255, 255, 0.2);
    border-right-color: rgba(255, 255, 255, 0.2);
    border-bottom-color: rgba(255, 255, 255, 0.2);
    border-left-color: #ffffff; }
  .sgLoader .roundSpin.orange {
    border-top-color: rgba(252, 173, 0, 0.2);
    border-right-color: rgba(252, 173, 0, 0.2);
    border-bottom-color: rgba(252, 173, 0, 0.2);
    border-left-color: #FCAD00; }
  .sgLoader .roundSpin.yellow {
    border-top-color: rgba(255, 251, 5, 0.2);
    border-right-color: rgba(255, 251, 5, 0.2);
    border-bottom-color: rgba(255, 251, 5, 0.2);
    border-left-color: #FFFB05; }
  .sgLoader .roundSpin, .sgLoader .roundSpin:after {
    border-radius: 50%;
    width: 40px;
    height: 40px; }
  .sgLoader .roundSpin.sm, .sgLoader .roundSpin.sm:after {
    width: 20px;
    height: 20px;
    border-width: 3px; }
  .sgLoader .roundSpin.md, .sgLoader .roundSpin.md:after {
    width: 30px;
    height: 30px;
    border-width: 5px; }
  .sgLoader .roundSpin.lg, .sgLoader .roundSpin.lg:after {
    width: 40px;
    height: 40px;
    border-width: 8px; }
  .sgLoader .roundSpin.xl, .sgLoader .roundSpin.xl:after {
    width: 60px;
    height: 60px;
    border-width: 8px; }

/* linear
*/
.sg-linear-progress {
  background-color: #bbdefb !important; }
  .sg-linear-progress > div:first-child {
    background-color: #2196f3; }
  .sg-linear-progress > div:last-child {
    background-color: #1976d2; }

@keyframes bufferIncrease {
  from {
    left: -5%;
    width: 5%; }
  to {
    left: 130%;
    width: 100%; } }

@keyframes bufferDecrease {
  from {
    left: -80%;
    width: 80%; }
  to {
    left: 110%;
    width: 10%; } }

@-webkit-keyframes loadRoundStep {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes loadRoundStep {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes loadRound {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes loadRound {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  padding: 0 30px;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: '';
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir='rtl'] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* Icons */
/*@if $slick-font-family == "slick" {
	@font-face {
		font-family: "slick";
		src: slick-font-url("slick.eot");
		src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
		font-weight: normal;
		font-style: normal;
	}
}*/
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-size: 20px;
    line-height: 1;
    color: #cccccc;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -5px; }
  [dir='rtl'] .slick-prev {
    left: auto;
    right: -5px; }
  .slick-prev:before {
    content: "arrow_back_ios"; }
    [dir='rtl'] .slick-prev:before {
      content: "arrow_forward_ios"; }

.slick-next {
  right: -5px; }
  [dir='rtl'] .slick-next {
    left: -5px;
    right: auto; }
  .slick-next:before {
    content: "arrow_forward_ios"; }
    [dir='rtl'] .slick-next:before {
      content: "arrow_back_ios"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "radio_button_unchecked";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.slick-demo {
  width: 100%;
  margin: 0 auto; }
  .slick-demo .img {
    background: #ccc;
    padding: 5px;
    margin: 0 10px; }
  .slick-demo img {
    height: 170px; }
  .slick-demo h3 {
    background: #f8f8f8;
    color: #000000;
    border: 1px solid #ddd;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center; }
  .slick-demo .slick-slide {
    width: 250px; }
  .slick-demo .slick-slide:focus {
    outline: none; }
  .slick-demo .form {
    margin: 0 10px; }

/*
tooltip
*/
.el-tooltip__popper {
  z-index: 1000; }

.Toastify__toast--error {
  background: #FF6666; }

/*! nouislider - 11.1.0 - 2018-04-02 11:18:13 */
/* Functional styling;
* These styles are required for noUiSlider to function.
* You don't need to change these rules to apply your design.
*/
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative;
  direction: ltr; }

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

/* Wrapper for all connect elements.
*/
.noUi-connects {
  overflow: hidden;
  z-index: 0; }

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0; }

/* Offset direction
*/
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0; }

/* Give origins 0 height/width so they don't interfere with clicking the
* connect elements.
*/
.noUi-vertical .noUi-origin {
  width: 0; }

.noUi-horizontal .noUi-origin {
  height: 0; }

.noUi-handle {
  position: absolute; }

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

/* Slider size and handle placement;
*/
.noUi-horizontal {
  height: 6px;
  margin: 10px 0; }

.noUi-horizontal .noUi-handle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  left: -17px;
  top: -9px; }

.noUi-vertical {
  width: 18px; }

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px; }

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -17px;
  left: auto; }

/* Styling;
* Giving the connect element a border radius causes issues with using transform: scale
*/
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px; }

.noUi-connects {
  border-radius: 3px;
  background-color: #B8B8B8; }

.noUi-connect {
  background: #FF6666; }

/* Handles and cursors;
*/
.noUi-draggable {
  cursor: ew-resize; }

.noUi-vertical .noUi-draggable {
  cursor: ns-resize; }

.noUi-handle {
  border-radius: 3px;
  background: #FF6666;
  cursor: default; }
  .noUi-handle:active, .noUi-handle:hover, .noUi-handle:focus {
    outline: none; }

[disabled].noUi-target .noUi-handle {
  background: #B8B8B8; }

.noUi-horizontal:not(.slider-multi-range) .noUi-handle[aria-valuenow="0.0"],
[disabled].noUi-target:not(.slider-multi-range) .noUi-handle[aria-valuenow="0.0"] {
  background: #ffffff;
  border: 3px solid #999; }

.noUi-active {
  background: #bf360c; }

/* Handle stripes;
*/
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: none;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px; }

.noUi-handle:after {
  left: 17px; }

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px; }

.noUi-vertical .noUi-handle:after {
  top: 17px; }

/* Disabled state;
*/
[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed; }

/* Base;
*
*/
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-pips {
  position: absolute;
  color: #999; }

/* Values;
*
*/
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center; }

.noUi-value-sub {
  color: #ccc;
  font-size: 10px; }

/* Markings;
*
*/
.noUi-marker {
  position: absolute;
  background: #CCC; }

.noUi-marker-sub {
  background: #AAA; }

.noUi-marker-large {
  background: #AAA; }

/* Horizontal layout;
*
*/
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%; }

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px; }

/* Vertical layout;
*
*/
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%, 0);
  padding-left: 25px; }

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap; }

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%; }

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%; }

.el-date-editor--daterange input.el-input__inner {
  border-color: rgba(0, 0, 0, 0.15) !important;
  border-radius: 2px;
  height: 35px; }

.react-datepicker-popper {
  z-index: 99999; }

@media (max-width: 575px) {
  .el-date-editor,
  .el-date-editor--daterange.el-input {
    width: 100%; } }

/*!
 * Bootstrap-select v1.12.4 (http://silviomoreto.github.io/bootstrap-select)
 *
 * Copyright 2013-2017 bootstrap-select
 * Licensed under MIT (https://github.com/silviomoreto/bootstrap-select/blob/master/LICENSE)
 */
select.bs-select-hidden,
select.selectpicker {
  display: none !important; }

.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/ }
  .bootstrap-select > .dropdown-toggle {
    height: 35px; }
  .bootstrap-select > .dropdown-toggle:focus {
    outline: none !important; }
  .bootstrap-select > .dropdown-toggle::after {
    position: absolute;
    top: 14px; }
  .bootstrap-select .dropdown-menu.open .dropdown-menu {
    display: block; }
  .bootstrap-select .dropdown-menu.open .dropdown-menu li a {
    display: block;
    padding: 5px 15px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px; }
  .bootstrap-select .dropdown-menu.open .dropdown-menu li a:hover, .bootstrap-select .dropdown-menu.open .dropdown-menu li a:active, .bootstrap-select .dropdown-menu.open .dropdown-menu li a:focus {
    outline: none; }
  .bootstrap-select .dropdown-menu.open .dropdown-menu li a:hover {
    background-color: #f8f9fa; }
  .bootstrap-select .dropdown-menu {
    padding: 0; }

.bootstrap-select > .dropdown-toggle {
  width: 100%;
  padding-right: 25px;
  z-index: 1; }

.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  color: #999; }

.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none; }

.bootstrap-select > select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2; }

.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle {
  border-color: #b94a48; }

.bootstrap-select.fit-width {
  width: auto !important; }

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 220px; }

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none; }

.bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%; }

.bootstrap-select.form-control.input-group-btn {
  z-index: auto; }

.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0; }

.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right,
.row .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
  float: right; }

.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group,
.form-group .bootstrap-select.btn-group {
  margin-bottom: 0; }

.form-group-lg .bootstrap-select.btn-group.form-control,
.form-group-sm .bootstrap-select.btn-group.form-control {
  padding: 0; }

.form-group-lg .bootstrap-select.btn-group.form-control .dropdown-toggle,
.form-group-sm .bootstrap-select.btn-group.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit; }

.form-inline .bootstrap-select.btn-group .form-control {
  width: 100%; }

.bootstrap-select.btn-group.disabled,
.bootstrap-select.btn-group > .disabled {
  cursor: not-allowed; }

.bootstrap-select.btn-group.disabled:focus,
.bootstrap-select.btn-group > .disabled:focus {
  outline: none !important; }

.bootstrap-select.btn-group.bs-container {
  position: absolute;
  height: 0 !important;
  padding: 0 !important; }

.bootstrap-select.btn-group.bs-container .dropdown-menu {
  z-index: 1060; }

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left; }

.bootstrap-select.btn-group .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle; }

.bootstrap-select.btn-group[class*="col-"] .dropdown-toggle {
  width: 100%; }

.bootstrap-select.btn-group .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.bootstrap-select.btn-group .dropdown-menu li {
  position: relative; }

.bootstrap-select.btn-group .dropdown-menu li.active small {
  color: #fff; }

.bootstrap-select.btn-group .dropdown-menu li.disabled a {
  cursor: not-allowed; }

.bootstrap-select.btn-group .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bootstrap-select.btn-group .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em; }

.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
  display: none; }

.bootstrap-select.btn-group .dropdown-menu li a span.text {
  display: inline-block; }

.bootstrap-select.btn-group .dropdown-menu li small {
  padding-left: 0.5em; }

.bootstrap-select.btn-group .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bootstrap-select.btn-group .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap; }

.bootstrap-select.btn-group.fit-width .dropdown-toggle .filter-option {
  position: static; }

.bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px; }

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  margin-top: 5px; }

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
  margin-right: 34px; }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle {
  z-index: 1061; }

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none; }

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
  bottom: auto;
  top: -3px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
  bottom: auto;
  top: -3px;
  border-top: 6px solid white;
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
  right: 12px;
  left: auto; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
  right: 13px;
  left: auto; }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
  display: block; }

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px; }

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bs-actionsbox .btn-group button {
  width: 50%; }

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bs-donebutton .btn-group button {
  width: 100%; }

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px; }

.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none; }

/*# sourceMappingURL=bootstrap-select.css.map */
/*------------------------------------------------------------------
[28. Misc : Helper Classes]
*/
.custom {
  height: 150px; }

.icon-list .fa-item {
  display: block;
  color: #121212;
  line-height: 32px;
  height: 32px;
  padding-left: 10px; }
  .icon-list .fa-item > i {
    width: 32px;
    font-size: 14px;
    display: inline-block;
    text-align: right;
    margin-right: 10px; }

.push-on-sidebar-open {
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  -webkit-backface-visibility: hidden; }

/* Thumbnail for icons and profile pics
------------------------------------
*/
.thumbnail-wrapper {
  display: inline-block;
  overflow: hidden;
  float: left; }
  .thumbnail-wrapper.circular {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%; }
  .thumbnail-wrapper.bordered {
    border-width: 4px;
    border-style: solid; }
    .thumbnail-wrapper.bordered.d16 {
      width: 20px;
      height: 20px; }
      .thumbnail-wrapper.bordered.d16 > * {
        line-height: 12px; }
    .thumbnail-wrapper.bordered.d24 {
      width: 28px;
      height: 28px; }
      .thumbnail-wrapper.bordered.d24 > * {
        line-height: 24px; }
    .thumbnail-wrapper.bordered.d32 {
      width: 36px;
      height: 36px; }
      .thumbnail-wrapper.bordered.d32 > * {
        line-height: 28px; }
    .thumbnail-wrapper.bordered.d48 {
      width: 52px;
      height: 52px; }
      .thumbnail-wrapper.bordered.d48 > * {
        line-height: 44px; }
  .thumbnail-wrapper.d16 {
    width: 16px;
    height: 16px; }
    .thumbnail-wrapper.d16 > * {
      line-height: 16px; }
  .thumbnail-wrapper.d24 {
    width: 24px;
    height: 24px; }
    .thumbnail-wrapper.d24 > * {
      line-height: 24px; }
  .thumbnail-wrapper.d32 {
    width: 32px;
    height: 32px; }
    .thumbnail-wrapper.d32 > * {
      line-height: 32px; }
  .thumbnail-wrapper.d39 {
    width: 39px;
    height: 39px; }
    .thumbnail-wrapper.d39 > * {
      line-height: 39px; }
  .thumbnail-wrapper.d48 {
    width: 48px;
    height: 48px; }
    .thumbnail-wrapper.d48 > * {
      line-height: 50px; }
  .thumbnail-wrapper > * {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    text-align: center; }

/* rotate
------------------------------------
*/
.rotate-180 {
  transform: rotate(180deg); }

/* Profile dropdown
------------------------------------
*/
.profile-dropdown {
  background: #fff;
  padding: 0; }
  .profile-dropdown:before {
    position: absolute;
    top: -7px;
    right: 15px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: ''; }
  .profile-dropdown:after {
    position: absolute;
    top: -6px;
    right: 16px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: ''; }
  .profile-dropdown > a {
    opacity: .5;
    -webkit-transition: opacity ease 0.3s;
    transition: opacity ease 0.3s;
    padding-left: 17px;
    padding-right: 37px;
    min-width: 138px; }
    .profile-dropdown > a > i {
      margin-right: 5px; }
    .profile-dropdown > a:hover {
      opacity: 1; }
    .profile-dropdown > a:last-child {
      padding-bottom: 3px !important;
      padding-top: 3px; }

.profile-dropdown-toggle {
  background: transparent;
  border: none; }

/* Scroll
------------------------------------
*/
.scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

/* Overides
------------------------------------
*/
.no-padding {
  padding: 0px !important; }

.no-margin {
  margin: 0px !important; }

.no-overflow {
  overflow: hidden !important; }

.auto-overflow {
  overflow: auto; }

.reset-overflow {
  overflow: initial !important; }

.center-margin {
  margin-left: auto;
  margin-right: auto; }

.inherit-size {
  width: inherit;
  height: inherit; }

.inherit-height {
  height: inherit; }

.image-responsive-height {
  width: 100%; }

.image-responsive-width {
  height: 100%; }

.lower-index {
  z-index: 0; }

.overlayer {
  position: absolute;
  display: block;
  z-index: 21; }
  .overlayer.fullwidth {
    width: 100%; }

.overlayer-wrapper {
  position: relative;
  display: block;
  z-index: 10; }

.overlay-fixed {
  position: fixed !important;
  top: auto !important; }

.top-left {
  position: absolute !important;
  top: 0;
  left: 0; }

.top-right {
  position: absolute !important;
  top: 1px;
  right: 0; }

.bottom-left {
  position: absolute !important;
  bottom: 1px;
  left: 0; }

.bottom-right {
  position: absolute !important;
  bottom: 0;
  right: 0; }

.pull-bottom {
  position: absolute !important;
  bottom: 0; }

.pull-up {
  position: absolute !important;
  top: 0; }

.pull-center {
  left: 0; }

.pull-right {
  float: right !important; }

.pull-center {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  display: table;
  z-index: 1; }

.cursor {
  cursor: pointer; }

.no-cursor {
  cursor: default !important; }

.scroll-x-hidden {
  overflow-x: hidden !important; }

/* Generic Padding Helpers
------------------------------------
*/
.p-t-0 {
  padding-top: 0px !important; }

.p-r-0 {
  padding-right: 0px !important; }

.p-l-0 {
  padding-left: 0px !important; }

.p-b-0 {
  padding-bottom: 0px !important; }

.padding-0 {
  padding: 0px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.p-r-5 {
  padding-right: 5px !important; }

.p-l-5 {
  padding-left: 5px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.padding-5 {
  padding: 5px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.p-r-10 {
  padding-right: 10px !important; }

.p-l-10 {
  padding-left: 10px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.padding-10 {
  padding: 10px !important; }

.p-t-15 {
  padding-top: 15px !important; }

.p-r-15 {
  padding-right: 15px !important; }

.p-l-15 {
  padding-left: 15px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.padding-15 {
  padding: 15px !important; }

.p-t-20 {
  padding-top: 20px !important; }

.p-r-20 {
  padding-right: 20px !important; }

.p-l-20 {
  padding-left: 20px !important; }

.p-b-20 {
  padding-bottom: 20px !important; }

.padding-20 {
  padding: 20px !important; }

.p-t-25 {
  padding-top: 25px !important; }

.p-r-25 {
  padding-right: 25px !important; }

.p-l-25 {
  padding-left: 25px !important; }

.p-b-25 {
  padding-bottom: 25px !important; }

.padding-25 {
  padding: 25px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.p-r-30 {
  padding-right: 30px !important; }

.p-l-30 {
  padding-left: 30px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.padding-30 {
  padding: 30px !important; }

.p-t-35 {
  padding-top: 35px !important; }

.p-r-35 {
  padding-right: 35px !important; }

.p-l-35 {
  padding-left: 35px !important; }

.p-b-35 {
  padding-bottom: 35px !important; }

.padding-35 {
  padding: 35px !important; }

.p-t-40 {
  padding-top: 40px !important; }

.p-r-40 {
  padding-right: 40px !important; }

.p-l-40 {
  padding-left: 40px !important; }

.p-b-40 {
  padding-bottom: 40px !important; }

.padding-40 {
  padding: 40px !important; }

.p-t-45 {
  padding-top: 45px !important; }

.p-r-45 {
  padding-right: 45px !important; }

.p-l-45 {
  padding-left: 45px !important; }

.p-b-45 {
  padding-bottom: 45px !important; }

.padding-45 {
  padding: 45px !important; }

.p-t-50 {
  padding-top: 50px !important; }

.p-r-50 {
  padding-right: 50px !important; }

.p-l-50 {
  padding-left: 50px !important; }

.p-b-50 {
  padding-bottom: 50px !important; }

.padding-50 {
  padding: 50px !important; }

/* Generic Margin Helpers
------------------------------------
*/
.m-t-0 {
  margin-top: 0px; }

.m-r-0 {
  margin-right: 0px !important; }

.m-l-0 {
  margin-left: 0px; }

.m-b-0 {
  margin-bottom: 0px; }

.m-t-5 {
  margin-top: 5px; }

.m-r-5 {
  margin-right: 5px !important; }

.m-l-5 {
  margin-left: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-r-10 {
  margin-right: 10px !important; }

.m-l-10 {
  margin-left: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-r-15 {
  margin-right: 15px !important; }

.m-l-15 {
  margin-left: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-r-20 {
  margin-right: 20px !important; }

.m-l-20 {
  margin-left: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-t-25 {
  margin-top: 25px; }

.m-r-25 {
  margin-right: 25px !important; }

.m-l-25 {
  margin-left: 25px; }

.m-b-25 {
  margin-bottom: 25px; }

.m-t-30 {
  margin-top: 30px; }

.m-r-30 {
  margin-right: 30px !important; }

.m-l-30 {
  margin-left: 30px; }

.m-b-30 {
  margin-bottom: 30px; }

.m-t-35 {
  margin-top: 35px; }

.m-r-35 {
  margin-right: 35px !important; }

.m-l-35 {
  margin-left: 35px; }

.m-b-35 {
  margin-bottom: 35px; }

.m-t-40 {
  margin-top: 40px; }

.m-r-40 {
  margin-right: 40px !important; }

.m-l-40 {
  margin-left: 40px; }

.m-b-40 {
  margin-bottom: 40px; }

.m-t-45 {
  margin-top: 45px; }

.m-r-45 {
  margin-right: 45px !important; }

.m-l-45 {
  margin-left: 45px; }

.m-b-45 {
  margin-bottom: 45px; }

.m-t-50 {
  margin-top: 50px; }

.m-r-50 {
  margin-right: 50px !important; }

.m-l-50 {
  margin-left: 50px; }

.m-b-50 {
  margin-bottom: 50px; }

.full-height {
  height: 100% !important; }

.full-width {
  width: 100%; }

.hide {
  display: none !important; }

.inline {
  display: inline-block !important; }

.block {
  display: block !important; }

.b-blank {
  border-color: #000; }

/* background helpers
------------------------------------
*/
.bg-greylight {
  background-color: #f8f8f8; }

.bg-grey {
  background-color: #e9e9e9; }

.bg-bluegrey {
  background-color: #edf2f7; }

/* Border Helpers
------------------------------------
*/
.b-a,
.b-r,
.b-l,
.b-t,
.b-b {
  border-style: solid;
  border-width: 0; }

.border {
  border-color: #dddddd; }

.b-r {
  border-right-width: 1px; }

.b-l {
  border-left-width: 1px; }

.b-t {
  border-top-width: 1px; }

.b-b {
  border-bottom-width: 1px; }

.b-a {
  border-width: 1px; }

.b-dashed {
  border-style: dashed; }

.b-thick {
  border-width: 2px; }

.b-transparent {
  border-color: rgba(0, 0, 0, 0.4); }

.b-transparent-white {
  border-color: rgba(255, 255, 255, 0.3); }

.b-grey {
  border-color: #e6e6e6; }

.b-white {
  border-color: #fff; }

.b-primary {
  border-color: #FF6666; }

.b-complete {
  border-color: #FF6666; }

.b-success {
  border-color: #10cfbd; }

.b-info {
  border-color: #2979FF; }

.b-danger {
  border-color: #f55753; }

.b-warning {
  border-color: #f8d053; }

/* Border Radius
------------------------------------
*/
.b-rad-sm {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px; }

.b-rad-md {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px; }

.b-rad-lg {
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px; }

.no-border {
  border: none !important; }

/** Profile Wrappers **/
.profile-img-wrapper {
  display: inline-block;
  float: left;
  border-radius: 100px 100px 100px 100px;
  display: inline-block;
  height: 35px;
  overflow: hidden;
  width: 35px;
  -webkit-flex: 1;
  -moz-flex: 1;
  flex: 1; }

.profile-img-wrapper.big {
  height: 68px;
  width: 68px; }

.profile-img-wrapper.with-left-space-custom {
  margin-left: 7px; }

.relative {
  position: relative; }

.flex-1 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

[data-pages-bg-image] {
  background-size: cover; }

/* Demo Purposes */
.icon-set-preview {
  transition: opacity 0.1s linear; }

#icon-list {
  transition: all 0.1s ease-in-out; }

.visible-xlg {
  display: none; }

.hidden-xlg {
  display: block; }

.sm-gutter .row > [class^="col-"], .sm-gutter .row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px; }

.sm-gutter .row {
  margin-left: -5px;
  margin-right: -5px; }

/* Aspect ratio */
.ar-1-1 .card,
.ar-2-1 .card,
.ar-1-2 .card,
.ar-3-2 .card,
.ar-2-3 .card {
  margin: 0; }

.ar-1-1 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden; }
  .ar-1-1:before {
    content: "";
    display: block;
    padding-top: 100%;
    /* initial ratio of 1:1*/ }
  .ar-1-1 > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }

.ar-2-1 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden; }
  .ar-2-1:before {
    content: "";
    display: block;
    padding-top: calc(50% - 5px);
    /* initial ratio of 1:1*/ }
  .ar-2-1 > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }

.ar-1-2 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden; }
  .ar-1-2:before {
    content: "";
    display: block;
    padding-top: calc(150% - 5px);
    /* initial ratio of 1:1*/ }
  .ar-1-2 > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }

.ar-3-2 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden; }
  .ar-3-2:before {
    content: "";
    display: block;
    padding-top: calc(75% - 5px);
    /* initial ratio of 1:1*/ }
  .ar-3-2 > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }

.ar-2-3 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden; }
  .ar-2-3:before {
    content: "";
    display: block;
    padding-top: calc(125% - 5px);
    /* initial ratio of 1:1*/ }
  .ar-2-3 > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }

.v-align-bottom {
  vertical-align: bottom !important; }

.v-align-top {
  vertical-align: top !important; }

.v-align-middle {
  vertical-align: middle !important; }

/* vertical alignment styles */
.col-top {
  vertical-align: top !important; }

.col-middle {
  vertical-align: middle !important; }

.col-bottom {
  vertical-align: bottom !important; }

/* columns of same height styles
------------------------------------
*/
.container-xs-height {
  display: table;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; }

.row-xs-height {
  display: table-row; }

.col-xs-height {
  display: table-cell;
  float: none; }

@media (min-width: 768px) {
  .container-sm-height {
    display: table;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; } }

@media (min-width: 992px) {
  .container-md-height {
    display: table;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; }
  .row-md-height {
    display: table-row; }
  .col-md-height {
    display: table-cell !important;
    float: none !important; } }

@media (min-width: 1200px) {
  .container-lg-height {
    display: table;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; }
  .row-lg-height {
    display: table-row; }
  .col-lg-height {
    display: table-cell !important;
    float: none !important; } }

.windows .d-flex {
  -ms-flex-wrap: wrap; }

/* ## common reusable class in UI
*/
/* google material icons
*/
/* Rules for sizing the icon. */
.material-icons {
  vertical-align: middle; }

.material-icons.md-18 {
  font-size: 18px; }

.material-icons.md-24 {
  font-size: 24px; }

.material-icons.md-36 {
  font-size: 36px; }

.material-icons.md-48 {
  font-size: 48px; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54); }

.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: white; }

.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3); }

/* text size
*/
.text-xxs {
  font-size: 9px !important; }

.text-xs {
  font-size: 10px !important; }

.text-sm {
  font-size: 11px !important; }

.text-md {
  font-size: 12px !important; }

.text-lg {
  font-size: 14px !important; }

.text-xlg {
  font-size: 16px !important; }

/* text color
*/
.text-color-primary {
  color: rgba(0, 0, 0, 0.87) !important; }

.text-color-sub {
  color: rgba(0, 0, 0, 0.54) !important; }

.text-color-inactive {
  color: rgba(0, 0, 0, 0.38) !important; }

.text-color-rev-primary {
  color: white !important; }

.text-color-rev-sub {
  color: rgba(255, 255, 255, 0.7) !important; }

.text-color-rev-inactive {
  color: rgba(255, 255, 255, 0.3) !important; }

/* text decoration
*/
.text-linethrough {
  text-decoration: line-through; }

/* text truncate
*/
/* text weight
*/
.text-bold {
  font-weight: bold !important; }

.text-normal {
  font-weight: normal !important; }

/* text vertical alignment
*/
.text-vert-top {
  vertical-align: text-top !important; }

.text-vert-bottom {
  vertical-align: text-bottom !important; }

.text-vert-middle {
  vertical-align: middle !important; }

/* scroll
*/
.scroll-none {
  overflow: hidden; }

/* text new line
*/
.newline {
  display: block !important; }

/* border
*/
.border-none, .border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-top {
  border-top: 1px solid #dddddd !important; }

.border-bottom {
  border-bottom: 1px solid #dddddd !important; }

.border-left {
  border-left: 1px solid #dddddd !important; }

.border-right {
  border-right: 1px solid #dddddd !important; }

/* padding
*/
.mb-0 {
  margin-bottom: 0rem !important; }

.mb-5 {
  margin-bottom: 5rem !important; }

.mb-10 {
  margin-bottom: 10rem !important; }

.mb-15 {
  margin-bottom: 15rem !important; }

.mb-20 {
  margin-bottom: 20rem !important; }

.mb-25 {
  margin-bottom: 25rem !important; }

.p-5 {
  padding: 5px !important; }

.p-10 {
  padding: 10px !important; }

.p-15 {
  padding: 15px !important; }

.p-20 {
  padding: 20px !important; }

.p-25 {
  padding: 25px !important; }

.p-30 {
  padding: 30px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.p-t-15 {
  padding-top: 15px !important; }

.p-t-20 {
  padding-top: 20px !important; }

.p-t-25 {
  padding-top: 25px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.p-b-20 {
  padding-bottom: 20px !important; }

.p-b-25 {
  padding-bottom: 25px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.p-b-35 {
  padding-bottom: 35px !important; }

.p-b-40 {
  padding-bottom: 40px !important; }

.p-r-5 {
  padding-right: 5px !important; }

.p-r-10 {
  padding-right: 10px !important; }

.p-r-15 {
  padding-right: 15px !important; }

.p-r-20 {
  padding-right: 20px !important; }

.p-r-25 {
  padding-right: 25px !important; }

.p-r-30 {
  padding-right: 30px !important; }

.p-l-5 {
  padding-left: 5px !important; }

.p-l-10 {
  padding-left: 10px !important; }

.p-l-15 {
  padding-left: 15px !important; }

.p-l-20 {
  padding-left: 20px !important; }

.p-l-25 {
  padding-left: 25px !important; }

.p-l-30 {
  padding-left: 30px !important; }

/* margin
*/
.m-5 {
  margin: 5px !important; }

.m-10 {
  margin: 10px !important; }

.m-15 {
  margin: 15px !important; }

.m-20 {
  margin: 20px !important; }

.m-25 {
  margin: 25px !important; }

.m-30 {
  margin: 30px !important; }

.m-t-5 {
  margin-top: 5px !important; }

.m-t-10 {
  margin-top: 10px !important; }

.m-t-15 {
  margin-top: 15px !important; }

.m-t-20 {
  margin-top: 20px !important; }

.m-t-25 {
  margin-top: 25px !important; }

.m-t-30 {
  margin-top: 30px !important; }

.m-b-5 {
  margin-bottom: 5px !important; }

.m-b-10 {
  margin-bottom: 10px !important; }

.m-b-15 {
  margin-bottom: 15px !important; }

.m-b-20 {
  margin-bottom: 20px !important; }

.m-b-25 {
  margin-bottom: 25px !important; }

.m-b-30 {
  margin-bottom: 30px !important; }

.m-b-35 {
  margin-bottom: 35px !important; }

.m-b-40 {
  margin-bottom: 40px !important; }

.m-b-45 {
  margin-bottom: 45px !important; }

.m-b-50 {
  margin-bottom: 50px !important; }

.m-b-55 {
  margin-bottom: 55px !important; }

.m-b-60 {
  margin-bottom: 60px !important; }

.m-b-65 {
  margin-bottom: 65px !important; }

.m-b-70 {
  margin-bottom: 70px !important; }

.m-b-75 {
  margin-bottom: 75px !important; }

.m-b-80 {
  margin-bottom: 80px !important; }

.m-b-85 {
  margin-bottom: 85px !important; }

.m-b-90 {
  margin-bottom: 90px !important; }

.m-b-95 {
  margin-bottom: 95px !important; }

.m-b-100 {
  margin-bottom: 100px !important; }

.m-r-5 {
  margin-right: 5px !important; }

.m-r-10 {
  margin-right: 10px !important; }

.m-r-15 {
  margin-right: 15px !important; }

.m-r-20 {
  margin-right: 20px !important; }

.m-r-25 {
  margin-right: 25px !important; }

.m-r-30 {
  margin-right: 30px !important; }

.m-l-5 {
  margin-left: 5px !important; }

.m-l-10 {
  margin-left: 10px !important; }

.m-l-15 {
  margin-left: 15px !important; }

.m-l-20 {
  margin-left: 20px !important; }

.m-l-25 {
  margin-left: 25px !important; }

.m-l-30 {
  margin-left: 30px !important; }

.m-t-0 {
  margin-top: 0 !important; }

/* position
*/
.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-left {
  left: 0 !important;
  right: auto !important; }

.position-right {
  right: 0 !important;
  left: auto !important; }

/* height
*/
.ht100p {
  height: 100%; }

/* lazy
*/
.lazy {
  visibility: hidden; }

.lazy-show {
  visibility: visible; }

/* border radius
*/
/* help use by arrow mixins
*/
/* with or without border
*	e.g. @include arrow('right','bottom', 10px, 0px, #4fade3) - right bottom without border
*	e.g. @include arrow('right','bottom', 10px, 0px, #eee, #4fade3, 6px) - right bottom with border
*/
/* media queries
*	e.g
	.foo {
		@include screen(sm) { //max-width
			width: 22%;
		}
		@include screen(xs, min) { //min-width
			width: 10%;
		}
	}
*/
/* REM and pixel convertion : USAGE
h1 {
	@include font-size(12px);
}
*/
/* animation with keyframes :: USAGE
@include keyframes(circle1) {
	0% { top: 8px; }
	15% { top: 4px; }
	25% { top: 8px; }
}
.element {
	@include animation('circle1');
}
*/
/* transition
*/
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.on-drag-template-image .image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.ReactTable .rt-th.-cursor-pointer > div:after, #toast-container > .toast:before, .slick-prev:before,
.slick-next:before, .page-sidebar .sidebar-menu .menu-items > li > a > .arrow:before,
.page-sidebar .sidebar-menu .menu-items > li > span.sub-label > .arrow:before, [data-toggle-pin='sidebar'] > i:before {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons';
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

::placeholder {
  color: #000 !important;
  opacity: 0.3 !important; }

::-webkit-input-placeholder {
  color: #000 !important;
  opacity: 0.3 !important; }

:-ms-input-placeholder {
  color: #000 !important;
  opacity: 0.3 !important; }

::-moz-placeholder {
  color: #000 !important;
  opacity: 0.3 !important; }

/* text color
*/
/* padding
*/
/* z-index
*/
/* hover
*/
/* gap
*/
/* shadow
*/
/* text size
*/
/* theme - scenario editor */
/* Layouts */
html {
  height: 100%; }

/* Body Triggers for layout options
------------------------------------------------------------------
*/
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  -moz-osx-font-smoothing: grayscale; }
  body.fixed-header .header {
    position: fixed;
    left: 0;
    top: 0; }
  body.no-header .page-container .page-content-wrapper .content {
    padding-top: 0px; }
  body.no-header .header {
    border-bottom-color: transparent; }
  body.dashboard {
    background: #f5f5f5; }
    body.dashboard .page-container {
      background: #f5f5f5; }
  body.menu-pin .overlay {
    padding-left: 0; }
    body.menu-pin .overlay .overlay-brand {
      margin-left: 0; }

/* Page Container
------------------------------------
*/
.page-container {
  width: 100%;
  height: 100%;
  padding-left: 70px; }
  .page-container .page-content-wrapper {
    min-height: 100%;
    position: relative; }
    .page-container .page-content-wrapper .content {
      /* Content holder */
      z-index: 10;
      padding-top: 60px;
      padding-bottom: 141px;
      min-height: 100%;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      .page-container .page-content-wrapper .content.full-width {
        width: 100%; }
      .page-container .page-content-wrapper .content .content-inner {
        display: inline-block;
        vertical-align: top;
        height: 100%;
        padding-left: 30px;
        position: relative; }
      .page-container .page-content-wrapper .content:only-child {
        padding-bottom: 0px; }
      .page-container .page-content-wrapper .content.overlay-footer {
        padding-bottom: 0px; }
    .page-container .page-content-wrapper .footer {
      /* Footer */
      width: auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-transition: left 0.3s ease;
      transition: left 0.3s ease; }
      .page-container .page-content-wrapper .footer.fixed {
        position: fixed; }
  .page-container .footer .langs {
    float: right; }
  @media (max-width: 767px) {
    .page-container .footer .langs {
      float: none;
      margin: 0 auto;
      width: 80% !important; } }

.page-title {
  margin-top: 0px; }

.group-container {
  white-space: nowrap !important; }
  .group-container .single-group {
    vertical-align: top;
    display: inline-block;
    white-space: normal; }

.jumbotron {
  position: relative;
  overflow: hidden;
  display: block;
  padding: 0;
  background-color: #f0f0f0; }
  .jumbotron .inner {
    position: relative;
    z-index: 2; }
  .jumbotron.lg {
    height: 600px; }
  .jumbotron .cover-photo {
    width: 100%;
    height: inherit;
    overflow: hidden;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    background-size: cover;
    background-color: #10cfbd; }
  .jumbotron h1 {
    font-size: 44px; }

/* Other overides */
.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
  position: relative; }

.copyright {
  padding: 25px 0;
  border-top: 1px solid #e6e6e6;
  margin: 0 0 52px 0; }
  .copyright .col-12.col-md-8 {
    padding-left: 0; }
  .copyright .col-12.col-md-4 {
    padding-right: 0; }

.pager {
  margin: 0; }

/* Header
------------------------------------
*/
.header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  width: 100%;
  padding: 0 20px 0 0;
  z-index: 800;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .header a {
    color: #626262; }
    .header a:hover, .header a:active, .header a:focus {
      color: #626262; }
  .header .header-inner {
    height: inherit;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .header.transparent {
    background-color: transparent !important; }
  .header .brand {
    vertical-align: middle;
    width: 280px;
    text-align: left;
    padding-left: 95px; }
  .header .bubble {
    border-radius: 100%;
    height: 12px;
    width: 12px;
    background-color: #f55753;
    color: #fff;
    position: absolute;
    top: -6px;
    float: right;
    right: -5px; }
  .header .notification-list {
    display: inline-block;
    line-height: 23px; }
  .header .project-list {
    width: 250px; }
  .header .search-link {
    display: inline-block;
    margin-left: 15px;
    color: #626262;
    opacity: .7;
    font-size: 14px;
    vertical-align: middle;
    line-height: 12px;
    font-family: wf_segoe-ui_light, wf_segoe-ui_normal, "Helvetica Neue", Helvetica, Arial, sans-serif; }
    .header .search-link i {
      margin-right: 15px;
      font-size: 16px;
      vertical-align: middle; }
    .header .search-link:hover {
      opacity: 1; }
  .header .pg {
    font-size: 16px; }
  .header ul li {
    line-height: 12px;
    vertical-align: middle; }
  .header .dropdown {
    line-height: 12px; }
  .header .dropdown-menu {
    margin-top: 10px; }

/* Bootstrap navbar
------------------------------------
*/
.navbar {
  min-height: 50px;
  position: relative;
  margin-bottom: 20px;
  border: 1px solid transparent;
  top: -1px; }

.navbar-nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
  padding-top: 20px;
  padding-bottom: 20px; }

.nav > li > a {
  padding: 10px 15px; }

.navbar-default {
  background-color: #fff; }
  .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-default .navbar-nav > .active > a:focus {
    background-color: transparent; }
  .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-default .navbar-nav > .active > a:focus {
    background-color: transparent; }

.navbar-toggle {
  border-radius: 0;
  background-color: transparent !important; }

.navbar-default .navbar-nav > li > a {
  color: #626262; }

.nav-collapse.collapse {
  height: 100%  !important; }

.navbar-center, .navbar-center > li {
  float: none;
  display: inline-block;
  *display: inline;
  /* ie7 fix */
  *zoom: 1;
  /* hasLayout ie7 trigger */
  vertical-align: top; }

.navbar-nav li a {
  min-width: 50px; }

/*** General tablets and phones ***/
@media (max-width: 991px) {
  .header {
    padding: 0 15px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07); }
    .header .header-inner {
      text-align: center; }
      .header .header-inner .mark-email {
        left: 45px;
        position: absolute;
        top: 23px; }
      .header .header-inner .quickview-link {
        position: absolute;
        right: 0;
        top: 12px; } }

@media (max-width: 767px) {
  .header {
    height: 48px; }
    .header .header-inner {
      height: 48px; }
    .header .brand {
      margin-top: 0; }
    .header .project-list {
      margin-top: 15px !important; }
  .header .user-info-wrapper {
    display: none; }
  .header .search-link {
    height: 19px;
    width: 16px;
    overflow: hidden; } }

@media (max-width: 480px) {
  .header {
    width: 100%;
    height: 48px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07); }
    .header .header-inner {
      height: 48px;
      text-align: center; }
      .header .header-inner .toggle-email-sidebar {
        font-size: 16px;
        top: 12px; }
      .header .header-inner .mark-email {
        left: 35px;
        top: 14px; }
      .header .header-inner .quickview-link {
        top: 14px; }
    .header .dropdown-submenu {
      top: 12px; } }

.windows .header .search-link {
  font-size: 15px; }

/* END Header
------------------------------------
*/
/* text color
*/
/* padding
*/
/* z-index
*/
/* hover
*/
/* gap
*/
/* shadow
*/
/* text size
*/
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.on-drag-template-image .image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.ReactTable .rt-th.-cursor-pointer > div:after, #toast-container > .toast:before, .slick-prev:before,
.slick-next:before, .page-sidebar .sidebar-menu .menu-items > li > a > .arrow:before,
.page-sidebar .sidebar-menu .menu-items > li > span.sub-label > .arrow:before, [data-toggle-pin='sidebar'] > i:before {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons';
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

::placeholder {
  color: #000 !important;
  opacity: 0.3 !important; }

::-webkit-input-placeholder {
  color: #000 !important;
  opacity: 0.3 !important; }

:-ms-input-placeholder {
  color: #000 !important;
  opacity: 0.3 !important; }

::-moz-placeholder {
  color: #000 !important;
  opacity: 0.3 !important; }

/* Main Menu Sidebar
------------------------------------
*/
body.mobile .sidebar-menu {
  overflow: scroll;
  -webkit-overflow-scrolling: touch; }

body.mobile .sidebar-menu > ul {
  height: auto !important;
  overflow: visible !important;
  -webkit-overflow-scrolling: touch !important; }

body.mobile .page-sidebar .sidebar-menu .menu-items li:hover a {
  color: #ffffff; }

body.mobile .page-sidebar .sidebar-menu .menu-items li:hover .icon-thumbnail {
  color: #ffffff !important; }

body.mobile .page-sidebar .sidebar-menu .menu-items li.active > a, body.mobile .page-sidebar .sidebar-menu .menu-items li.open > a {
  color: #fff; }

body.mobile .page-sidebar .sidebar-menu .menu-items li.active > .icon-thumbnail, body.mobile .page-sidebar .sidebar-menu .menu-items li.open > .icon-thumbnail {
  color: #fff; }

body.mobile .drager {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

body.sidebar-visible .page-sidebar .scroll-element {
  visibility: visible; }

body.sidebar-visible .page-sidebar .menu-items .icon-thumbnail {
  -webkit-transform: translate3d(-14px, 0, 0);
  transform: translate3d(-14px, 0, 0);
  -ms-transform: translate(-14px, 0); }

body.sidebar-visible .page-sidebar .sidebar-header .sidebar-header-controls {
  -webkit-transform: translate3d(48px, 0, 0);
  transform: translate3d(48px, 0, 0);
  -ms-transform: translate(48px, 0); }

.page-sidebar {
  width: 280px;
  background-color: #000;
  z-index: 1000;
  left: -210px;
  position: fixed;
  bottom: 0;
  top: 0;
  right: auto;
  overflow: hidden;
  -webkit-transition: -webkit-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -moz-transition: -moz-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -o-transition: -o-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px; }
  .page-sidebar a,
  .page-sidebar button {
    color: #ffffff; }
    .page-sidebar a:hover, .page-sidebar a:active,
    .page-sidebar button:hover,
    .page-sidebar button:active {
      color: #fff;
      background-color: #ee8989 !important; }
      .page-sidebar a:hover:focus, .page-sidebar a:active:focus,
      .page-sidebar button:hover:focus,
      .page-sidebar button:active:focus {
        color: #fff; }
    .page-sidebar a:visited, .page-sidebar a:focus,
    .page-sidebar button:visited,
    .page-sidebar button:focus {
      color: #ffffff; }
  .page-sidebar button.sidebar-slide-toggle.active {
    color: #ffffff; }
    .page-sidebar button.sidebar-slide-toggle.active:focus {
      background: transparent; }
  .page-sidebar .scroll-element {
    visibility: hidden; }
  .page-sidebar .sidebar-header {
    /* Side-bar header */
    display: block;
    height: 60px;
    line-height: 60px;
    color: #fff;
    width: 100%;
    padding: 0 20px;
    padding-left: 15px;
    clear: both;
    z-index: 10;
    position: relative;
    border-bottom: 1px solid #423b3b; }
    .page-sidebar .sidebar-header .sidebar-header-controls {
      display: inline-block;
      -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
      transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
      -webkit-backface-visibility: hidden;
      margin-left: 30px;
      top: -4px;
      position: relative; }
      .page-sidebar .sidebar-header .sidebar-header-controls button:hover {
        background: transparent !important; }
    .page-sidebar .sidebar-header img {
      position: relative;
      top: -3px; }
    .page-sidebar .sidebar-header .sidebar-slide-toggle i {
      -webkit-transition: all 0.12s ease;
      transition: all 0.12s ease; }
    .page-sidebar .sidebar-header .sidebar-slide-toggle.active i {
      -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg); }
    .page-sidebar .sidebar-header .environment-name {
      color: #D0102C;
      font-weight: bold;
      font-size: 15px;
      position: absolute;
      transform: translate(120px, -2px); }
  .page-sidebar .close-sidebar {
    position: absolute;
    right: 19px;
    top: 14px;
    padding: 9px;
    z-index: 1; }
    .page-sidebar .close-sidebar > i {
      color: rgba(255, 255, 255, 0.57); }
  .page-sidebar .sidebar-overlay-slide {
    /* Side-bar Top Slider */
    width: 100%;
    height: 100%;
    background-color: black;
    display: block;
    z-index: 9;
    padding: 80px 20px 20px 20px; }
    .page-sidebar .sidebar-overlay-slide.from-top {
      top: -100%;
      position: absolute;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease; }
      .page-sidebar .sidebar-overlay-slide.from-top.show {
        -webkit-transform: translate(0, 100%);
        -ms-transform: translate(0, 100%);
        transform: translate(0, 100%); }
  .page-sidebar .sidebar-menu {
    /* Side-bar Menu */
    height: 100%;
    position: relative;
    width: 100%; }
    .page-sidebar .sidebar-menu .outer-tab-nav-section {
      display: inline-block;
      width: 45px;
      position: absolute;
      height: 100%;
      background-color: #0aa699 !important; }
    .page-sidebar .sidebar-menu .menu-items {
      /* Side-bar Menut Items */
      list-style: none;
      margin: 0;
      padding: 0;
      margin: 0;
      padding: 0;
      position: relative;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      height: calc(100% - 10px - 60px);
      width: 100%; }
      .page-sidebar .sidebar-menu .menu-items li:hover .icon-thumbnail, .page-sidebar .sidebar-menu .menu-items li.open .icon-thumbnail, .page-sidebar .sidebar-menu .menu-items li.active .icon-thumbnail {
        color: #fff; }
      .page-sidebar .sidebar-menu .menu-items li:hover a, .page-sidebar .sidebar-menu .menu-items li.open a, .page-sidebar .sidebar-menu .menu-items li.active a {
        color: #fff; }
      .page-sidebar .sidebar-menu .menu-items li > a,
      .page-sidebar .sidebar-menu .menu-items li > span.sub-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        color: #fff; }
        .page-sidebar .sidebar-menu .menu-items li > a span.title,
        .page-sidebar .sidebar-menu .menu-items li > span.sub-label span.title {
          position: relative;
          left: -15px; }
      .page-sidebar .sidebar-menu .menu-items > li {
        display: block;
        padding: 0;
        clear: right; }
        .page-sidebar .sidebar-menu .menu-items > li:after, .page-sidebar .sidebar-menu .menu-items > li:before {
          display: table;
          content: ' ';
          clear: both; }
        .page-sidebar .sidebar-menu .menu-items > li > a,
        .page-sidebar .sidebar-menu .menu-items > li > span.sub-label {
          font-family: Arial, sans-serif;
          display: flex;
          padding-left: 20px;
          align-items: center;
          min-height: 40px;
          line-height: 40px;
          font-size: 14px;
          clear: both;
          position: relative; }
          .page-sidebar .sidebar-menu .menu-items > li > a.open,
          .page-sidebar .sidebar-menu .menu-items > li > span.sub-label.open {
            background: #313131; }
          .page-sidebar .sidebar-menu .menu-items > li > a > .arrow:before,
          .page-sidebar .sidebar-menu .menu-items > li > span.sub-label > .arrow:before {
            float: right;
            display: inline;
            content: 'keyboard_arrow_down';
            font-size: 26px;
            height: auto;
            font-weight: 300;
            text-shadow: none;
            -webkit-transition: all 0.12s ease;
            transition: all 0.12s ease; }
          .page-sidebar .sidebar-menu .menu-items > li > a > .arrow.open:before,
          .page-sidebar .sidebar-menu .menu-items > li > span.sub-label > .arrow.open:before {
            -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg); }
          .page-sidebar .sidebar-menu .menu-items > li > a > .badge,
          .page-sidebar .sidebar-menu .menu-items > li > span.sub-label > .badge {
            margin-top: 12px; }
          .page-sidebar .sidebar-menu .menu-items > li > a > .title,
          .page-sidebar .sidebar-menu .menu-items > li > span.sub-label > .title {
            float: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden; }
          .page-sidebar .sidebar-menu .menu-items > li > a > .details,
          .page-sidebar .sidebar-menu .menu-items > li > span.sub-label > .details {
            font-size: 12px;
            opacity: 0.4;
            display: block;
            clear: both; }
          .page-sidebar .sidebar-menu .menu-items > li > a.detailed > .title,
          .page-sidebar .sidebar-menu .menu-items > li > span.sub-label.detailed > .title {
            line-height: 28px; }
          .page-sidebar .sidebar-menu .menu-items > li > a.detailed > .details,
          .page-sidebar .sidebar-menu .menu-items > li > span.sub-label.detailed > .details {
            line-height: 16px; }
        .page-sidebar .sidebar-menu .menu-items > li.active > ul.sub-menu, .page-sidebar .sidebar-menu .menu-items > li.open > ul.sub-menu {
          display: block; }
        .page-sidebar .sidebar-menu .menu-items > li > .icon-thumbnail:first-letter {
          text-transform: uppercase; }
        .page-sidebar .sidebar-menu .menu-items > li:first-child {
          margin-top: 10px; }
        .page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu > li {
          line-height: initial; }
          .page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu > li ul.sub-menu li {
            padding-left: 10px;
            padding-right: 3px; }
          .page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu > li ul.sub-menu .icon-thumbnail {
            width: 25px;
            height: 25px;
            line-height: 25px;
            font-size: 10px; }
        .page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu li > a > .arrow:before,
        .page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu li > span.sub-label > .arrow:before {
          float: right;
          margin-top: 1px;
          margin-right: 20px;
          display: inline;
          font-size: 16px;
          font-family: FontAwesome;
          height: auto;
          content: '\f104';
          font-weight: 300;
          text-shadow: none;
          -webkit-transition: all 0.12s ease;
          transition: all 0.12s ease; }
        .page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu li > a > .arrow.open:before,
        .page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu li > span.sub-label > .arrow.open:before {
          float: right;
          margin-top: 1px;
          margin-right: 18px;
          display: inline;
          font-family: FontAwesome;
          height: auto;
          font-size: 16px;
          -webkit-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
          font-weight: 300;
          text-shadow: none; }
        .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu {
          display: none;
          list-style: none;
          clear: both;
          margin: 0;
          padding: 0;
          background-color: black;
          background-color: #2A2A26; }
          .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
            background: none;
            padding-left: 0; }
            .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li:hover > .icon-thumbnail {
              color: #fff; }
            .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li > a,
            .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li > span.sub-label {
              font-size: 13px;
              font-family: Arial, sans-serif;
              white-space: normal;
              width: 100%;
              padding-left: 20px;
              margin-bottom: 0;
              margin-top: 3px; }
            .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li > a > span.title {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              line-height: 30px; }
            .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li .icon-thumbnail {
              line-height: 21px;
              margin: 0;
              font-size: 14px;
              display: flex;
              justify-content: center;
              align-items: center; }
    .page-sidebar .sidebar-menu .muted {
      color: #576775;
      opacity: 0.45; }
  .page-sidebar .icon-thumbnail [class^='bg-'],
  .page-sidebar [class*='bg-'] {
    color: #fff; }

[data-toggle-pin='sidebar'] > i:before {
  font-size: 15px !important;
  top: -4px;
  position: relative;
  content: 'panorama_fish_eye'; }

/* Sidebar icon holder
------------------------------------
*/
.page-sidebar .icon-thumbnail {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  position: relative;
  left: 0;
  color: #cccccc;
  font-size: 16px;
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  font-family: wf_segoe-ui_light, wf_segoe-ui_normal, "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-weight: bold; }
  .page-sidebar .icon-thumbnail > svg {
    width: auto;
    height: auto;
    display: inline; }
  .page-sidebar .icon-thumbnail i {
    display: inline-block;
    min-width: 1.8rem;
    font-size: 20px;
    line-height: 1.40625rem;
    vertical-align: middle; }

.page-sidebar .link-item {
  margin-bottom: 3px;
  align-items: center; }

.page-sidebar .link-item.active {
  background-color: #f66; }
  .page-sidebar .link-item.active span {
    color: #fff; }
    .page-sidebar .link-item.active span i {
      color: #fff; }
  .page-sidebar .link-item.active:hover {
    background-color: #f66 !important; }

.page-sidebar .button-item {
  padding-left: 20px !important;
  display: flex;
  align-items: center;
  border-radius: 0;
  margin: 3px 0; }
  .page-sidebar .button-item .title {
    position: relative;
    left: -15px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 30px;
    font-size: 14px;
    font-size: 13px; }

.page-sidebar .home-link .arrow {
  position: absolute;
  right: 20px; }

li.cursor.open a.link-item {
  margin-bottom: 0 !important; }

li.cursor.open .sub-menu li.section-link:first-child {
  padding-top: 2.5px !important; }
  li.cursor.open .sub-menu li.section-link:first-child a {
    margin-top: 0 !important; }

li.cursor.open .sub-menu li.section-link:last-child {
  padding: 0 0 3px 0 !important; }

li.cursor.open .sub-menu li.new-section {
  padding: 3px 0 !important; }

li.cursor.open .sub-menu .button-item {
  margin: 0; }

@media (max-width: 991px) {
  .page-sidebar .sidebar-header {
    display: flex !important;
    align-items: center;
    border: 0; }
    .page-sidebar .sidebar-header .environment-name {
      transform: translate(120px, 1px); }
  .page-sidebar .sidebar-menu .menu-items > li > a {
    padding-left: 5px !important; }
  .page-sidebar .brand {
    margin: 0;
    top: 0 !important; }
  .page-sidebar ul.sub-menu li {
    padding: 0 !important; }
    .page-sidebar ul.sub-menu li a {
      padding-left: 5px !important; }
      .page-sidebar ul.sub-menu li a span.title {
        left: 0 !important; }
  .page-sidebar ul.sub-menu .button-item {
    padding-left: 5px !important; }
    .page-sidebar ul.sub-menu .button-item span.title {
      left: 0 !important; }
  .fixed-header.sidebar-visible span.title, .fixed-header.menu-pin span.title, .fixed-header.sidebar-open span.title {
    left: 0 !important; }
  .fixed-header.sidebar-visible div.brand, .fixed-header.menu-pin div.brand, .fixed-header.sidebar-open div.brand {
    display: none !important; }
  .fixed-header.sidebar-visible .sidebar-header, .fixed-header.menu-pin .sidebar-header, .fixed-header.sidebar-open .sidebar-header {
    border: 0 !important; } }

.fixed-header .link-item, .fixed-header .button-item {
  display: block !important; }

.fixed-header span.icon-thumbnail {
  float: right;
  margin-right: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center; }

.fixed-header span.arrow {
  display: none; }

.fixed-header.sidebar-visible .link-item, .fixed-header.sidebar-visible .button-item, .fixed-header.menu-pin .link-item, .fixed-header.menu-pin .button-item, .fixed-header.sidebar-open .link-item, .fixed-header.sidebar-open .button-item {
  display: flex !important; }

.fixed-header.sidebar-visible span.icon-thumbnail, .fixed-header.menu-pin span.icon-thumbnail, .fixed-header.sidebar-open span.icon-thumbnail {
  float: none;
  margin-right: 0 !important;
  display: inline-block; }

.fixed-header.sidebar-visible span.arrow, .fixed-header.menu-pin span.arrow, .fixed-header.sidebar-open span.arrow {
  display: block; }

.fixed-header.sidebar-visible .sidebar-header, .fixed-header.menu-pin .sidebar-header, .fixed-header.sidebar-open .sidebar-header {
  border-bottom: 1px solid #423b3b; }

.sidebar-menu ::-webkit-scrollbar {
  display: none; }

.table-scenario .type-icon {
  border-color: #eee !important; }

.table-scenario td {
  position: relative; }

.table-scenario .action-bar {
  position: absolute;
  right: 10px;
  top: 10px;
  display: none; }

.table-scenario td:hover > .action-bar {
  display: block; }

.table-scenario .selected-edit {
  background-color: #BBDEFB; }

.scenario-sidebar {
  bottom: 0;
  right: 0;
  background-color: #f8f8f8;
  box-shadow: 0px 0px 4px #ccc;
  border: 1px solid #ddd;
  border-right: 0;
  border-bottom: 0;
  z-index: 999;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s; }
  .scenario-sidebar .header {
    height: 55px;
    background-color: #eee;
    position: absolute;
    top: 0;
    padding: 14px; }
  .scenario-sidebar .header .action {
    position: absolute;
    right: 10px;
    top: 8px; }
  .scenario-sidebar .header .action .btn-link {
    color: rgba(0, 0, 0, 0.87); }
  .scenario-sidebar .header h3 {
    font-size: 18px; }
  .scenario-sidebar .middle {
    height: calc(100% - 110px);
    margin-top: 55px;
    padding: 15px;
    overflow: auto; }
  .scenario-sidebar .footer {
    height: 55px;
    background-color: #eee;
    position: absolute;
    bottom: 0;
    padding: 10px;
    border-top: 1px solid #dddddd; }
  .scenario-sidebar .card-scenario-footer {
    background-color: #eee;
    border-top: 1px solid #ddd;
    padding: 10px;
    position: fixed;
    bottom: 0;
    right: -700px;
    z-index: 100;
    width: 700px;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s; }
  .scenario-sidebar .card + .card {
    margin-top: 15px; }
  .scenario-sidebar.in {
    right: 0; }
  .scenario-sidebar .card-scenario-editor {
    border: none;
    height: 100%;
    overflow: auto;
    display: block;
    position: relative;
    background-color: #f8f8f8; }
  .scenario-sidebar .card-scenario-editor > .card-content {
    background-color: #f8f8f8;
    padding-bottom: 60px; }

.scenario-editor-flow, .on-drag-progress {
  position: relative; }
  .scenario-editor-flow .action-flow, .on-drag-progress .action-flow {
    text-align: center; }
  .scenario-editor-flow .action-flow .btn-add-scenario-flow, .on-drag-progress .action-flow .btn-add-scenario-flow {
    margin: 10px auto;
    text-align: center; }
  .scenario-editor-flow .timeline-bar, .on-drag-progress .timeline-bar {
    position: absolute;
    left: 30px;
    top: 0;
    height: 100%;
    width: 5px;
    background-color: #ffffff;
    z-index: 0; }

/* accordion
*/
.accordion .card .card-header {
  padding: 10px !important; }

.btn.remove {
  padding: 0px 15px; }

.btn.remove span {
  font-size: 1.5rem;
  line-height: 27px;
  font-weight: 700;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }

.btn.remove:disabled span {
  opacity: .2; }

.scenario-editor-flow .scenario, .on-drag-progress {
  background-color: #e9e9e9;
  padding: 0 15px; }
  .scenario-editor-flow .scenario .drag-handle, .on-drag-progress .drag-handle {
    padding: 5px;
    background-color: #ffffff;
    position: absolute;
    top: -33px;
    border: 1px solid #dddddd; }
  .scenario-editor-flow .scenario .flow, .on-drag-progress .flow {
    position: relative; }
  .scenario-editor-flow .scenario .flow + .flow, .on-drag-progress .flow + .flow {
    margin-top: 20px; }
  .scenario-editor-flow .scenario .header-action, .on-drag-progress .header-action {
    position: absolute;
    right: 10px;
    top: 10px; }
  .scenario-editor-flow .scenario .header-action .btn-link, .on-drag-progress .header-action .btn-link {
    color: rgba(0, 0, 0, 0.54);
    padding: 5px; }
  .scenario-editor-flow .scenario .card, .on-drag-progress .card {
    margin-bottom: 0; }
  .scenario-editor-flow .scenario .card-flow, .on-drag-progress .card-flow {
    margin-left: 50px;
    width: auto; }
  .scenario-editor-flow .scenario .type-flow, .on-drag-progress .type-flow {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 5px;
    background-color: #ffffff; }

.uploader {
  position: relative;
  height: 100%;
  display: inline-block;
  overflow: hidden; }
  .uploader input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer; }

.media-uploader {
  background-color: #ffffff;
  border: 1px solid #dddddd; }
  .media-uploader .media-left {
    padding: 10px; }
  .media-uploader .media-body {
    background-color: #f8f8f8;
    border-left: 1px solid #dddddd;
    padding: 10px;
    min-height: 86px;
    overflow-y: auto; }
  .media-uploader .images .image {
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    width: 150px;
    height: 100px;
    position: relative;
    display: inline-block;
    margin: 0 5px 5px 0; }
  .media-uploader .images .image .tools {
    width: 100%;
    height: 100%;
    visibility: hidden; }
  .media-uploader .images .image .drag {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff; }
  .media-uploader .images .image:hover .drag {
    display: inline-block; }
  .media-uploader .images .image:hover .tools {
    visibility: visible; }
  .media-uploader .images .image .remove {
    position: absolute;
    right: 0;
    top: 0;
    padding: 2px 5px;
    border-radius: 0; }
  .media-uploader .videos .video {
    max-width: 400px;
    position: relative; }
  .media-uploader .videos .video button.remove {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000; }

.media-carousel {
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
  position: relative; }
  .media-carousel > .card {
    width: 350px;
    min-width: 350px;
    margin-right: 20px;
    overflow: hidden; }
  .media-carousel .badge-order {
    z-index: 100;
    background-color: #444;
    height: 50px;
    left: -25px;
    position: absolute;
    top: -25px;
    width: 50px;
    border-radius: 0;
    -webkit-transform: rotate(-45deg); }
    .media-carousel .badge-order span {
      color: #ffffff;
      left: -15px;
      top: 34px;
      position: absolute;
      width: 80px; }
  .media-carousel .card .card-content {
    padding: 0; }
  .media-carousel .card-content .image {
    position: relative;
    height: 200px;
    border-bottom: 1px solid #dddddd;
    background-color: #f8f8f8;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s; }
  .media-carousel .card-content .image .tools {
    width: 100%;
    height: 100%;
    visibility: hidden;
    position: absolute;
    z-index: 100; }
  .media-carousel .card-content .image:hover .tools {
    visibility: visible; }
  .media-carousel .image-uploaded {
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
    position: relative;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s; }
  .media-carousel.square-carousel .card-content .image {
    height: 348px; }
  .media-carousel.square-carousel .image-uploaded {
    height: 348px; }
  .media-carousel .card-content .image.active .uploader {
    display: none; }
  .media-carousel .card-content .image:hover .uploader {
    display: block; }
  .media-carousel .card-content .settings {
    padding: 10px; }
  .media-carousel .card-content .image .remove {
    position: absolute;
    right: 0;
    top: 0;
    padding: 2px 5px;
    border-radius: 0;
    z-index: 200; }
  .media-carousel .uploader {
    z-index: 9;
    width: 100%;
    height: 200px; }
  .media-carousel .uploader span.btn {
    background: transparent;
    border: none;
    width: 100%;
    height: 100%; }
  .media-carousel .image.active .uploader span.btn i {
    color: #ffffff; }

.modal-task {
  z-index: 1051; }
  .modal-task .card {
    cursor: pointer;
    margin-bottom: 20px; }
  .modal-task .card.selected {
    background-color: #2196f3; }
  .modal-task .card.selected .card-header,
  .modal-task .card.selected .card-content,
  .modal-task .card.selected .card-content i {
    color: #ffffff !important; }
  .modal-task .disabled {
    background-color: #f8f8f8;
    cursor: not-allowed; }
    .modal-task .disabled .card-title, .modal-task .disabled .card-content {
      color: #cccccc; }

.modal-flow .card .card-header {
  padding: 16px 16px 7px; }

.modal-flow .tab-content {
  padding: 15px 0px; }

.modal-flow .cover-preview {
  position: relative; }

.modal-flow .remove-cover {
  position: absolute;
  top: 0;
  right: 0; }

.modal-flow .modal-dialog {
  overflow: hidden; }

.modal-flow .modal-header > button.close {
  display: none; }

.modal-flow .modal-header {
  transition: all .2s !important;
  position: fixed;
  top: 0;
  width: inherit;
  height: 60px;
  z-index: 10;
  background-color: #eee;
  padding: 10px; }
  .modal-flow .modal-header h3 {
    margin: 0; }
  .modal-flow .modal-header .action {
    position: absolute;
    right: 10px;
    top: 10px; }

.modal-flow .modal-body {
  overflow: auto;
  padding-top: 80px;
  padding-bottom: 20px; }

.modal-flow .modal-footer {
  height: 50px;
  z-index: 10;
  background-color: #eee;
  padding: 5px 10px 5px 5px;
  width: inherit;
  border-top: 1px solid #ddd; }

.modal-flow .sc-name {
  font-family: wf_segoe-ui_light, wf_segoe-ui_normal, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-size: 1.375rem;
  padding: 0; }
  .modal-flow .sc-name:focus {
    background-color: transparent;
    border-bottom: 1px solid #FF6666; }

.modal-flow .social-brand-logo {
  width: 20px; }

.modal-flow .nav-tabs .nav-item .nav-link {
  opacity: .5; }

.modal-flow .nav-tabs .nav-item .nav-link.active {
  opacity: 1;
  border-top: 2px solid #FF6666;
  border-bottom: none; }

.modal-flow .nav-tabs .nav-item .nav-link.active:hover {
  background-color: #ffffff; }

.flow-body {
  overflow: auto; }

.modal-flow-editor {
  position: relative;
  background-color: #f8f8f8; }
  .modal-flow-editor .flow.header, .modal-flow-editor .flow.footer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%; }
  .modal-flow-editor .footer {
    bottom: 0;
    top: auto; }

/* drag and drop
*/
.on-drag-progress .scenario {
  padding: 0; }

.on-drag-progress .card .card-header {
  padding: 16px 16px 7px; }

.on-drag-progress,
.scenario-in-drag .on-drag-stay {
  margin-bottom: 30px;
  z-index: 1;
  position: relative; }
  .on-drag-progress .btn-add-scenario-flow,
  .scenario-in-drag .on-drag-stay .btn-add-scenario-flow {
    display: none; }

.scenario-in-drag .on-drag-stay {
  overflow: hidden !important; }

.on-drag-progress {
  background-color: transparent !important; }

.on-drag-progress, .on-drag-stay {
  user-select: none;
  padding: 0px;
  margin: 0px; }
  .on-drag-progress > div, .on-drag-stay > div {
    position: relative;
    z-index: 1; }

[data-react-beautiful-dnd-drag-handle][draggable="true"] {
  background-color: red !important; }

.on-drag-template-image .image {
  width: 150px;
  height: 100px; }

.on-drag-template-image .drag {
  background-color: #ffffff;
  display: inline-block; }

.on-drag-template-image .tools,
.on-drag-template-image .remove,
.on-drag-template-image .btn-edit-img {
  display: none; }

/* mentions input on editor
*/
.mention-wrapper {
  position: relative; }
  .mention-wrapper.is-invalid .mention-on-editor__control {
    border-color: #DC3546; }
  .mention-wrapper.is-invalid ~ .invalid-feedback {
    display: block; }
  .mention-wrapper .tooltips {
    position: absolute;
    right: 6px;
    top: 5px;
    z-index: 1; }
  .mention-wrapper i.tag {
    color: #dddddd; }

.mention-on-editor__control {
  border: 1px solid #dddddd; }

.mention-on-editor__highlighter {
  padding: 9px 32px 9px 15px;
  line-height: 20px;
  min-height: 80px;
  letter-spacing: normal;
  font-family: Arial, sans-serif !important; }
  .mention-on-editor__highlighter strong {
    background-color: #bbdefb !important; }

.mention-on-editor__input {
  padding: 9px 32px 9px 15px;
  font-family: Arial, sans-serif !important;
  min-height: 80px;
  outline: 0;
  border: 0; }

.mention-on-editor__suggestions {
  z-index: 99999999 !important;
  min-width: 160px; }

.mention-on-editor__suggestions__list {
  min-width: 160px;
  background-color: #fafafa;
  box-shadow: 0px 0px 5px rgba(98, 98, 98, 0.2); }

.mention-on-editor__suggestions__item {
  color: #626262 !important;
  border-bottom: 1px solid #dddddd;
  background-color: #fafafa;
  opacity: .6; }
  .mention-on-editor__suggestions__item:last-child {
    border-bottom: none; }

.mention-on-editor__suggestions__item--focused {
  background-color: #bbdefb;
  color: #000;
  opacity: 1; }

/* message tools
*/
.message-tools {
  background-color: #f8f8f8;
  border: 1px solid #dddddd;
  border-top: none;
  position: relative; }
  .message-tools li {
    border-right: 1px solid #dddddd;
    padding: 3px 5px;
    cursor: pointer; }
  .message-tools .tool-emoji {
    position: absolute;
    bottom: 100%;
    left: 0; }

.operation-btns {
  width: 205px; }

/* scenario list/tree toolbar
*/
.scenario-toolbar-select {
  border-radius: 2px; }
  .scenario-toolbar-select .react-select__value-container {
    line-height: 22px; }
    .scenario-toolbar-select .react-select__value-container label {
      font-family: 'Montserrat';
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 500; }
  .scenario-toolbar-select .react-select__value-container--is-multi {
    line-height: 20px; }
    .scenario-toolbar-select .react-select__value-container--is-multi .react-select__multi-value {
      line-height: 18px; }
  .scenario-toolbar-select .react-select__placeholder {
    font-size: 14px;
    margin-left: 5px; }
  .scenario-toolbar-select .react-select__control {
    align-items: flex-start;
    min-height: 35px;
    height: 35px; }
  .scenario-toolbar-select .react-select__dropdown-indicator {
    padding: 7px; }

.border-wrapper {
  background: url("~assets/img/vertical-line-9.png");
  height: 61px;
  position: relative;
  left: -9px;
  top: -30px;
  background-repeat: no-repeat;
  background-position: right; }

.task-grouper, .parent-task-connector {
  background: url("~assets/img/vertical-line-9.png") repeat-y right top;
  height: 60px;
  position: absolute;
  left: 28px;
  top: 29px;
  z-index: 1; }

.border-wrapper-el {
  background: url("~assets/img/el-line-6.png") no-repeat right top;
  height: 22px;
  position: absolute; }
  .border-wrapper-el.long-el {
    left: 41px;
    top: 10px; }
  .border-wrapper-el.short-el {
    left: 12px;
    top: 10px; }

.border-wrapper-hor {
  background: url("~assets/img/horizontal-line-6.png") no-repeat right top;
  height: 22px;
  position: absolute;
  left: 53px;
  top: 10px; }

.grp-icon {
  position: absolute;
  left: -15px;
  top: 35px;
  background: #ffffff; }

.expanded-material {
  color: #666 !important; }

.collapsed-material {
  border: 1px solid #666;
  border-radius: 1px;
  font-size: 11px !important;
  background: #ffffff; }

.BtnGrp {
  color: #FFF;
  font-size: 11px;
  font-family: 'Montserrat';
  position: relative;
  left: 22px;
  top: 0px;
  display: inline-block;
  width: 10px; }

.cp_tree-table_row, .cp_tree-table_column {
  overflow: visible !important; }

.cp_tree-table_cell .main-title > i.material-icons {
  padding: 0px 5px;
  background-color: #ffffff; }

/* Bot settings */
.settingOptions {
  background: #eeeeee; }

/* scenario conditional & declarations
*/
.declare-conditional {
  position: relative; }
  .declare-conditional .wrapper {
    position: relative; }
  .declare-conditional .wrapper.off {
    max-height: 100px;
    overflow: hidden; }
  .declare-conditional .wrapper .arrow {
    position: absolute;
    bottom: 15px;
    width: 100%;
    height: 20px;
    text-align: center;
    display: none;
    z-index: 100; }
  .declare-conditional .wrapper.off .arrow {
    display: block; }
  .declare-conditional .toggle-tool {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100; }
  .declare-conditional h5 {
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    margin-top: 0;
    padding-top: 10px; }
  .declare-conditional h5 span {
    text-transform: none; }
  .declare-conditional .section {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 10px;
    color: #666666; }
  .declare-conditional span.varcode {
    background-color: #f8f8f8;
    color: #2196f3;
    padding: 3px 10px;
    border-radius: 10px;
    margin: 0 5px;
    border: 1px solid #2196f3; }
  .declare-conditional .declare, .declare-conditional .rule {
    margin-bottom: 10px;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 10px; }
    .declare-conditional .declare:last-child, .declare-conditional .rule:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none; }

/*------------------------------------------------------------------
[22. Login]
*/
.login-wrapper {
  height: 100%;
  background-color: #FF6666; }
  .login-wrapper > * {
    height: 100%; }
  .login-wrapper .background {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover !important;
    background-position: top center !important;
    background-repeat: no-repeat !important; }
    .login-wrapper .background .backdrop {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.2);
      top: 0;
      left: 0;
      z-index: 100; }
    .login-wrapper .background > img {
      height: 100%;
      opacity: 0.6; }
  .login-wrapper .login-container {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    float: right; }
  .login-wrapper .bg-caption {
    width: 100%;
    position: relative;
    z-index: 200;
    background-image: -webkit-gradient(linear, left 0%, left 100%, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.3)));
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.1), 0%, rgba(0, 0, 0, 0.3), 100%);
    background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 100%);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1A000000', endColorstr='#4D000000', GradientType=0); }

.register-container {
  width: 550px;
  margin: auto;
  height: 100%; }

/* Responsive handlers : Login
------------------------------------
*/
@media (max-width: 768px) {
  .login-wrapper .login-container {
    width: 100%; }
  .register-container {
    width: 100%;
    padding: 15px; } }

@media only screen and (max-width: 321px) {
  .login-wrapper .login-container {
    width: 100%; } }

/*------------------------------------------------------------------
[29. Print]
*/
@media print {
  .header,
  .page-sidebar,
  .quickview-wrapper,
  .overlay {
    display: none; }
  .page-container {
    padding-left: 0; }
  .page-content-wrapper .content {
    border-top: 0; }
    .page-content-wrapper .content .card {
      border: none; }
      .page-content-wrapper .content .card .card-body {
        padding: 0; }
  [class^='padding-'],
  [class*='padding-'],
  .table tbody tr td {
    padding: 10px; } }

/* text color
*/
/* padding
*/
/* z-index
*/
/* hover
*/
/* gap
*/
/* shadow
*/
/* text size
*/
.dragged {
  opacity: 0.7;
  filter: alpha(opacity=70);
  cursor: move;
  border: 2px dashed #ff5722; }

.placeholder {
  opacity: 0.5;
  filter: alpha(opacity=50);
  background: #f8f8f8;
  border: 2px dashed #0da899; }

.switch {
  border: 1px solid #dddddd;
  width: 50px;
  height: 26px;
  border-radius: 13px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle; }

.switch-toggle {
  border: 1px solid #f8f8f8;
  box-shadow: 1px 1px 1px #ccc;
  width: 25px;
  height: 24px;
  left: 0;
  border-radius: 12px;
  background: #ffffff;
  position: relative;
  transition: left .2s ease-in-out; }

.switch.on {
  background: #FF6666; }

.switch.on .switch-toggle {
  left: 23px; }

.switch.disabled {
  cursor: not-allowed;
  background: #f8f8f8; }
  .switch.disabled .switch-toggle {
    background: #f8f8f8; }

.switch.switch-md {
  width: 40px;
  height: 22px;
  border-radius: 11px; }
  .switch.switch-md .switch-toggle {
    width: 20px;
    height: 19px;
    border-radius: 9px; }
  .switch.switch-md.on .switch-toggle {
    left: 18px; }

.visible-xlg {
  display: none; }

.hidden-xlg {
  display: block; }

.mfa-sms-form-step2 {
  max-width: 350px; }

.inline-flex {
  display: inline-flex; }

/*** Large screens ***/
@media (min-width: 1824px) {
  .visible-xlg {
    display: block !important; }
  .hidden-xlg {
    display: none !important; }
  .quickview-wrapper .tab-content #quickview-notes > .inner {
    width: 570px; }
  .container-fluid.container-fixed-lg {
    margin-right: auto;
    margin-left: auto; }
  .menu-pin .container-fluid.container-fixed-lg {
    width: 1450px; }
  /*.visible-xlg {
		display: block !important;
	}
	.hidden-xlg {
		display: none !important;
	}*/
  .col-xlg-1, .col-xlg-2, .col-xlg-3, .col-xlg-4, .col-xlg-5, .col-xlg-6, .col-xlg-7, .col-xlg-8, .col-xlg-9, .col-xlg-10, .col-xlg-11 {
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative; }
  .col-xlg-12 {
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    flex: 0 0 100%;
    max-width: 100%; }
  .col-xlg-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%; }
  .col-xlg-10 {
    width: 83.3333%;
    flex: 0 0 83.3333%;
    max-width: 83.3333%; }
  .col-xlg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xlg-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%; }
  .col-xlg-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%; }
  .col-xlg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xlg-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%; }
  .col-xlg-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%; }
  .col-xlg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xlg-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%; }
  .col-xlg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xlg-pull-12 {
    right: 100%; }
  .col-xlg-pull-11 {
    right: 91.6667%; }
  .col-xlg-pull-10 {
    right: 83.3333%; }
  .col-xlg-pull-9 {
    right: 75%; }
  .col-xlg-pull-8 {
    right: 66.6667%; }
  .col-xlg-pull-7 {
    right: 58.3333%; }
  .col-xlg-pull-6 {
    right: 50%; }
  .col-xlg-pull-5 {
    right: 41.6667%; }
  .col-xlg-pull-4 {
    right: 33.3333%; }
  .col-xlg-pull-3 {
    right: 25%; }
  .col-xlg-pull-2 {
    right: 16.6667%; }
  .col-xlg-pull-1 {
    right: 8.33333%; }
  .col-xlg-pull-0 {
    right: 0; }
  .col-xlg-push-12 {
    left: 100%; }
  .col-xlg-push-11 {
    left: 91.6667%; }
  .col-xlg-push-10 {
    left: 83.3333%; }
  .col-xlg-push-9 {
    left: 75%; }
  .col-xlg-push-8 {
    left: 66.6667%; }
  .col-xlg-push-7 {
    left: 58.3333%; }
  .col-xlg-push-6 {
    left: 50%; }
  .col-xlg-push-5 {
    left: 41.6667%; }
  .col-xlg-push-4 {
    left: 33.3333%; }
  .col-xlg-push-3 {
    left: 25%; }
  .col-xlg-push-2 {
    left: 16.6667%; }
  .col-xlg-push-1 {
    left: 8.33333%; }
  .col-xlg-push-0 {
    left: 0; }
  .col-xlg-offset-12 {
    margin-left: 100%; }
  .col-xlg-offset-11 {
    margin-left: 91.6667%; }
  .col-xlg-offset-10 {
    margin-left: 83.3333%; }
  .col-xlg-offset-9 {
    margin-left: 75%; }
  .col-xlg-offset-8 {
    margin-left: 66.6667%; }
  .col-xlg-offset-7 {
    margin-left: 58.3333%; }
  .col-xlg-offset-6 {
    margin-left: 50%; }
  .col-xlg-offset-5 {
    margin-left: 41.6667%; }
  .col-xlg-offset-4 {
    margin-left: 33.3333%; }
  .col-xlg-offset-3 {
    margin-left: 25%; }
  .col-xlg-offset-2 {
    margin-left: 16.6667%; }
  .col-xlg-offset-1 {
    margin-left: 8.33333%; }
  .col-xlg-offset-0 {
    margin-left: 0; } }

/*** Desktops ***/
/*** Medium Size Screen ***/
@media only screen and (max-width: 1400px) {
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu {
    bottom: 50px; }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-widgets {
    display: none; }
  .footer-widget {
    padding: 11px 21px !important; } }

@media only screen and (max-width: 1199px) {
  .visible-xl-down {
    display: block !important; } }

/*** Desktops & Laptops ***/
@media only screen and (min-width: 992px) {
  body.ie9.menu-pin .page-sidebar {
    transform: none !important;
    -webkit-transform: none !important;
    -ms-transform: none !important; }
  body.menu-pin .header .brand {
    width: 245px; }
    body.menu-pin .header .brand span {
      display: none; }
  body.menu-pin .page-container {
    padding-left: 0; }
    body.menu-pin .page-container .page-content-wrapper .content {
      padding-left: 250px; }
    body.menu-pin .page-container .page-content-wrapper .footer {
      left: 250px; }
  body.menu-pin [data-toggle-pin="sidebar"] > i:before {
    content: "adjust"; }
  body.menu-pin .page-sidebar {
    transform: translate(210px, 0) !important;
    -webkit-transform: translate(210px, 0) !important;
    -ms-transform: translate(210px, 0) !important;
    width: 250px; }
    body.menu-pin .page-sidebar .sidebar-header .sidebar-header-controls {
      transform: translateX(18px);
      -webkit-transform: translateX(18px); }
    body.menu-pin .page-sidebar .menu-items .icon-thumbnail {
      transform: translate3d(-14px, 0, 0);
      -webkit-transform: -webkit-translate3d(-14px, 0, 0); }
  body.menu-behind .page-sidebar {
    z-index: 799; }
  body.menu-behind .header .brand {
    width: 200px;
    text-align: left;
    padding-left: 20px; }
  body.box-layout {
    background-color: #fff; }
    body.box-layout > .container, body.box-layout > .full-height > .container {
      height: 100%;
      padding: 0;
      background-color: #fafafa; }
    body.box-layout .header {
      background-color: transparent;
      border: 0;
      padding: 0; }
      body.box-layout .header > .container {
        background-color: #fff;
        border-bottom: 1px solid rgba(230, 230, 230, 0.7);
        padding: 0 20px 0 0;
        height: 100%; }
    body.box-layout .page-sidebar {
      left: auto;
      transform: none !important;
      -webkit-transform: none !important; }
    body.box-layout .page-container .page-content-wrapper .footer {
      width: auto; }
  .header .brand {
    position: relative; }
  .header .user-info-wrapper .user-details .user-name {
    font-size: 16px; }
  .header .user-info-wrapper .user-details .user-other {
    font-size: 10px; }
  .header .user-info-wrapper .user-pic {
    position: relative;
    top: -6px; }
  .notification-panel {
    width: 400px; } }

/*** General Small Screen Desktops ***/
/*** General tablets and phones ***/
@media (max-width: 991px) {
  .hidden-lg-down {
    display: none !important; }
  .page-container {
    padding-left: 0; }
  .d-flex-lg-down {
    display: flex; }
  .justify-between-lg-down {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  body.sidebar-open .page-container {
    -webkit-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
    -ms-transform: translate(250px, 0);
    overflow: hidden;
    position: fixed; }
  body.sidebar-open .push-on-sidebar-open {
    -webkit-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
    -ms-transform: translate(250px, 0);
    overflow: hidden; }
  body.box-layout > .container {
    padding: 0;
    height: 100%; }
  body.box-layout .header > .container {
    padding: 0; }
    body.box-layout .header > .container .pull-right .sm-action-bar {
      right: 0; }
  body.horizontal-app-menu .header > .container {
    padding: 0; }
  body.horizontal-app-menu .secondary-sidebar {
    margin-left: 0; }
  .sm-action-bar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10; }
    .sm-action-bar > a {
      padding: 10px;
      display: inline-block; }
  .pace .pace-activity {
    top: 60px; }
  .page-sidebar {
    width: 250px;
    z-index: auto;
    left: 0 !important;
    -webkit-transform: translate3d(0, 0px, 0px) !important;
    transform: translate3d(0, 0px, 0px) !important; }
    .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
      padding: 0px 30px 0 36px; }
  .page-container {
    padding-left: 0;
    position: relative;
    transition: transform .25s ease;
    -webkit-transition: -webkit-transform .25s ease; }
    .page-container .page-content-wrapper .content {
      overflow-x: hidden;
      height: auto;
      padding-top: 120px !important; }
    .page-container .page-content-wrapper .content.p-t-0 {
      padding-top: 0 !important; }
    .page-container .header .toggle-sidebar {
      z-index: 1000; }
    .page-container .header .project-list {
      background: #fafafa !important;
      z-index: 900;
      margin-top: 0 !important;
      padding-top: 15px;
      padding-bottom: 15px; }
    .page-container .header .brand {
      height: 60px;
      text-align: center;
      padding: 0; }
    .page-container .header .middle-brand {
      position: absolute;
      left: 0; }
  .middle-brand {
    width: 100%; }
  .project-list {
    margin-top: 25px !important;
    border-left: none !important;
    width: 70% !important; }
  .project-bar {
    background-color: #f8f8f8; }
  .brand {
    display: block !important;
    margin-left: auto;
    margin-right: auto; }
  .icon-thumbnail {
    margin-right: 24px; }
  .page-sidebar {
    display: none;
    z-index: auto; }
    .page-sidebar.visible {
      display: block; }
    .page-sidebar .page-sidebar-inner {
      z-index: 1;
      left: 0 !important;
      width: 260px; }
      .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu {
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        top: 40px;
        bottom: 0; }
        .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu ul > li > a > .badge {
          display: inline-block; }
  .secondary-sidebar {
    float: none;
    height: auto;
    left: 50%;
    margin-left: -125px;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 9999;
    display: none;
    border-radius: 10px; }
    .secondary-sidebar .btn-compose {
      display: none; }
  .inner-content, .horizontal-app-menu .inner-content {
    margin: 0; }
  .breadcrumb {
    padding-left: 15px;
    padding-right: 15px; }
  .copyright {
    padding-left: 15px;
    padding-right: 15px; }
  body > .pgn-wrapper[data-position="top"] {
    top: 48px;
    left: 0; }
  body > .pgn-wrapper[data-position="bottom"] {
    left: 0; }
  body > .pgn-wrapper[data-position$='-left'] {
    left: 20px;
    right: auto; }
  .sm-table {
    display: table;
    width: 100%; }
  .user-profile-wrapper {
    position: absolute;
    right: 50px;
    top: -9px; }
  .container {
    width: 100%; }
  .table-responsive-block table, .table-responsive-block thead, .table-responsive-block tbody, .table-responsive-block th, .table-responsive-block td, .table-responsive-block tr {
    display: block; }
  .table-responsive-block thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px; }
  .table-responsive-block tr {
    border: 1px solid rgba(230, 230, 230, 0.7);
    margin-bottom: 15px; }
  .table-responsive-block td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left; }
    .table-responsive-block td:last-child {
      border-bottom: 0; }
    .table-responsive-block td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;
      font-weight: bold; }
    .table-responsive-block td:before {
      content: attr(data-title); }
  .kill-pull-md-down {
    position: relative;
    display: block;
    width: inherit; }
    .kill-pull-md-down .toggle-secondary-sidebar {
      display: inline-block;
      font-size: 18px; }
  .nav-tabs-header.nav-tabs-linetriangle {
    height: 61px; }
  .overlay {
    padding-left: 15px; } }

/* Landscape view of all tablet devices */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .page-sidebar .sidebar-menu .menu-items ul {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000; }
  body.box-layout > .container {
    width: 100%; }
  .inner-content {
    margin-left: 0; }
  .secondary-sidebar {
    float: none;
    height: auto;
    left: 50%;
    margin-left: -155px;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 9999;
    display: none;
    border-radius: 10px; }
    .secondary-sidebar .btn-compose {
      display: none; }
  .horizontal-app-menu .secondary-sidebar {
    display: inline-block;
    position: relative;
    top: inherit;
    left: inherit;
    right: inherit;
    padding: 20px 0;
    border-radius: 0;
    float: left;
    height: 100%; }
  .split-details {
    margin-left: 0; }
  .toggle-secondary-sidebar {
    font-size: 18px;
    display: block;
    font-size: 18px;
    left: 50%;
    margin-left: -36px;
    position: absolute; }
    .toggle-secondary-sidebar ~ .brand {
      display: none !important; } }

@media (max-width: 991px) {
  .sm-b-r, .sm-b-l, .sm-b-t, .sm-b-b {
    border-width: 0; }
  .sm-b-r {
    border-right-width: 1px; }
  .sm-b-l {
    border-left-width: 1px; }
  .sm-b-t {
    border-top-width: 1px; }
  .sm-b-b {
    border-bottom-width: 1px; }
  .sidebar-menu .icon-thumbnail {
    margin-right: 15px; } }

@media (min-width: 1200px) {
  .row-same-height {
    overflow: hidden; }
    .row-same-height > [class*="col-lg"] {
      margin-bottom: -99999px;
      padding-bottom: 99999px; } }

@media (min-width: 992px) {
  .row-same-height {
    overflow: hidden; }
    .row-same-height > [class*="col-md"] {
      margin-bottom: -99999px;
      padding-bottom: 99999px; } }

@media (min-width: 768px) {
  .row-same-height {
    overflow: hidden; }
    .row-same-height > [class*="col-sm"] {
      margin-bottom: -99999px;
      padding-bottom: 99999px; }
  .box-layout .container .jumbotron, .container-fluid .jumbotron {
    padding: 0; }
  .d-flex-md-up {
    display: flex; }
  .navbar-toggle {
    display: none; }
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important; }
  .dataTables_wrapper.form-inline .checkbox input[type=checkbox], .form-inline .radio input[type=radio] {
    position: absolute; }
  .justify-end-md-up {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end; } }

@media (min-width: 700px) {
  .dd {
    float: none; } }

/* Portrait view of all tablet devices */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .page-sidebar .sidebar-menu .menu-items > li > a {
    padding-left: 27px; }
  .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
    padding: 0px 27px 0 31px; }
  .icon-thumbnail {
    margin-right: 21px; } }

@media (max-width: 991px) and (min-width: 768px) {
  .visible-sm {
    display: block; }
  .hidden-sm {
    display: none; }
  .visible-sm-inline-block {
    display: inline-block !important; }
  .md-p-t-0 {
    margin-top: 0px !important; }
  .md-p-r-0 {
    margin-right: 0px !important; }
  .md-p-l-0 {
    margin-left: 0px !important; }
  .md-p-b-0 {
    margin-bottom: 0px !important; }
  .md-p-t-5 {
    margin-top: 5px !important; }
  .md-p-r-5 {
    margin-right: 5px !important; }
  .md-p-l-5 {
    margin-left: 5px !important; }
  .md-p-b-5 {
    margin-bottom: 5px !important; }
  .md-p-t-10 {
    margin-top: 10px !important; }
  .md-p-r-10 {
    margin-right: 10px !important; }
  .md-p-l-10 {
    margin-left: 10px !important; }
  .md-p-b-10 {
    margin-bottom: 10px !important; }
  .md-p-t-15 {
    margin-top: 15px !important; }
  .md-p-r-15 {
    margin-right: 15px !important; }
  .md-p-l-15 {
    margin-left: 15px !important; }
  .md-p-b-15 {
    margin-bottom: 15px !important; }
  .md-p-t-0 {
    margin-top: 0px !important; }
  .md-p-r-0 {
    margin-right: 0px !important; }
  .md-p-l-0 {
    margin-left: 0px !important; }
  .md-p-b-0 {
    margin-bottom: 0px !important; }
  .md-p-t-5 {
    margin-top: 5px !important; }
  .md-p-r-5 {
    margin-right: 5px !important; }
  .md-p-l-5 {
    margin-left: 5px !important; }
  .md-p-b-5 {
    margin-bottom: 5px !important; }
  .md-p-t-10 {
    margin-top: 10px !important; }
  .md-p-r-10 {
    margin-right: 10px !important; }
  .md-p-l-10 {
    margin-left: 10px !important; }
  .md-p-b-10 {
    margin-bottom: 10px !important; }
  .md-p-t-15 {
    margin-top: 15px !important; }
  .md-p-r-15 {
    margin-right: 15px !important; }
  .md-p-l-15 {
    margin-left: 15px !important; }
  .md-p-b-15 {
    margin-bottom: 15px !important; } }

@media (max-width: 979px) {
  .toggle-secondary-sidebar {
    font-size: 18px;
    position: static;
    display: block; }
    .toggle-secondary-sidebar ~ .brand {
      display: none !important; }
  .secondary-sidebar {
    float: none;
    height: auto;
    left: 50%;
    margin-left: -125px;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 9999;
    display: none;
    border-radius: 10px; }
    .secondary-sidebar .btn-compose {
      display: none; }
  .split-view .split-list {
    width: 100%;
    height: auto;
    bottom: 50px;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 1000;
    float: none; }
    .split-view .split-list .item {
      padding: 8px; }
      .split-view .split-list .item .inline {
        width: 188px; }
  .split-view .split-details {
    margin-left: 0;
    width: 100%; }
    .split-view .split-details .email-content-wrapper {
      overflow: auto;
      padding: 0;
      height: calc(100% - 50px); }
      .split-view .split-details .email-content-wrapper .email-content {
        width: 90%; }
  .compose-wrapper {
    display: block !important; }
    .compose-wrapper .btn-compose {
      display: block !important; } }

@media (max-width: 911px) {
  .right-nav-btns {
    width: 100%; }
    .right-nav-btns .search-box {
      width: 80% !important;
      margin-left: 0; } }

@media (max-width: 767px) {
  .page-container .header .brand {
    height: 48px; }
  .page-container .page-content-wrapper .content {
    padding-top: 48px;
    padding-bottom: 130px; }
  .page-sidebar .sidebar-header {
    padding: 0 12px; }
  .page-sidebar .sidebar-menu .menu-items > li > a {
    padding-left: 20px; }
  .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
    padding: 0px 25px 0 28px; }
  .icon-thumbnail {
    margin-right: 20px; }
  .secondary-sidebar {
    top: 48px; }
  .split-details {
    margin-left: 0; }
  .email-composer {
    padding-left: 30px;
    padding-right: 30px; }
  .sm-pull-bottom, .sm-pull-up {
    position: relative !important; }
  .sm-p-t-0 {
    margin-top: 0px !important; }
  .sm-p-r-0 {
    margin-right: 0px !important; }
  .sm-p-l-0 {
    margin-left: 0px !important; }
  .sm-p-b-0 {
    margin-bottom: 0px !important; }
  .sm-p-t-5 {
    margin-top: 5px !important; }
  .sm-p-r-5 {
    margin-right: 5px !important; }
  .sm-p-l-5 {
    margin-left: 5px !important; }
  .sm-p-b-5 {
    margin-bottom: 5px !important; }
  .sm-p-t-10 {
    margin-top: 10px !important; }
  .sm-p-r-10 {
    margin-right: 10px !important; }
  .sm-p-l-10 {
    margin-left: 10px !important; }
  .sm-p-b-10 {
    margin-bottom: 10px !important; }
  .sm-p-t-15 {
    margin-top: 15px !important; }
  .sm-p-r-15 {
    margin-right: 15px !important; }
  .sm-p-l-15 {
    margin-left: 15px !important; }
  .sm-p-b-15 {
    margin-bottom: 15px !important; }
  .sm-p-t-20 {
    margin-top: 20px !important; }
  .sm-p-r-20 {
    margin-right: 20px !important; }
  .sm-p-l-20 {
    margin-left: 20px !important; }
  .sm-p-b-20 {
    margin-bottom: 20px !important; }
  .sm-p-t-25 {
    margin-top: 25px !important; }
  .sm-p-r-25 {
    margin-right: 25px !important; }
  .sm-p-l-25 {
    margin-left: 25px !important; }
  .sm-p-b-25 {
    margin-bottom: 25px !important; }
  .sm-p-t-30 {
    margin-top: 30px !important; }
  .sm-p-r-30 {
    margin-right: 30px !important; }
  .sm-p-l-30 {
    margin-left: 30px !important; }
  .sm-p-b-30 {
    margin-bottom: 30px !important; }
  .sm-p-t-35 {
    margin-top: 35px !important; }
  .sm-p-r-35 {
    margin-right: 35px !important; }
  .sm-p-l-35 {
    margin-left: 35px !important; }
  .sm-p-b-35 {
    margin-bottom: 35px !important; }
  .sm-p-t-40 {
    margin-top: 40px !important; }
  .sm-p-r-40 {
    margin-right: 40px !important; }
  .sm-p-l-40 {
    margin-left: 40px !important; }
  .sm-p-b-40 {
    margin-bottom: 40px !important; }
  .sm-p-t-45 {
    margin-top: 45px !important; }
  .sm-p-r-45 {
    margin-right: 45px !important; }
  .sm-p-l-45 {
    margin-left: 45px !important; }
  .sm-p-b-45 {
    margin-bottom: 45px !important; }
  .sm-p-t-50 {
    margin-top: 50px !important; }
  .sm-p-r-50 {
    margin-right: 50px !important; }
  .sm-p-l-50 {
    margin-left: 50px !important; }
  .sm-p-b-50 {
    margin-bottom: 50px !important; }
  .sm-p-t-0 {
    margin-top: 0px !important; }
  .sm-p-r-0 {
    margin-right: 0px !important; }
  .sm-p-l-0 {
    margin-left: 0px !important; }
  .sm-p-b-0 {
    margin-bottom: 0px !important; }
  .sm-p-t-5 {
    margin-top: 5px !important; }
  .sm-p-r-5 {
    margin-right: 5px !important; }
  .sm-p-l-5 {
    margin-left: 5px !important; }
  .sm-p-b-5 {
    margin-bottom: 5px !important; }
  .sm-p-t-10 {
    margin-top: 10px !important; }
  .sm-p-r-10 {
    margin-right: 10px !important; }
  .sm-p-l-10 {
    margin-left: 10px !important; }
  .sm-p-b-10 {
    margin-bottom: 10px !important; }
  .sm-p-t-15 {
    margin-top: 15px !important; }
  .sm-p-r-15 {
    margin-right: 15px !important; }
  .sm-p-l-15 {
    margin-left: 15px !important; }
  .sm-p-b-15 {
    margin-bottom: 15px !important; }
  .sm-p-t-20 {
    margin-top: 20px !important; }
  .sm-p-r-20 {
    margin-right: 20px !important; }
  .sm-p-l-20 {
    margin-left: 20px !important; }
  .sm-p-b-20 {
    margin-bottom: 20px !important; }
  .sm-p-t-25 {
    margin-top: 25px !important; }
  .sm-p-r-25 {
    margin-right: 25px !important; }
  .sm-p-l-25 {
    margin-left: 25px !important; }
  .sm-p-b-25 {
    margin-bottom: 25px !important; }
  .sm-p-t-30 {
    margin-top: 30px !important; }
  .sm-p-r-30 {
    margin-right: 30px !important; }
  .sm-p-l-30 {
    margin-left: 30px !important; }
  .sm-p-b-30 {
    margin-bottom: 30px !important; }
  .sm-p-t-35 {
    margin-top: 35px !important; }
  .sm-p-r-35 {
    margin-right: 35px !important; }
  .sm-p-l-35 {
    margin-left: 35px !important; }
  .sm-p-b-35 {
    margin-bottom: 35px !important; }
  .sm-p-t-40 {
    margin-top: 40px !important; }
  .sm-p-r-40 {
    margin-right: 40px !important; }
  .sm-p-l-40 {
    margin-left: 40px !important; }
  .sm-p-b-40 {
    margin-bottom: 40px !important; }
  .sm-p-t-45 {
    margin-top: 45px !important; }
  .sm-p-r-45 {
    margin-right: 45px !important; }
  .sm-p-l-45 {
    margin-left: 45px !important; }
  .sm-p-b-45 {
    margin-bottom: 45px !important; }
  .sm-p-t-50 {
    margin-top: 50px !important; }
  .sm-p-r-50 {
    margin-right: 50px !important; }
  .sm-p-l-50 {
    margin-left: 50px !important; }
  .sm-p-b-50 {
    margin-bottom: 50px !important; }
  .sm-no-margin {
    margin: 0px; }
  .sm-no-padding {
    padding: 0px; }
  .sm-text-right {
    text-align: right !important; }
  .sm-text-left {
    text-align: left !important; }
  .sm-text-center {
    text-align: center !important; }
  .sm-pull-right {
    float: right !important; }
  .sm-pull-left {
    float: left !important; }
  .sm-pull-reset {
    float: none !important; }
  .sm-block {
    display: block; }
  .sm-image-responsive-height {
    width: 100%;
    height: auto; }
  .email-composer {
    padding-left: 30px;
    padding-right: 30px; }
  .sm-padding-10 {
    padding: 10px !important; }
  .lock-box {
    align-items: flex-start;
    height: auto; }
  .login-box {
    padding-right: 15px;
    padding-left: 15px; }
  .visible-xs-inline-block {
    display: inline-block !important; }
  .sm-padding-10 {
    padding: 10px !important; }
  .lock-box {
    align-items: flex-start;
    height: auto; }
  .login-box {
    padding-right: 15px;
    padding-left: 15px; }
  .visible-xs-inline-block {
    display: inline-block !important; }
  .nav-tabs-linetriangle.nav-cust {
    flex-direction: column; }
  .nav-tabs-linetriangle li a.active:after, .nav-tabs-linetriangle li a.active:before {
    display: none; }
  .sm-vh-25 {
    height: 25vh; }
  .sm-vh-50 {
    height: 50vh; }
  .sm-vh-75 {
    height: 75vh; }
  .sm-vh-100 {
    height: 100vh; }
  .horizontal-app-menu .page-container .page-content-wrapper .content > .container {
    padding-left: 0;
    padding-right: 0; }
  .summernote-wrapper .btn-group {
    -ms-flex-flow: wrap;
    flex-flow: wrap; }
  .mfa-sms-form-step2 {
    max-width: 100%; }
  .xs-full-width {
    width: 100% !important; } }

/*** Phones ***/
@media (max-width: 480px) {
  body {
    width: 100%; }
    body #overlay-search {
      font-size: 48px;
      height: 118px;
      line-height: 46px; }
  .project-list {
    width: 60% !important; }
  .page-sidebar .sidebar-header {
    height: 48px;
    line-height: 48px; }
  .panel .panel-heading {
    padding-left: 15px; }
  .panel .panel-body {
    padding: 15px;
    padding-top: 0; }
  .map-controls {
    left: 10px; }
  .register-container {
    height: auto !important; }
  .mxs-full-width {
    width: 100% !important; } }

/*** Retina Display Images **/
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 200 / 100), only screen and (min-device-pixel-ratio: 2) {
  .editor-icon {
    background-image: url("~assets/img/editor/editor_tray_2x.png");
    background-size: 480px 40px; }
  .alert .close {
    background: url("~assets/img/icons/noti-cross-2x.png") no-repeat scroll 0 0 transparent;
    background-position: -9px -10px;
    width: 10px;
    height: 9px;
    position: relative;
    opacity: 0.8;
    background-size: 114px 29px; } }

.hidden {
  display: none !important; }

.visible-sm, .visible-xs, .visible-md {
  display: none; }

.dis-flex {
  display: flex; }

.mfa-sms-form-step1 {
  width: 100%;
  max-width: 400px; }

.mfa-sms-form-step2 {
  max-width: 350px; }

@media (max-width: 767px) {
  .visible-xs {
    display: block; }
  .hidden-xs {
    display: none; }
  .header .brand a img {
    max-height: 45px; }
  .header .brand span {
    font-size: 15px !important;
    margin-top: 13px !important; }
  button.profile-dropdown-toggle {
    padding: 0; }
  .m-w-100 {
    width: 100% !important; }
  .m-m-t-10 {
    margin-top: 10px; }
  .m-m-b-10 {
    margin-bottom: 10px; }
  .page-container .page-content-wrapper .content {
    padding-top: 50px !important; }
  .-pageSizeOptions {
    width: 100%; }
    .-pageSizeOptions select {
      width: 100%; }
  .m-p-l-0 {
    padding-left: 0 !important; }
  .m-p-r-0 {
    padding-right: 0 !important; }
  .terms_of_use-href {
    padding-right: 15px;
    background-color: #fff; }
  .page-sidebar .sidebar-menu .menu-items > li > a,
  .page-sidebar .sidebar-menu .menu-items > li > span.sub-label {
    padding-left: 20px; }
  .container-fixed-xs {
    padding-left: 15px;
    padding-right: 15px; }
  .resources-modal {
    width: auto; }
  .resource-list .card {
    z-index: 1; }
  .mfa-modal-notification .modal-header,
  .mfa-modal-notification .modal-body,
  .mfa-modal-notification .modal-footer {
    padding: 10px 15px !important; }
  .mfa-modal-notification .modal-footer {
    display: flex;
    justify-content: center; }
    .mfa-modal-notification .modal-footer .btn {
      padding: 5px 10px; }
    .mfa-modal-notification .modal-footer .btn-primary {
      flex: 1; }
  .m-p-lr-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .m-dis-ib {
    display: inline-block !important; }
  .m-p-r-40 {
    padding-right: 40px; }
  .m-m-l-0 {
    margin-left: 0 !important; }
  .resource-list .card-img img {
    object-fit: scale-down; }
  .xs-full-width {
    width: 100% !important; }
  .xs-m-t-5 {
    margin-top: 5px; }
  .xs-m-l-r-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xs-p-r-5 {
    padding-right: 5px !important; }
  .xs-float-left {
    float: left !important; }
  .xs-flex-1 {
    flex: 1; }
  .xs-m-l-5 {
    margin-left: 5px !important; }
  .xs-p-r-0 {
    padding-right: 0 !important; }
  .mfa-sms-form-step1 {
    max-width: 100%; }
  .mfa-sms-form-step2 {
    max-width: 100%; } }

@media (max-width: 1366px) {
  .md-dis-ib {
    display: inline-block !important; }
  .md-m-l-0 {
    margin-left: 0 !important; }
  .md-m-t-10 {
    margin-top: 10px; }
  .md-full-width {
    width: 100% !important; } }

@media (max-width: 480px) {
  .mxs-full-width {
    width: 100% !important; }
  .mxs-dis-ib {
    display: inline-block !important; }
  .mxs-m-l-0 {
    margin-left: 0 !important; } }

@media (max-width: 360px) {
  .mfa-modal-notification .modal-footer .btn-secondary {
    flex: 0.5;
    height: 54px; } }

@media (max-width: 991px) {
  .visible-md {
    display: block; } }

@media (max-width: 480px) {
  .mfa-modal-notification .modal-footer {
    display: block; }
    .mfa-modal-notification .modal-footer .btn {
      width: 100%;
      margin-left: 0 !important; } }

.card-reply.card:first-child {
  margin-bottom: 20px !important; }

.ct-label {
  fill: #bdbdbd;
  color: #bdbdbd;
  font-size: 0.858rem;
  line-height: 1; }

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -ms-flexbox;
  display: flex; }

.ct-label.ct-horizontal.ct-start {
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-horizontal.ct-end {
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-vertical.ct-start {
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-label.ct-vertical.ct-end {
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end; }

.ct-grid {
  stroke: rgba(0, 0, 0, 0.1);
  stroke-width: 1px;
  stroke-dasharray: 2px; }

.ct-point {
  stroke-width: 8px;
  stroke-linecap: round; }

.ct-line {
  fill: none;
  stroke-width: 3px; }

.ct-area {
  stroke: none;
  fill-opacity: 0.15; }

.ct-bar {
  fill: none;
  stroke-width: 10px; }

.ct-slice-donut {
  fill: none;
  stroke-width: 60px; }

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: #1e88e5; }

.ct-series-a .ct-slice-pie,
.ct-series-a .ct-area {
  fill: #1e88e5; }

.ct-series-b .ct-point,
.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-slice-donut {
  stroke: #66bb6a; }

.ct-series-b .ct-slice-pie,
.ct-series-b .ct-area {
  fill: #66bb6a; }

.ct-series-c .ct-point,
.ct-series-c .ct-line,
.ct-series-c .ct-bar,
.ct-series-c .ct-slice-donut {
  stroke: #f44336; }

.ct-series-c .ct-slice-pie,
.ct-series-c .ct-area {
  fill: #f44336; }

.ct-series-d .ct-point,
.ct-series-d .ct-line,
.ct-series-d .ct-bar,
.ct-series-d .ct-slice-donut {
  stroke: #8e24aa; }

.ct-series-d .ct-slice-pie,
.ct-series-d .ct-area {
  fill: #8e24aa; }

.ct-series-e .ct-point,
.ct-series-e .ct-line,
.ct-series-e .ct-bar,
.ct-series-e .ct-slice-donut {
  stroke: #fdd835; }

.ct-series-e .ct-slice-pie,
.ct-series-e .ct-area {
  fill: #fdd835; }

.ct-series-f .ct-point,
.ct-series-f .ct-line,
.ct-series-f .ct-bar,
.ct-series-f .ct-slice-donut {
  stroke: #d81b60; }

.ct-series-f .ct-slice-pie,
.ct-series-f .ct-area {
  fill: #d81b60; }

.ct-series-g .ct-point,
.ct-series-g .ct-line,
.ct-series-g .ct-bar,
.ct-series-g .ct-slice-donut {
  stroke: #5c6bc0; }

.ct-series-g .ct-slice-pie,
.ct-series-g .ct-area {
  fill: #5c6bc0; }

.ct-series-h .ct-point,
.ct-series-h .ct-line,
.ct-series-h .ct-bar,
.ct-series-h .ct-slice-donut {
  stroke: #689f38; }

.ct-series-h .ct-slice-pie,
.ct-series-h .ct-area {
  fill: #689f38; }

.ct-series-i .ct-point,
.ct-series-i .ct-line,
.ct-series-i .ct-bar,
.ct-series-i .ct-slice-donut {
  stroke: #ffa726; }

.ct-series-i .ct-slice-pie,
.ct-series-i .ct-area {
  fill: #ffa726; }

.ct-series-j .ct-point,
.ct-series-j .ct-line,
.ct-series-j .ct-bar,
.ct-series-j .ct-slice-donut {
  stroke: #26c6da; }

.ct-series-j .ct-slice-pie,
.ct-series-j .ct-area {
  fill: #26c6da; }

.ct-series-k .ct-point,
.ct-series-k .ct-line,
.ct-series-k .ct-bar,
.ct-series-k .ct-slice-donut {
  stroke: #00897b; }

.ct-series-k .ct-slice-pie,
.ct-series-k .ct-area {
  fill: #00897b; }

.ct-series-l .ct-point,
.ct-series-l .ct-line,
.ct-series-l .ct-bar,
.ct-series-l .ct-slice-donut {
  stroke: #795548; }

.ct-series-l .ct-slice-pie,
.ct-series-l .ct-area {
  fill: #795548; }

.ct-series-m .ct-point,
.ct-series-m .ct-line,
.ct-series-m .ct-bar,
.ct-series-m .ct-slice-donut {
  stroke: #bdbdbd; }

.ct-series-m .ct-slice-pie,
.ct-series-m .ct-area {
  fill: #bdbdbd; }

.ct-series-n .ct-point,
.ct-series-n .ct-line,
.ct-series-n .ct-bar,
.ct-series-n .ct-slice-donut {
  stroke: #757575; }

.ct-series-n .ct-slice-pie,
.ct-series-n .ct-area {
  fill: #757575; }

.ct-square {
  display: block;
  position: relative;
  width: 100%; }

.ct-square:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 100%; }

.ct-square:after {
  content: "";
  display: table;
  clear: both; }

.ct-square > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-second {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 93.75%; }

.ct-minor-second:after {
  content: "";
  display: table;
  clear: both; }

.ct-minor-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-second {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 88.8888888889%; }

.ct-major-second:after {
  content: "";
  display: table;
  clear: both; }

.ct-major-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-third {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 83.3333333333%; }

.ct-minor-third:after {
  content: "";
  display: table;
  clear: both; }

.ct-minor-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-third {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 80%; }

.ct-major-third:after {
  content: "";
  display: table;
  clear: both; }

.ct-major-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%; }

.ct-perfect-fourth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 75%; }

.ct-perfect-fourth:after {
  content: "";
  display: table;
  clear: both; }

.ct-perfect-fourth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%; }

.ct-perfect-fifth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 66.6666666667%; }

.ct-perfect-fifth:after {
  content: "";
  display: table;
  clear: both; }

.ct-perfect-fifth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 62.5%; }

.ct-minor-sixth:after {
  content: "";
  display: table;
  clear: both; }

.ct-minor-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-golden-section {
  display: block;
  position: relative;
  width: 100%; }

.ct-golden-section:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 61.804697157%; }

.ct-golden-section:after {
  content: "";
  display: table;
  clear: both; }

.ct-golden-section > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 60%; }

.ct-major-sixth:after {
  content: "";
  display: table;
  clear: both; }

.ct-major-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 56.25%; }

.ct-minor-seventh:after {
  content: "";
  display: table;
  clear: both; }

.ct-minor-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 53.3333333333%; }

.ct-major-seventh:after {
  content: "";
  display: table;
  clear: both; }

.ct-major-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-octave {
  display: block;
  position: relative;
  width: 100%; }

.ct-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 50%; }

.ct-octave:after {
  content: "";
  display: table;
  clear: both; }

.ct-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-tenth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 40%; }

.ct-major-tenth:after {
  content: "";
  display: table;
  clear: both; }

.ct-major-tenth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-eleventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 37.5%; }

.ct-major-eleventh:after {
  content: "";
  display: table;
  clear: both; }

.ct-major-eleventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-twelfth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 33.3333333333%; }

.ct-major-twelfth:after {
  content: "";
  display: table;
  clear: both; }

.ct-major-twelfth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-double-octave {
  display: block;
  position: relative;
  width: 100%; }

.ct-double-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 25%; }

.ct-double-octave:after {
  content: "";
  display: table;
  clear: both; }

.ct-double-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.loadingTable {
  width: 100%;
  height: 200px;
  position: relative; }
  .loadingTable .lds-ellipsis {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 50px;
    height: 50px; }
  .loadingTable .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #ff5722;
    animation-timing-function: cubic-bezier(0, 1, 1, 0); }
  .loadingTable .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite; }
  .loadingTable .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite; }
  .loadingTable .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite; }
  .loadingTable .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite; }

.modal-choose-alias-accounts .modal-body button {
  color: #000;
  width: 100%;
  margin-bottom: 5px;
  padding: 12px;
  box-shadow: 1px 1px 1px 1px #eee !important;
  transition: all 0.2s ease !important;
  text-align: left; }
  .modal-choose-alias-accounts .modal-body button:hover, .modal-choose-alias-accounts .modal-body button:focus {
    border: 1px solid #ccc !important;
    box-shadow: 1px 1px 1px 1px #ccc !important;
    transition: all 0.2s ease !important; }

.modal-choose-alias-accounts .modal-body .modal-description {
  margin-bottom: 30px;
  text-align: center; }
  .modal-choose-alias-accounts .modal-body .modal-description h3 {
    margin: 0; }
  .modal-choose-alias-accounts .modal-body .modal-description p {
    font-size: 16px; }
    .modal-choose-alias-accounts .modal-body .modal-description p span {
      color: #1166bb;
      font-weight: bold; }

.modal-choose-alias-accounts .modal-dialog {
  box-shadow: none !important;
  max-width: 550px !important; }

.modal-choose-alias-accounts h5.modal-title {
  font-size: 16px !important; }
  .modal-choose-alias-accounts h5.modal-title img {
    max-width: 60px; }

.modal-choose-alias-accounts .modal-content {
  border: 1px solid #ccc; }

.modal-choose-alias-accounts .alias-avatar-item {
  display: flex; }
  .modal-choose-alias-accounts .alias-avatar-item span {
    position: relative;
    top: -10px;
    font-size: 16px; }
  .modal-choose-alias-accounts .alias-avatar-item .avatar-content {
    width: 40px;
    height: 40px; }
  .modal-choose-alias-accounts .alias-avatar-item .main-avatar-content {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    border-radius: 50%; }

.m-t-12 {
  margin-top: 12px !important; }

.login-page .login-container {
  display: flex;
  flex-direction: column; }
  .login-page .login-container .content {
    flex: 1; }
  .login-page .login-container .copyright-xs {
    position: relative; }
  .login-page .login-container .environment-name-login {
    color: #D0102C;
    font-weight: bold;
    font-size: 25px;
    padding-top: 5px; }

@media (min-width: 768px) {
  .copyright-xs {
    display: none !important; } }

@media (max-width: 768px) {
  .copyright-xs {
    margin-bottom: 49px;
    display: block; } }

.copyright-xs {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  text-align: center;
  background: #fff;
  width: 100%; }

.nl2br {
  white-space: pre-line; }
