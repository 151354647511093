.table {
	margin-bottom: 0;
  }
  .table + .table {
	margin-top: $gap-vert-default;
  }
  .table thead > tr > th {
	color: $darkgrey;
	font-weight: normal;
  }
  .table tbody > tr.selected {
	background-color: #f5f5f5;
  }
  .table:not(.table-nohover) tbody > tr:hover {
	//background-color: $greynormal;
  }
  .table.table-border {
	border: 1px solid $border-color;
  }
  .table.table-shadow {
	box-shadow: $table-shadow;
  }
  .ReactTable {
		.rt-noData {
		  background:transparent !important;
		}
		.rt-table .rt-thead .rt-th {
		  padding: 13px;
		  line-height: 24px;
		  color: $text-color-sub;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  font-weight: bold;
		}
		.rt-tbody .rt-tr .rt-td {
		  padding: 7px 10px;
		  display: flex;
		  align-items: center;
		}
		.rt-tbody .rt-tr .rt-td input {
		  margin-left: -2px;
		}
		.rt-th.-cursor-pointer {
		  > div:after {
			@extend %extend-material-icons;
			content: 'unfold_more';
			font-size: 18px !important;
			top: 4px;
			position: relative;
		  }
		  &.-sort-asc {
			-webkit-box-shadow: inset 0 3px 0 0 $primary;
			box-shadow: inset 0 3px 0 0 $primary;
		  }
		  &.-sort-asc > div:after {
			content: 'keyboard_arrow_up';
		  }
		  &.-sort-desc {
			-webkit-box-shadow: inset 0 -3px 0 0 $primary;
			box-shadow: inset 0 -3px 0 0 $primary;
		  }
		  &.-sort-desc > div:after {
			content: 'keyboard_arrow_down';
		  }
		}
		.-pagination .-btn {
	    background: #2979FF;
	    color: white;
	  }
	  .-pagination .-btn:not([disabled]):hover {
	    background: lighten(#2979FF, 10%);
	  }
	  .-pagination .-btn:disabled {
	    background: rgba(0,0,0,0.1);
	    color: rgba(0,0,0,0.6);
	  }
}
