.search-box {
  position: relative;
  input {
    height: 38px;
  }
  & > label {
    position: absolute;
    left: 8px;
    top: 3px;
    z-index: 10;
  }
  input.form-control {
    padding-left: 30px;
  }
}
