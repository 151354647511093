.modal-dialog {
  .modal-content {
    // max-height: calc(100vh - 60px);
    // overflow-y: hidden;
    .rt-table {
      max-height: calc(100vh - 420px);
      .my-tbody-class {
        flex: 99999 1 auto;
        display: flex;
        flex-direction: column;
        min-width: 560px;
        .rt-tr-group{
          border-bottom: 1px solid rgba(0,0,0,0.05);
        }
        .rt-tr-group:last-child {
          border-bottom: 0;
        }
        @media(max-width:1400px){
          .rt-tr-group:last-child {
            margin-bottom: 25px;
          }
        }
        .rt-td {
          border-right: 1px solid rgba(0,0,0,0.05);
          padding: 7px 10px;
          display: flex;
          align-items: center;
        }
        .rt-td:last-child {
          border-right: 0;
        }
      }
      .my-thead-class {
        height: 51px;
        min-height: 51px;
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
        .rt-thead.-header {
          box-shadow: none;
        }
      }
    }
  }
}
@media(max-width: 1366px){
  .modal-dialog .modal-content .rt-table{
    max-height: 100%;
  }
}
