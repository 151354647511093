.popover {
	z-index: $zIndex-navbar - 10;
	.popover-header {
		font-size: $text-button;
		padding: 10px;
		margin: 0;
	}
	.popover-body {
		font-size: $text-cap;
		padding: 10px;
	}
	&.popover-info {
		z-index: 99999;
		min-width: 300px;
		h3.popover-header {
			font-size: $text-subtitle;
		}
		.popover-body {
			min-height: 60px;
			font-size: $text-body;
		}
	}
}
