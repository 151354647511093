@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite
}
.forgot-password {
    font-size: .8rem;
    padding-bottom: 5px;
}

.forgot-password>a {
    color: #007bff!important;
}

.errorMessage {
    color: #ff4949;
    font-size: 12px;
  }

.text-center {
    text-align: center;
}

.domain {
    .btn.btn-auth {
        position: absolute !important;
        top: 5px;
        min-width: 40px;
        max-width: 40px;
        right: 0;
        height: calc(100% - 69px);
        margin: 0;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .invalid-feedback {
        min-height: 22px;
    }

    .input-group {
        padding-right: 40px;
    }
}
.terms_of_use-href{
    padding-top: 10px;
    padding-right: 70px;
    a{
        color: #000;
        text-decoration: underline;
        &:hover,&:focus,&:active{
            text-decoration: underline;
            color: #000;
        }
    }
}

