.sms-color {
    color: rgba(255, 34, 0, 0.932);
  }
  .m-t-2 {
    margin-top: 2px;
  }
  .m-l-2 {
    margin-left: -2px;
  }
  .w-80 {
    width: 80%;
  }
  .mr-top-4 {
    margin-top: -5px;
  }
  .font-size-12 {
    font-size: 12px;
  }
  .m-t-15 {
    margin-top: 15px;
  }
  @media (max-width: 767px) {
    .mr-top-4 {
        margin-top: 0px;
    }
  }
